/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./Register.css";
import { t } from "../../languages/settingsLanguages";
import Header from "../../components/header/Header";
// ---------------------------------------------------------

const ConfirmationMail = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "reg" refer to the file: Register.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-confirmation-mail")}
        </title>
      </Helmet>

      <Header />

      <div className="reg_confirmation_mail_container">
        <h2>{t("company-confirmation-mail")}</h2>
      </div>
    </>
  );
};

export default ConfirmationMail;
