/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_GET_PACKAGES,
  PATH_BUY_PACKAGE,
  TOKEN_DB,
} from "../../api/apis";
import {
  packagesCU,
  validateActiveSessionTokenCompany,
} from "../../utils/Helpers";
// ---------------------------------------------------------

const getAllPackages = async (
  dispatch,
  privateSessionToken,
  responsePackages = () => {}
) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_GET_PACKAGES}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      let _essential = res.findIndex(
        (item) => item.id === packagesCU.essential.id
      );
      let _progress = res.findIndex(
        (item) => item.id === packagesCU.progress.id
      );
      let _dominate = res.findIndex(
        (item) => item.id === packagesCU.dominate.id
      );

      let pkgEssential = {
        id: res[_essential].id,
        name: res[_essential].name,
        description: res[_essential].description,
        possible_unlocks: res[_essential].unlok - packagesCU.essential.bonus,
        currency: res[_essential].currency,
        unit_amount: Number(res[_essential].unit_amount) / 100,
        ...packagesCU.essential,
      };
      let pkgProgress = {
        id: res[_progress].id,
        name: res[_progress].name,
        description: res[_progress].description,
        possible_unlocks: res[_progress].unlok - packagesCU.progress.bonus,
        currency: res[_progress].currency,
        unit_amount: Number(res[_progress].unit_amount) / 100,
        ...packagesCU.progress,
      };
      let pkgDominate = {
        id: res[_dominate].id,
        name: res[_dominate].name,
        description: res[_dominate].description,
        possible_unlocks: res[_dominate].unlok - packagesCU.dominate.bonus,
        currency: res[_dominate].currency,
        unit_amount: Number(res[_dominate].unit_amount) / 100,
        ...packagesCU.dominate,
      };
      let arrPackages = [pkgEssential, pkgProgress, pkgDominate];

      responsePackages(arrPackages);
    })
    .catch((error) => {
      // console.error(error);
      responsePackages(
        error.message === "token-expired" ? error.message : "unexpected-error"
      );
    });
};

const buyPackage = async (
  id_package,
  id_company,
  privateSessionToken,
  responseBuy = () => {}
) => {
  const API = API_BASE;
  let _data = {
    product_id: id_package,
    company_id: id_company,
  };

  await fetch(`${API}${PATH_BUY_PACKAGE}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_data),
  })
    .then((response) =>
      validateActiveSessionTokenCompany(response, [400, 404], "unpaid")
    )
    .then((res) => {
      responseBuy(res);
    })
    .catch((error) => {
      // console.error(error.message);
      responseBuy(
        error.message === "token-expired"
          ? error.message
          : error.message === "unpaid"
          ? "unpaid"
          : "unexpected-error"
      );
    });
};

const servicesCompanyPackages = {
  getAllPackages,
  buyPackage,
};

export default servicesCompanyPackages;
