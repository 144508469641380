/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./CompanyPackages.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import servicesCompanyPackages from "./servicesCompanyPackages";
import { userCompany } from "../../utils/Helpers";
import Button from "../../components/button/Button";
import Paragraph from "../../components/paragraph/Paragraph";
import LoadingTab from "../../components/loading/LoadingTab";
// ---------------------------------------------------------

const CompanyPackages = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const [dataPackages, setDataPackages] = useState(undefined);
  const [selectedPackage, setSelectedPackage] = useState(undefined);
  const [moreInfo, setMoreInfo] = useState(undefined);
  const [unpaid, setUnpaid] = useState(false);

  const { token } = userCompany.get();

  const navigate = useNavigate();

  useEffect(() => {
    servicesCompanyPackages.getAllPackages(dispatch, token, (res) =>
      res === "token-expired"
        ? navigate("/login-company")
        : setDataPackages(res)
    );
  }, [dispatch, token, navigate]);

  const handleBuyConfirm = () => {
    const handleBuy = (res) => {
      setSelectedPackage(undefined);
      // navigate("/home-company")
      window.open(res.url);
    };

    servicesCompanyPackages.buyPackage(
      selectedPackage,
      userCompany.get().id,
      token,
      (res) =>
        res === "token-expired"
          ? navigate("/login-company")
          : res === "unpaid"
          ? setUnpaid(true)
          : handleBuy(res)
    );
  };

  const Modal = ({ item }) => {
    return (
      <>
        <div className="cpk_modal_container">
          <div
            onClick={() => setSelectedPackage(undefined)}
            className="cpk_modal_dark"
          />

          <div className="cpk_modal app_modal_confirm">
            {!unpaid ? (
              <Paragraph
                withTitle
                title={t("admin-company-modal-confirm-buy")}
              />
            ) : (
              <Paragraph
                withTitle
                title={t("admin-company-modal-pending-purchase-title")}
              />
            )}

            <div className="app_modal_confirm_btns_container">
              {!unpaid ? (
                <>
                  <Button
                    otherClassNameBtn="app_modal_confirm_btn"
                    title={t("admin-company-unblock-confirm-cancel")}
                    onclick={() => setSelectedPackage(undefined)}
                  />

                  <Button
                    otherClassNameBtn={`app_modal_confirm_btn ${item.classNameBorderColor} ${item.classNameBgColor}`}
                    title={t("admin-company-modal-confirm-buy-yes")}
                    onclick={handleBuyConfirm}
                  />
                </>
              ) : (
                <>
                  <Button
                    otherClassNameBtn="app_modal_confirm_btn"
                    title={t("admin-company-modal-pending-purchase-close")}
                    onclick={() => {
                      setSelectedPackage(undefined);
                      setUnpaid(false);
                    }}
                  />

                  <Button
                    type="link"
                    link="/billing"
                    otherClassNameBtn={`app_modal_confirm_btn ${item.classNameBorderColor} ${item.classNameBgColor}`}
                    title={t("admin-company-modal-pending-purchase-see")}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* Class names starting with "cpk" refer to the file: CompanyPackages.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-company-packages")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="company" />

      <div id="cpk_packages_container" className="cpk_packages_container">
        <div style={{ display: "flex", margin: "auto" }}>
          {dataPackages === undefined ? (
            <LoadingTab />
          ) : (
            dataPackages.map((item, index) => {
              return (
                <div
                  key={`packageCU_${index}`}
                  className={`cpk_package ${item.classNameBorderColor}`}
                  style={{
                    boxShadow: `0 0.5rem 1rem 0.1rem ` + item.color + "50",
                  }}
                >
                  {selectedPackage === item.id && <Modal item={item} />}

                  <span
                    className={`cpk_package_price ${item.classNameBgColor}`}
                  >
                    <p>
                      {item.unit_amount}€
                      <i style={{ fontSize: 14 }}>
                        /{t("admin-company-month")}
                      </i>
                    </p>
                  </span>

                  <h2 className={`cpk_package_title ${item.classNameTxtColor}`}>
                    {item.name}
                  </h2>

                  <div>
                    <Paragraph>
                      <p>{t(item.description)}</p>
                    </Paragraph>

                    <Paragraph>
                      <p>
                        {t("admin-company-possible-unlocks")}:{" "}
                        <b>{item.possible_unlocks}</b>
                      </p>

                      <p>
                        {t("admin-company-bonus")}: <b>{item.bonus}</b>
                      </p>

                      <p>{t("admin-company-benefit")}:</p>
                      <ul className="cpk_package_benefit">
                        {item.benefits.map((benefit, id) => {
                          return (
                            <li
                              className="cpk_package_benefit_item"
                              key={`${index}_benefit_${id}`}
                            >
                              <p>{benefit.info}</p>

                              {benefit.moreInfo.type !== "" &&
                                benefit.moreInfo.type !== undefined && (
                                  <button
                                    onClick={() =>
                                      setMoreInfo(benefit.moreInfo.type)
                                    }
                                    className="cpk_package_benefit_moreInfo"
                                  >
                                    <h3>i</h3>
                                  </button>
                                )}
                              {moreInfo === benefit.moreInfo.type && (
                                <div className="cpk_package_benefit_moreInfo_container">
                                  <button
                                    onClick={() => setMoreInfo(undefined)}
                                    className="cpk_package_benefit_moreInfo_close_container"
                                  >
                                    <div className="cpk_package_benefit_moreInfo_close_line" />
                                    <div className="cpk_package_benefit_moreInfo_close_line" />
                                  </button>
                                  <p>{benefit.moreInfo.data}</p>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>

                      <p style={{ marginTop: 20 }}>
                        {t("admin-company-price-per-lead")}:{" "}
                        <b>
                          {Number(item.unit_amount) /
                            (Number(item.possible_unlocks) +
                              Number(item.bonus))}
                          €
                        </b>
                      </p>
                    </Paragraph>
                  </div>

                  <div style={{ flex: 1 }} />

                  <div className="cpk_package_btnBuy_container">
                    <Button
                      otherClassNameBtn={`${item.classNameBorderColor} ${item.classNameBgColor}`}
                      title={t("admin-company-buy-package-btn")}
                      onclick={() => {
                        setSelectedPackage(item.id);
                      }}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyPackages;
