/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./SideMenu.css";
// ---------------------------------------------------------

const SideMenu = ({
  children = <></>,
  sideChildrenLeft = <></>,
  sideChildrenRight = <></>,
  openSides = false,
  onClickClose = () => {},
}) => {
  return (
    <>
      {/* Class names starting with "smn" refer to the file: SideMenu.css */}
      <div className="smn_container">
        <div
          className={`smn_side smn_left_container ${
            openSides && "smn_sideMobile_open"
          }`}
        >
          {sideChildrenLeft}
        </div>

        <div className="smn_center_container">{children}</div>

        <div
          className={`smn_side smn_right_container ${
            openSides && "smn_sideMobile_open"
          }`}
        >
          <button className="smn_side_btn_close" onClick={onClickClose}>
            <div className="smn_side_btn_line smn_side_btn_line_1" />
            <div className="smn_side_btn_line smn_side_btn_line_2" />
          </button>
          {sideChildrenRight}
        </div>
      </div>
    </>
  );
};

export default SideMenu;
