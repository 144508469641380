/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./ForCompanies.css";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../../components/paragraph/Paragraph";
import BannerWithChildren from "../../components/bannerWithChildren/BannerWithChildren";
import ContactForm from "../../components/contactForm/ContactForm";
import { useState } from "react";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const ForCompanies = ({ docTitle }) => {
  const [stateForm, setStateForm] = useState({
    Service: "CompanyU",
    name: "",
    lastname: "",
    mail: "",
    phone: "",
    Message: "",
    checkbox_legalConditions: true,
    ready: false,
  });

  return (
    <>
      {/* Class names starting with "fco" refer to the file: ForCompanies.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-companies")}
        </title>
      </Helmet>

      <Header />

      <Banner
        img={assets.imgs.companyhelp}
        alt="Banner Companies: Imagen de freepik"
        title={"Maßgeschneiderte Bewerber für Ihr Unternehmen!"}
        opacityDarkLayer={90}
      />

      <div className="fco_container">
        <Paragraph>
          <p>
            Rekrutierung neu definiert - Schnell, Modern & Effizient. Willkommen
            in der Ära des digitalen Job-Matchings, inspiriert vom 21.
            Jahrhundert.
          </p>

          <p>
            Warten Sie nicht länger passiv auf den perfekten Bewerber. Mit
            CompanyU erhalten Sie die Werkzeuge, um aktiv und gezielt Ihren
            idealen Kandidaten zu finden - oder lassen Sie uns die Suche für Sie
            übernehmen. Unsere Plattform agiert wie eine fortschrittliche
            Dating-App, wobei der Fokus auf der Verbindung mit Ihrer nächsten
            Top-Fachkraft liegt. Entdecken Sie Talente, die genau zu Ihrem
            Unternehmensprofil passen.
          </p>
        </Paragraph>
      </div>

      <div className="fco_problem_and_solution_container">
        <div className="fco_pas_container">
          <Paragraph withTitle title="Aktuelles Problem">
            <p>
              Aktuell investiert Ihr Unternehmen massiv in Jobplattformen und
              Headhunter, doch der Fachkräftemangel bleibt ein ungelöstes
              Problem. Dies führt zu einem Rückstau bei Aufträgen und erschwert
              die Annahme neuer Projekte, was die betriebliche Effizienz und
              Wettbewerbsfähigkeit erheblich beeinträchtigt.
            </p>
          </Paragraph>

          <Paragraph withTitle title="Lösung">
            <p>
              Company U bietet eine effiziente Lösung für den Fachkräftemangel,
              indem es Handwerksunternehmen direkt mit qualifizierten
              Fachkräften verbindet. Unsere Plattform ist kostengünstig und
              effektiv, reduziert die Abhängigkeit von teuren Jobplattformen und
              Headhuntern. Mit Company U finden Sie schnell die passenden
              Mitarbeiter, bewältigen Auftragsrückstände und fördern Ihr
              Unternehmenswachstum. Company U strebt danach, ein verlässlicher
              Partner für den Erfolg und das Wachstum Ihres Handwerksbetriebs zu
              sein.
            </p>
          </Paragraph>
        </div>

        <div className="fco_pas_container">
          <section className="fco_pas_steps_container">
            <h3>Step 1</h3>

            <p>Kontaktieren Sie uns einfach über das untenstehende Formular.</p>
          </section>

          <section className="fco_pas_steps_container fco_pas_steps_right">
            <h3>Step 2</h3>

            <p>
              Wir nehmen Kontakt zu Ihnen auf und begleiten Sie Schritt für
              Schritt durch den gewünschten Einstellungsprozess.
            </p>
          </section>

          <section className="fco_pas_steps_container">
            <h3>Step 3</h3>

            <p>
              Sie bekommen Zugang zu allen passenden Handwerkstalenten aus
              unserem Pool und können diese dann direkt kontaktieren.
            </p>
          </section>
        </div>
      </div>

      <div className="fco_main_container">
        <div className="fco_bg_img_container">
          <img
            src={assets.imgs.companiesBgCity}
            alt="Bg conpanies"
            className="fco_bg_img_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fco_banner_container"
          img={assets.imgs.companiesBanner}
          alt="Banner companies"
        >
          <div className="fco_child_container">
            <Paragraph
              otherClassNameContainer="fco_child_paragraph_container"
              otherClassNameParagraph="fap_child_p fap_child_p_light"
              withTitle
              title={t("companies-banner-how-to-start-title")}
            >
              <p>
                Erster Kontakt? Schnell und unkompliziert über den präferierten
                Kommunikationsweg des Bewerbers.
              </p>

              <p>
                Lassen Sie sich nicht von traditionellen Rekrutierungsverfahren
                bremsen. Bei CompanyU sind Sie in der Pole-Position, Ihren
                Einstellungsprozess so effizient wie nie zuvor zu gestalten -
                mit voller Unterstützung durch unser Team!
              </p>
            </Paragraph>

            {/* <Button
              type="link"
              link="https://web.arbeitsagentur.de/entgeltatlas/"
              otherClassNameBtn="fco_banner_btn"
              title={t("applicants-banner-2-payment-atlas")}
            /> */}
          </div>
        </BannerWithChildren>
      </div>

      <section className="ser_article_tab_form_container fco_contact_form">
        <div
          style={{ backgroundColor: `#bf8970` }}
          className="fco_contact_tab_form_decorationTop"
        />

        <ContactForm
          color={`#bf8970`}
          initialForm={stateForm}
          onChangeForm={(form) => setStateForm(form)}
        />
      </section>
    </>
  );
};

export default ForCompanies;
