/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { defaultLanguage } from "../languages/settingsLanguages";
// ---------------------------------------------------------

const initialState = {
  defaultTest: false,
  language: defaultLanguage,
  arrApplicantsPreview: [],
  loadingArrApplicantsPreview: true,
  arrApplicantsWithFilters: [],
  loadingArrApplicantsWithFilters: true,
  usersuperadmin: false,
  usercompany: false,
  userapplicant: false,
  userinfluencer: false,
  saveApplicantID: "",
  yourpackage: {},
  lastVisitedRoute: "",
  arrAllWorks: [],
};

export default initialState;
