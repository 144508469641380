/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import { t } from "../../languages/settingsLanguages";
import { addWorks } from "./servicesSuperAdmin";
import { WebContext } from "../../context/Context";
import Input, {
  ChildrenLabelDefault,
  Options,
  TextArea,
} from "../../components/input/Input";
import { additionalServices, studiesOptions } from "../../utils/options";
import Button from "../../components/button/Button";
import { userSuperAdmin } from "../../utils/Helpers";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const AddWorkSuperAdmin = () => {
  const { dispatch } = WebContext();
  const { token, id } = userSuperAdmin.get();

  const [newWorkData, setNewWorkData] = useState({
    logo: "",
    vacancy: "" /* Required */,
    company: "" /* Required */,
    qualification: "",
    working: "" /* Required */,
    zip: "",
    city: "" /* Required */,
    benefit: [],
    phone: "" /* Required */,
    mail: "" /* Required */,
    /* -------- More Info -------- */
    selfDescriptionCompany: "",
    requirements: "",
    workDescription: "",
    workPlaces: "",
    workSchedules: "",
    salaryRange: "",
    careerAndDevelopmentOpportunities: "",
  });

  const [errorFree, setErrorFree] = useState({
    ...newWorkData,
    allErrorFree: false,
  });

  const [sending, setSending] = useState(false);
  const [statusSave, setStatusSave] = useState();

  const handleChangeInput = (target, isOK) => {
    setNewWorkData({
      ...newWorkData,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleChangeAddServices = (value) => {
    let newArr = newWorkData.benefit;

    let exists = newArr.findIndex((date) => date.value === value);

    if (exists === -1) {
      newArr.push({ value });
      setNewWorkData({
        ...newWorkData,
        benefit: newArr,
      });
      // if (newArr.length < 5) {
      // }
    } else {
      newArr.splice(exists, 1);
      setNewWorkData({
        ...newWorkData,
        benefit: newArr,
      });
    }
  };

  const handleSubmit = async () => {
    setSending(true);

    addWorks(dispatch, newWorkData, token).then((res) => {
      setSending(false);
      return res === "failed" ? setStatusSave(res) : window.location.reload();
    });

    return;
  };

  useEffect(() => {
    setNewWorkData({ ...newWorkData, id });
  }, []);

  let regIsPhone = /^\+?\d{10,13}$/;
  let regIsMail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

  useEffect(() => {
    if (
      errorFree.vacancy &&
      errorFree.company &&
      errorFree.working &&
      errorFree.city &&
      errorFree.phone &&
      errorFree.mail &&
      !errorFree.allErrorFree
    ) {
      return setErrorFree({
        ...errorFree,
        allErrorFree: true,
      });
    } else if (
      (!errorFree.vacancy ||
        !errorFree.company ||
        !errorFree.working ||
        !errorFree.city ||
        !errorFree.phone ||
        !errorFree.mail) &&
      errorFree.allErrorFree
    ) {
      return setErrorFree({
        ...errorFree,
        allErrorFree: false,
      });
    } else return;
  }, [errorFree]);

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}

      <section className="super_newWork_container">
        <h2>Neuer Job</h2>

        {/* -------------------- ProfileFile -------------------- */}
        <div className="ffa_form_changeProfile">
          {newWorkData.logo !== undefined &&
          newWorkData.logo !== null &&
          newWorkData.logo !== "" ? (
            <div className="ffa_profileFile_container">
              <button
                className="ffa_profileFile_btn"
                onClick={() =>
                  setNewWorkData({
                    ...newWorkData,
                    logo: null,
                  })
                }
              >
                {typeof newWorkData.logo === "object" ? (
                  <img
                    className="ffa_profileFile_img"
                    src={URL.createObjectURL(newWorkData.logo)}
                    alt="Profile File"
                  />
                ) : (
                  <img
                    className="ffa_profileFile_img"
                    src={assets.brand.logoColorBgTransparent}
                    alt="Profile File"
                  />
                )}

                <p>{t("form-choose-other-image")}</p>
              </button>
            </div>
          ) : (
            <Input
              type="file"
              id="logo"
              nameInput="logo"
              responseInput={(target, isOK) =>
                setNewWorkData({
                  ...newWorkData,
                  logo: target.files[0],
                })
              }
              label
              childrenLabel={
                <ChildrenLabelDefault
                  txt={t("form-applicant-step-1-profile-file")}
                />
              }
            />
          )}

          <div className="ffa_form_change_container">
            <Button
              title="Stornieren"
              otherClassNameBtn="ffa_form_change_imgProfile_cancel"
              onclick={() =>
                setNewWorkData({
                  ...newWorkData,
                  logo: assets.brand.logoColorBgTransparent,
                })
              }
            />

            <Button
              title="Halten"
              otherClassNameBtn={
                newWorkData.logo === null ||
                newWorkData.logo === undefined ||
                newWorkData.logo === assets.brand.logoColorBgTransparent
                  ? "ffa_form_change_imgProfile_save_disabled"
                  : false
              }
              onclick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
        {/* -------------------- /ProfileFile ------------------------- */}
        {/* -------------------- Vacancy -------------------- */}
        <Input
          id="vacancy"
          nameInput="vacancy"
          placeholder="Stellenbezeichnung"
          defaultValue={newWorkData.vacancy}
          required
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Stellenbezeichnung"} />
          }
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Vacancy -------------------- */}

        {/* -------------------- Company -------------------- */}
        <Input
          id="company"
          nameInput="company"
          placeholder="Firma"
          defaultValue={newWorkData.company}
          required
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={<ChildrenLabelDefault required txt={"Firma"} />}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Company -------------------- */}

        {/* -------------------- Qualification -------------------- */}
        <Options
          label={"Was ist deine Qualifikation?"}
          arr={studiesOptions}
          onclick={(value) => {
            handleChangeInput(
              {
                name: "qualification",
                value,
              },
              // value !== option_choose
              true
            );
          }}
          select={newWorkData.qualification}
          error={errorFree.qualification === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Qualification -------------------- */}

        {/* -------------------- Working -------------------- */}
        <Input
          id="working"
          nameInput="working"
          placeholder="Teilzeit oder Vollzeit"
          defaultValue={newWorkData.working}
          required
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Teilzeit oder Vollzeit"} />
          }
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Working -------------------- */}

        {/* -------------------- Postal code -------------------- */}
        <Input
          id="zip"
          nameInput="zip"
          type="number"
          placeholder="000000"
          defaultValue={newWorkData.zip}
          responseInput={(target, isOK) => handleChangeInput(target, true)}
          label
          childrenLabel={<ChildrenLabelDefault required txt={"PLZ Abfragen"} />}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Postal code -------------------- */}

        {/* -------------------- City -------------------- */}
        <Input
          id="city"
          nameInput="city"
          placeholder="Hauptsitz des Unternehmens"
          defaultValue={newWorkData.city}
          required
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Hauptsitz des Unternehmens"} />
          }
          errorMessage={t("form-required")}
        />
        {/* -------------------- /City -------------------- */}

        {/* ---------------- Benefit ---------------- */}
        <Options
          otherClassNameOption="ffa_options_adjetives"
          label={t("form-applicant-step-3-additional-title")}
          arr={additionalServices}
          onclick={(value) => handleChangeAddServices(value)}
          select={newWorkData.benefit}
          arrSelects={newWorkData.benefit}
        />
        {/* ---------------- /Benefit ---------------- */}

        {/* -------------------- Phone number -------------------- */}
        <Input
          id="phone"
          nameInput="phone"
          placeholder="0000000000"
          defaultValue={newWorkData.phone}
          type="tel"
          required
          handleBlurContainsError={(e) => {
            let validNumberPhone = !regIsPhone.test(e.target.value);

            return validNumberPhone;
          }}
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Telefonnummer"} />
          }
          errorMessage={
            newWorkData.phone.length === 0
              ? t("form-required")
              : !regIsPhone.test(newWorkData.phone)
              ? t("form-applicant-step-1-please-valid-number")
              : ""
          }
        />
        {/* -------------------- /Phone number -------------------- */}

        {/* -------------------- Email -------------------- */}
        <Input
          id="mail"
          nameInput="mail"
          placeholder="yourmail@mail.com"
          defaultValue={newWorkData.mail}
          type="email"
          required
          handleBlurContainsError={(e) => {
            return (
              e.target.value.length < 6 || !regIsMail.test(newWorkData.mail)
            );
          }}
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"E-Mail Adresse"} />
          }
          errorMessage={
            newWorkData.mail.length === 0
              ? t("form-required")
              : !regIsMail.test(newWorkData.mail)
              ? t("form-applicant-step-1-please-valid-mail")
              : ""
          }
        />
        {/* -------------------- /Email -------------------- */}

        {/* ----------------- Self Description Company ----------------- */}
        <TextArea
          id="selfDescriptionCompany"
          label={"Firmenbeschreibung"}
          placeholder={"Firmenbeschreibung"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.selfDescriptionCompany}
          error={errorFree.selfDescriptionCompany === false}
          errorMessage={t("form-required")}
        />
        {/* ----------------- /Self Description Company ----------------- */}

        {/* -------------------- Requirements -------------------- */}
        <TextArea
          id="requirements"
          label={"Jobanforderungen"}
          placeholder={"Jobanforderungen"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.requirements}
          error={errorFree.requirements === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Requirements -------------------- */}

        {/* -------------------- Work Description -------------------- */}
        <TextArea
          id="workDescription"
          label={"Jobbeschreibung"}
          placeholder={"Jobbeschreibung"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.workDescription}
          error={errorFree.workDescription === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Work Description -------------------- */}

        {/* -------------------- Work Places -------------------- */}
        <TextArea
          id="workPlaces"
          label={"Arbeitsorte"}
          placeholder={"Arbeitsorte"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.workPlaces}
          error={errorFree.workPlaces === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Work Places -------------------- */}

        {/* -------------------- Work Schedules -------------------- */}
        <TextArea
          id="workSchedules"
          label={"Arbeitszeiten"}
          placeholder={"Arbeitszeiten"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.workSchedules}
          error={errorFree.workSchedules === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Work Schedules -------------------- */}

        {/* -------------------- Salary Range -------------------- */}
        <TextArea
          id="salaryRange"
          label={"Gehaltsspanne"}
          placeholder={"Gehaltsspanne"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.salaryRange}
          error={errorFree.salaryRange === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Salary Range -------------------- */}

        {/* ------------ Career And Development Opportunities ------------ */}
        <TextArea
          id="careerAndDevelopmentOpportunities"
          label={"Karriere- und Entwicklungsmöglichkeiten"}
          placeholder={"Karriere- und Entwicklungsmöglichkeiten"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, true);
          }}
          defaultValue={newWorkData.careerAndDevelopmentOpportunities}
          error={errorFree.careerAndDevelopmentOpportunities === false}
          errorMessage={t("form-required")}
        />
        {/* ------------ /Career And Development Opportunities ------------ */}

        <div className="super_send_container">
          {statusSave === "failed" ? (
            <>
              <div>
                <h4>Failed</h4>
              </div>
            </>
          ) : (
            <>
              {errorFree.allErrorFree ? (
                <Button
                  title={sending ? "Loading..." : "Job hinzufügen"}
                  onclick={() => !sending && handleSubmit()}
                />
              ) : (
                <div>
                  <h4>Absenden (alle Felder ausfüllen)</h4>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default AddWorkSuperAdmin;
