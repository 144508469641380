/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./PreviewApplicants.css";
import { WebContext } from "../../context/Context";
import { t } from "../../languages/settingsLanguages";
import SideMenu from "../../components/sideMenu/SideMenu";
import Header from "../../components/header/Header";
import Button from "../../components/button/Button";
import Paragraph from "../../components/paragraph/Paragraph";
import ApplicantCard from "../../components/applicantCard/ApplicantCard";
import ApplicantCardEmpty from "../../components/applicantCard/ApplicantCardEmpty";
import servicesPreviewApplicants from "./servicesPreviewApplicants";
import LoadingTab from "../../components/loading/LoadingTab";
import { useNavigate } from "react-router-dom";
import { userCompany } from "../../utils/Helpers";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const PreviewApplicants = ({ docTitle }) => {
  const { dispatch, arrApplicantsPreview, loadingArrApplicantsPreview } =
    WebContext();

  const [openMenuFilters, setOpenMenuFilters] = useState(false);

  const navigate = useNavigate();
  const { token } = userCompany.get();

  useEffect(() => {
    token !== "" && navigate("/applicants-list");
  }, [token, navigate]);

  const RequiredAction = ({ title = "", comment = "", link = "" }) => {
    return (
      <>
        <div className="pva_requiredAction_container">
          <h2 className="pva_title">{title}</h2>
          <Paragraph otherClassNameParagraph="pva_comment">{comment}</Paragraph>
          <Button type="link" link={link} title={title} />
        </div>
      </>
    );
  };

  useEffect(() => {
    servicesPreviewApplicants(dispatch);
  }, [dispatch]);

  return (
    <>
      {/* Class names starting with "pva" refer to the file: PreviewApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-preview-applicants")}
        </title>
      </Helmet>

      <Header />

      <SideMenu
        openSides={openMenuFilters}
        sideChildrenLeft={
          <RequiredAction
            title={t("preview-applicants-require-register-title")}
            comment={t("preview-applicants-require-comment")}
            link="/register-company"
          />
        }
        sideChildrenRight={
          <RequiredAction
            title={t("preview-applicants-require-login-title")}
            comment={t("preview-applicants-require-comment")}
            link="/login-company"
          />
        }
        onClickClose={() => setOpenMenuFilters(!openMenuFilters)}
      >
        <div className="pva_listContent_container">
          <div className="pva_icon_filter_container">
            <button
              className="pva_icon_filter_btn"
              onClick={() => setOpenMenuFilters(!openMenuFilters)}
            >
              <img
                className="pva_icon_filter_img"
                src={assets.icons.iconFilter}
                alt="Icon filter"
              />
            </button>
          </div>

          <div className="pva_list_container">
            {loadingArrApplicantsPreview ? (
              <LoadingTab />
            ) : arrApplicantsPreview.length === 0 ? (
              <ApplicantCardEmpty />
            ) : (
              arrApplicantsPreview.map((data, index) => {
                return (
                  <ApplicantCard key={`applicant_card_${index}`} data={data} />
                );
              })
            )}
          </div>
        </div>
      </SideMenu>
    </>
  );
};

export default PreviewApplicants;
