/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect } from "react";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import { t } from "../../languages/settingsLanguages";
import { useState } from "react";
import ViewAllWorksSuperAdmin from "./ViewAllWorksSuperAdmin";
import AddWorkSuperAdmin from "./AddWorkSuperAdmin";
import { useNavigate } from "react-router-dom";
import { userSuperAdmin } from "../../utils/Helpers";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
// ---------------------------------------------------------

const HomeSuperAdmin = ({ docTitle = "" }) => {
  const [tab, setTab] = useState("allWorks");
  const { token } = userSuperAdmin.get();

  const navigate = useNavigate();

  useEffect(() => {
    (token === "" || token === undefined) && navigate("/login-superadmin");
  }, [navigate, token]);

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}
      <Helmet>
        <title>
          {docTitle} | {t("Super Admin")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="superadmin" />

      <div className="super_works">
        <nav className="super_works_nav_container">
          <button
            onClick={() => setTab("allWorks")}
            className={`super_works_nav_btn super_btn_${tab === "allWorks"}`}
          >
            Jobs anzeigen
          </button>
          <button
            onClick={() => setTab("addWork")}
            className={`super_works_nav_btn super_btn_${tab === "addWork"}`}
          >
            Job hinzufügen
          </button>
        </nav>

        <div className="super_works_content">
          {tab === "allWorks" ? (
            <ViewAllWorksSuperAdmin />
          ) : tab === "addWork" ? (
            <AddWorkSuperAdmin />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeSuperAdmin;
