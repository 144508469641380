/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./FAQ.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Banner from "../../components/banner/Banner";
import Paragraph from "../../components/paragraph/Paragraph";
import ContainerWithColumns from "../../components/containerWithColumns/ContainerWithColumns";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const FAQ = ({ docTitle }) => {
  const faq = [
    {
      question: "Wie funktioniert CompanyU eigentlich?",
      response:
        "Bei CompanyU erstellen Handwerker ein kostenfreies Profil. Unternehmen können dann nach passenden Profilen suchen und Kontakt aufnehmen. Alles läuft unkompliziert, anonym und ist komplett kostenfrei für Handwerker!",
    },
    {
      question: "Kann ich als Handwerker anonym bleiben?",
      response:
        "Ja, die Anonymität unserer Handwerker ist uns sehr wichtig. Unternehmen sehen nur die Informationen, die Sie freigeben möchten.",
    },
    {
      question: "Was passiert, wenn ich einen Job finde?",
      response:
        "Super! Wenn Sie einen passenden Job finden und das Unternehmen ebenfalls Interesse hat, können Sie alles Weitere direkt mit dem Unternehmen klären. CompanyU steht Ihnen natürlich bei Fragen zur Seite.",
    },
    {
      question: "Welche Handwerksberufe können sich bei CompanyU anmelden?",
      response:
        "Bei CompanyU sind alle Handwerksberufe willkommen! Ob Elektriker, Maler, Zimmermann oder Installateur - hier finden Sie passende Jobs!",
    },
    {
      question: "Muss ich für die Services von CompanyU bezahlen?",
      response:
        "Nein, für Handwerker ist unser Service vollkommen kostenfrei! Unternehmen zahlen eine Gebühr, um auf unsere Plattform zugreifen zu können.",
    },
    {
      question: "Wie kann ich mein Profil löschen?",
      response:
        "Ihr Profil können Sie jederzeit in den Einstellungen Ihres Accounts löschen. Bei weiteren Fragen steht Ihnen unser Support zur Verfügung.",
    },
    {
      question: "Ist es sicher, meine Daten bei CompanyU zu hinterlegen?",
      response:
        "Ja, der Datenschutz hat bei uns oberste Priorität. Ihre Daten werden sicher gespeichert und sind nur für verifizierte Unternehmen sichtbar, und auch nur die Daten, die Sie freigeben möchten.",
    },
    {
      question: "Wie viele Unternehmen sind bei CompanyU registriert?",
      response:
        "Es sind bereits zahlreiche Unternehmen aus verschiedenen Branchen bei uns registriert, und es werden täglich mehr. So maximieren wir Ihre Chancen, den perfekten Job zu finden!",
    },
    {
      question: "Kann ich mehrere Jobs gleichzeitig annehmen?",
      response:
        "Ja, solange Sie die Jobs zeitlich und qualitativ bewältigen können, können Sie mehrere Aufträge annehmen.",
    },
    {
      question: "Wie erhalte ich Aufträge?",
      response:
        "Sie erhalten Anfragen direkt von Unternehmen. Wenn Sie Interesse haben, können Sie mit dem Unternehmen in Kontakt treten und alles Weitere besprechen. Hoffentlich hilft das als Grundlage für Ihr Q&A. Wenn Sie spezifischere Fragen haben oder andere Details hinzufügen möchten, lassen Sie es mich wissen!",
    },
    {
      question:
        "Kann ich meine Skills und Qualifikationen auf CompanyU zeigen?",
      response:
        "Absolut! Ihr Profil ist der perfekte Ort, um all Ihre Fähigkeiten, Qualifikationen und Erfahrungen zu präsentieren. So können Unternehmen direkt sehen, was Sie zu bieten haben!",
    },
    {
      question: "Wie schnell kann ich nach der Anmeldung Aufträge erhalten?",
      response:
        "Das hängt von vielen Faktoren ab, wie der Nachfrage in Ihrer Branche und Ihrem Standort. Aber je aussagekräftiger Ihr Profil ist, desto eher werden Unternehmen auf Sie aufmerksam!",
    },
    {
      question:
        "Was kann ich tun, wenn ich kein Feedback von Unternehmen erhalte?",
      response:
        "Kein Stress! Stellen Sie sicher, dass Ihr Profil vollständig und aussagekräftig ist und aktivieren Sie alle relevanten Skills und Qualifikationen. So maximieren Sie Ihre Sichtbarkeit für Unternehmen!",
    },
    {
      question: "Wie viele Profile kann ich erstellen?",
      response:
        "Wir empfehlen, nur ein Profil zu erstellen und dieses regelmäßig zu aktualisieren, um die besten Ergebnisse zu erzielen.",
    },
    {
      question: "Kann ich CompanyU auch im Ausland nutzen?",
      response:
        "Aktuell ist CompanyU auf den deutschen Markt ausgerichtet, aber wir planen, in Zukunft auch international tätig zu werden.",
    },
    {
      question: "Gibt es eine App von CompanyU?",
      response:
        "Derzeit bieten wir unsere Services nur über unsere Website an, arbeiten aber daran, Ihnen bald auch eine mobile Lösung anzubieten.",
    },
    {
      question: "Wie kann ich den Support von CompanyU kontaktieren?",
      response:
        "Sie erreichen unseren Support jederzeit per E-Mail oder über das Kontaktformular auf unserer Website. Wir sind für Sie da!",
    },
    {
      question: "Ich habe mein Passwort vergessen. Was nun?",
      response:
        "Kein Problem! Klicken Sie einfach auf „Passwort vergessen“ und folgen Sie den Anweisungen, um ein neues Passwort zu erstellen.",
    },
    {
      question:
        "Gibt es eine Garantie, dass ich über CompanyU einen Job finde?",
      response:
        "Wir können keine Garantie übernehmen, dass Sie einen Job finden, da dies von vielen unterschiedlichen Faktoren abhängt. Aber wir tun alles, um Ihre Chancen so hoch wie möglich zu machen!",
    },
    {
      question: "Kann ich Unternehmen bewerten, die mich kontaktiert haben?",
      response:
        "Ja, Ihr Feedback ist uns wichtig! Teilen Sie Ihre Erfahrungen, um anderen Handwerkern bei ihrer Suche zu helfen und um Unternehmen anzuregen, ihre Services zu verbessern. Wenn Sie spezifischere Fragen im Kopf haben oder Details zu den vorhandenen hinzufügen möchten, stehe ich Ihnen gerne zur Verfügung!",
    },
  ];

  return (
    <>
      {/* Class names starting with "faq" refer to the file: FAQ.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-about")}
        </title>
      </Helmet>

      <Header />

      <div>
        <h2 style={{ margin: "2rem auto", textAlign: "center" }}>
          {t("faq-title")}
        </h2>

        <div className="faq_container">
          {faq.map((ask, index) => {
            return (
              <details key={`faq_${index}`} className="faq_details_container">
                <summary>{ask.question}</summary>
                <p>{ask.response}</p>
              </details>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQ;
