/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./CompanyPackages.css";
import { t } from "../../languages/settingsLanguages";
import Header from "../../components/header/Header";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const BuySuccessful = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "cpk" refer to the file: CompanyPackages.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-buy-successful")}
        </title>
      </Helmet>

      <Header />

      <div className="cpk_buy_message_container">
        <h2 style={{ color: "#009900" }}>
          {t("company-buy-successful-title")}
        </h2>

        <div className="cpk_buy_message_img">
          <img src={assets.imgs.successful} alt="Buy successful" />
        </div>
      </div>
    </>
  );
};

export default BuySuccessful;
