/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./TestForm.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Input from "../../components/input/Input";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const TestForm = ({ docTitle = "" }) => {
  return (
    <>
      {/* Class names starting with "tf" refer to the file: TestForm.css */}
      <Helmet>
        <title>
          {docTitle} | {t("CRM Form")}
        </title>
      </Helmet>

      <Header />

      <div className="tf_container">
        <div className="tf_bgImg_container">
          <img
            className="tf_bgImg"
            src={assets.imgs.bannerApplicants}
            alt="Background Form"
          />
        </div>

        <div className="tf_card_container">
          <div className="tf_cicleDecoration" />
          <Input placeholder="Hola" />
        </div>
      </div>
    </>
  );
};

export default TestForm;
