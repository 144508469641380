/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./FormForInfluencer.css";
import { WebContext } from "../../context/Context";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Button from "../../components/button/Button";
import TabBarSteps, { Step } from "../../components/tabBarSteps/TabBarSteps";
import servicesformInfluencer from "./servicesFormForInfluencer";
import Input, { ChildrenLabelDefault } from "../../components/input/Input";
import { userInfluencer } from "../../utils/Helpers";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const FormForInfluencer = ({ docTitle }) => {
  const { dispatch } = WebContext();

  const [currentFormStepInfluencer, setCurrentFormStepInfluencer] = useState(1);
  const [arrCompletedInfluencer, setArrCompletedInfluencer] = useState([
    { completed: false },
    { completed: false },
  ]);
  const [formInfluencer, setFormInfluencer] = useState({
    // ------------- Step 1 -------------
    influencer_mail: "",
    influencer_password: "",
    influencer_repeat_password: "",
    // ------------- Step 2 -------------
    influencer_name: "",
    influencer_phone: "",
    influencer_city: "",
    influencer_postal_code: "",
  });

  const [errorFree, setErrorFree] = useState(formInfluencer);

  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);
  const navigate = useNavigate();
  const { token } = userInfluencer.get();

  useEffect(() => {
    token !== "" && navigate("/home-associated");
  }, [token, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isResultTabVisible !== "successful") {
        // The standard message that will be displayed may be different   depending on the browser.
        let mensaje = "Are you sure you want to go out?";

        // Set the return message for the event.
        e.returnValue = mensaje; // Standard for some browsers.

        return mensaje; // Some browsers may require the message to be returned.
      }
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isResultTabVisible]);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // ---------------- Regular expresions ----------------

  const handleChangeStep = (step) => {
    setCurrentFormStepInfluencer(step);
  };

  const handleChangeNextStep = (step) => {
    let next = step + 1;

    handleChangeStep(next);
  };

  const handleChangeBackStep = (step) => {
    let back = step - 1;

    handleChangeStep(back);
  };

  const handleChangeInput = (target, isOK) => {
    setFormInfluencer({
      ...formInfluencer,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleResponse = (res) => {
    if (res === "successful" || res.response === "successful") {
      navigate("/home-associated");
    } else if (res === "failed-login" || res.response === "failed-login") {
      navigate("/login-associated");
    }
    return setIsResultTabVisible(
      typeof res === "string" || typeof res === "boolean" ? res : res.response
    );
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  // --------------------- Validating all steps ---------------------
  const handleChangeCompleted = (step, state = false) => {
    let indexStep = step - 1;
    let copyArrCompletedInfluencer = arrCompletedInfluencer;

    copyArrCompletedInfluencer[indexStep] = { completed: state };

    return setArrCompletedInfluencer(copyArrCompletedInfluencer);
  };

  // ------------ Validation step 1 ------------
  if (currentFormStepInfluencer === 1) {
    if (!arrCompletedInfluencer[0].completed) {
      // influencer_mail
      // influencer_password
      // influencer_repeat_password
      if (
        errorFree.influencer_mail &&
        errorFree.influencer_password &&
        errorFree.influencer_repeat_password
      )
        handleChangeCompleted(1, true);
    } else if (arrCompletedInfluencer[0].completed) {
      if (
        !errorFree.influencer_mail ||
        !errorFree.influencer_password ||
        !errorFree.influencer_repeat_password
      )
        handleChangeCompleted(1, false);
    }
    // ------------ /Validation step 1 ------------

    // ------------ Validation step 2 ------------
  } else if (currentFormStepInfluencer === 2) {
    if (!arrCompletedInfluencer[1].completed) {
      // influencer_phone
      // influencer_city
      // influencer_postal_code
      if (
        errorFree.influencer_phone &&
        errorFree.influencer_city &&
        errorFree.influencer_postal_code
      ) {
        handleChangeCompleted(2, true);
      }
    } else if (arrCompletedInfluencer[1].completed) {
      if (
        !errorFree.influencer_phone ||
        !errorFree.influencer_city ||
        !errorFree.influencer_postal_code
      ) {
        handleChangeCompleted(2, false);
      }
    }
    // ------------ /Validation step 2 ------------

    // ------------ Validation step 3 ------------
  }

  return (
    <>
      {/* Class names starting with "ffi" refer to the file: FormForInfluencer.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-form-influencer")}
        </title>
      </Helmet>

      <Header />

      <div className="ffc_main_container">
        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={assets.icons.iconSending}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={assets.imgs.successful}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={assets.imgs.failed}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible !== "successful" && (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/register-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <TabBarSteps
          currentFormStep={currentFormStepInfluencer}
          arr={arrCompletedInfluencer}
          onclick={(step) => handleChangeStep(step)}
        />

        <form id="register-influencer" className="ffc_form_container">
          {currentFormStepInfluencer === 1 && (
            <Step
              numStep={1}
              currentFormStepInfluencer={currentFormStepInfluencer}
              arr={arrCompletedInfluencer}
              title={t("form-influencer-step-1-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Email -------------------- */}
              <Input
                id="influencer_mail"
                nameInput="influencer_mail"
                // placeholder={t("form-company-step-1-register-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={formInfluencer.influencer_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(formInfluencer.influencer_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-register-mail")}
                  />
                }
                errorMessage={
                  formInfluencer.influencer_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(formInfluencer.influencer_mail)
                    ? t("form-company-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Email -------------------- */}

              {/* -------------------- Password -------------------- */}
              <Input
                id="influencer_password"
                nameInput="influencer_password"
                // placeholder={t("form-company-step-1-password")}
                placeholder="******"
                defaultValue={formInfluencer.influencer_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value.length < 6;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-password")}
                  />
                }
                errorMessage={
                  formInfluencer.influencer_password.length === 0
                    ? t("form-required")
                    : formInfluencer.influencer_password.length < 6
                    ? t("form-company-step-1-please-valid-password")
                    : ""
                }
              />
              {/* -------------------- /Password -------------------- */}

              {/* -------------------- Repeat password -------------------- */}
              <Input
                id="influencer_repeat_password"
                nameInput="influencer_repeat_password"
                // placeholder={t("form-company-step-1-repeat-password")}
                placeholder="******"
                defaultValue={formInfluencer.influencer_repeat_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value !== formInfluencer.influencer_password;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-repeat-password")}
                  />
                }
                errorMessage={
                  formInfluencer.influencer_repeat_password.length === 0
                    ? t("form-required")
                    : formInfluencer.influencer_repeat_password !==
                      formInfluencer.influencer_password
                    ? t("form-company-step-1-please-valid-repeat-password")
                    : ""
                }
              />
              {/* -------------------- /Repeat password -------------------- */}
            </Step>
          )}

          {currentFormStepInfluencer === 2 && (
            <Step
              numStep={2}
              currentFormStepInfluencer={currentFormStepInfluencer}
              arr={arrCompletedInfluencer}
              title={t("form-company-step-2-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
              lastStep
              onFinish={() =>
                servicesformInfluencer(
                  dispatch,
                  formInfluencer,
                  (res) => handleResponse(res),
                  (loading) => handleLoadingFinish(loading)
                )
              }
              custonNameBtnNext={t("form-btn-register")}
            >
              {/* ------------------ Name ------------------ */}
              <Input
                id="influencer_name"
                nameInput="influencer_name"
                placeholder={t("Ihr Name")}
                defaultValue={formInfluencer.influencer_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault required txt={t("Ihr Name")} />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Name ----------------------- */}

              {/* ---------------- Phone number ---------------- */}
              <Input
                id="influencer_phone"
                nameInput="influencer_phone"
                // placeholder={t("form-applicant-step-1-phone-number")}
                placeholder="04900000000"
                defaultValue={formInfluencer.influencer_phone}
                type="tel"
                required
                handleBlurContainsError={(e) => {
                  let validNumberPhone =
                    e.target.value.length < 11 || e.target.value.length > 13;

                  return validNumberPhone;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-phone")}
                  />
                }
                errorMessage={
                  formInfluencer.influencer_phone.length === 0
                    ? t("form-required")
                    : formInfluencer.influencer_phone.length < 11 ||
                      formInfluencer.influencer_phone.length > 13
                    ? t("form-applicant-step-1-please-valid-number")
                    : ""
                }
              />
              {/* ---------------- /Phone number ---------------- */}

              {/* ------------------ City ------------------ */}
              <Input
                id="influencer_city"
                nameInput="influencer_city"
                placeholder={t("Stadt in der du lebst")}
                defaultValue={formInfluencer.influencer_city}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("Stadt in der du lebst")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /City ----------------------- */}

              {/* -------------------- Postal code -------------------- */}
              <Input
                id="influencer_postal_code"
                nameInput="influencer_postal_code"
                type="number"
                // placeholder={t("form-applicant-step-1-postal-code")}
                placeholder="000000"
                defaultValue={formInfluencer.influencer_postal_code}
                minLength={4}
                maxLength={13}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-postal-code")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Postal code -------------------- */}
            </Step>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForInfluencer;
