/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_REGISTER_INFLUENCER, TOKEN_DB } from "../../api/apis";
import servicesLoginInfluencer from "../loginInfluencer/servicesLoginInfluencer";
// ---------------------------------------------------------

const servicesFormForInfluencer = async (
  dispatch,
  data,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;
  const _data = {
    mail: data.influencer_mail,
    password: data.influencer_password,
    name: data.influencer_name,
    phone: data.influencer_phone,
    city: data.influencer_city,
    zip: data.influencer_postal_code,
  };

  await fetch(`${API}${PATH_REGISTER_INFLUENCER}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-token": TOKEN_DB,
    },
    body: JSON.stringify(_data),
  })
    .then((response) => response.json())
    .then((res) => {
      let credentials = {
        mail: data.influencer_mail,
        password: data.influencer_password,
      };

      servicesLoginInfluencer(
        dispatch,
        credentials,
        (res) => response(res === "failed" ? "failed-login" : res),
        (load) => loading(load)
      );
    })
    .catch((error) => {
      response("failed");
      loading(false);
    });
};

export default servicesFormForInfluencer;
