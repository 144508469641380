/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./GainsInfluencer.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import servicesGainsInfluencer from "./servicesGainsInfluencer";
import { userInfluencer } from "../../utils/Helpers";
import { useState } from "react";
// ---------------------------------------------------------

const GainsInfluencer = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const { name, id, token } = userInfluencer.get();

  const [gains, setGains] = useState({
    newRegistrationsInThisMonth: "",
    dateStart: "",
    dateEnd: "",
    totalUnlocks: "",
    unlockedPeople: [],
  });
  const [modalDates, setModalDates] = useState(false);

  let _date = new Date();
  let _year = _date.getFullYear();
  let _month = _date.getMonth() + 1;
  let dateCurrent = `${_year}-${_month}`;

  const [month, setMonth] = useState(dateCurrent);

  useEffect(() => {
    console.log(month);
  });

  const navigate = useNavigate();

  let records = Number(gains.newRegistrationsInThisMonth);
  let amount =
    records <= 250
      ? 10
      : records >= 250 && records <= 500
      ? 12.5
      : records >= 501
      ? 15
      : 10;

  return (
    <>
      {/* Class names starting with "gsi" refer to the file: GainsInfluencer.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-home-company")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="influencer" />

      <div className="gsi_container">
        <h2>Affiliate-Insight-Dashboard von Partner {name}</h2>

        <section className="gsi_note_container">
          <p className="gsi_note_item">
            0 - 250 Registrierungen = <b>10€</b>
          </p>

          <p className="gsi_note_item">
            251 - 500 Registrierungen rückwirkend = <b>12.50€</b>
          </p>

          <p className="gsi_note_item">
            mehr als 501 Registrierungen rückwirkend = <b>15€</b>
          </p>
        </section>

        <section className="gsi_table_container">
          <div className="gsi_table_header">
            <div className="gsi_table_header_period_container">
              <button
                className="gsi_table_header_period_btn"
                onClick={() => setModalDates(!modalDates)}
              >
                <h3>
                  Zeitraum:{" "}
                  <b className="gsi_table_header_period_btn_underlining">
                    {gains.dateStart} - {gains.dateEnd}
                  </b>
                </h3>
              </button>

              {modalDates && (
                <>
                  <section className="gsi_table_header_period_modal">
                    <input
                      type="month"
                      onChange={(e) => {
                        setMonth(e.target.value);
                        setModalDates(false);
                      }}
                      defaultValue={month}
                    />
                  </section>
                </>
              )}
            </div>

            <div className="gsi_table_header_info">
              <p>
                <b>{amount}€</b> pro Freischaltung (Kontaktaufnahme)
              </p>
            </div>

            <div className="gsi_table_header_info">
              <p>
                Gesamtanzahl der Kontaktaufnahmen:{" "}
                <b>{gains.totalUnlocks === "" ? 0 : gains.totalUnlocks}</b>
              </p>
            </div>

            <div className="gsi_table_header_info">
              <p>
                <b>Ausschüttung: {amount * gains.totalUnlocks}€</b>
              </p>
            </div>
          </div>

          <div className="gsi_table_data_title_container">
            <div className="gsi_table_data_name">
              <h5>Name des Bewerbers</h5>
            </div>

            <div className="gsi_table_data_unlocks">
              <h5>Kontaktaufnahmen</h5>
            </div>

            <div className="gsi_table_data_generated">
              <h5>Generiert</h5>
            </div>
          </div>

          <div className="gsi_table_data_content">
            {gains.unlockedPeople.map((item, index) => {
              return (
                <section
                  key={`table_gains_${index}`}
                  className="gsi_table_data_person"
                >
                  <div className="gsi_table_data_name">
                    <p>{item.name}</p>
                  </div>

                  <div className="gsi_table_data_unlocks">
                    <p>{item.unlocks}</p>
                  </div>

                  <div className="gsi_table_data_generated">
                    <p>{Number(item.unlocks) * amount}€</p>
                  </div>
                </section>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default GainsInfluencer;
