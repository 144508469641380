/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./DataProtection.css";
import { t } from "../../languages/settingsLanguages";
import Header from "../../components/header/Header";
import Paragraph from "../../components/paragraph/Paragraph";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const DataProtection = ({ docTitle }) => {
  const DataLink = ({ link = "" }) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    );
  };

  return (
    <>
      {/* Class names starting with "dp" refer to the file: ForApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("data-protection-title")}
        </title>
      </Helmet>

      <Header />

      <div className="dp_container">
        {/* 
        <Paragraph
          withTitle
          title={t("data-protection-title")}
          otherClassNameTitle="dp_title"
        /><Paragraph children={t("dp-1")} />

        <Paragraph withTitle title={t("dp-2-title")}>
          <p>Company U GbR</p>

          <p>St. Veit - Straße 9 27809 Lemwerder</p>

          <Button
            type="link"
            link="tel:4915161402225"
            title="Tel: +4915161402225"
            otherClassNameBtn="dp_link"
          />

          <Button
            type="link"
            link="mailto:info@companyu.de"
            title="Mail: info@companyu.de"
            otherClassNameBtn="dp_link"
          />
        </Paragraph>

        <Paragraph withTitle title={t("dp-3-title")}>
          {t("dp-3-1")}
        </Paragraph>

        <Paragraph withTitle title={t("dp-4-title")}>
          <p>{t("dp-4-1")}</p>

          <p>{t("dp-4-2")}</p>

          <p>{t("dp-4-3")}</p>

          <p>{t("dp-4-4")}</p>

          <p>{t("dp-4-5")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-5-title")}>
          <p>{t("dp-5-1")}</p>

          <p>{t("dp-5-2")}</p>

          <p>{t("dp-5-3")}</p>

          <p>{t("dp-5-4")}</p>

          <p>{t("dp-5-5")}</p>

          <p>{t("dp-5-6")}</p>

          <p>{t("dp-5-7")}</p>
        </Paragraph>

        <Paragraph otherClassNameContainer="dp_special_container">
          <p>{t("dp-5-special-1")}</p>

          <p>{t("dp-5-special-2")}</p>
        </Paragraph>

        <Paragraph>
          <p>{t("dp-5-8")}</p>

          <p>{t("dp-5-9")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-6-10-title")}>
          <p>{t("dp-6-1")}</p>

          <p>{t("dp-6-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-7-title")}>
          <p>{t("dp-7-1")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-8-title")}>
          <p>{t("dp-8-1")}</p>

          <p>{t("dp-8-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-9-title")}>
          <p>{t("dp-9-1")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-10-title")}>
          <p>{t("dp-10-1")}</p>

          <p>{t("dp-10-2")}</p>

          <p>{t("dp-10-3")}</p>

          <p>{t("dp-10-4")}</p>

          <p>{t("dp-10-5")}</p>

          <p>{t("dp-10-6")}</p>

          <p>
            {t("dp-10-7")} <DataLink link={"https://www.zendesk.de/"} />
          </p>

          <p>
            {t("dp-10-8-1")}{" "}
            <DataLink link="https://www.zendesk.de/company/privacy-and-data-protection/" />{" "}
            {t("dp-10-8-2")}{" "}
            <DataLink link="https://www.zendesk.de/company/legal/" />
          </p>

          <p>{t("dp-10-9")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-11-title")}>
          <p>{t("dp-11-1")}</p>

          <p>{t("dp-11-2")}</p>

          <p>{t("dp-11-3")}</p>

          <p>{t("dp-11-4")}</p>

          <p>{t("dp-11-5")}</p>

          <p>{t("dp-11-6")}</p>

          <p>
            {t("dp-11-7")}{" "}
            <DataLink link="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop" />
          </p>

          <p>
            {t("dp-11-8")}{" "}
            <DataLink link="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en" />
          </p>

          <p>
            {t("dp-11-9")}{" "}
            <DataLink link="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d" />
          </p>

          <p>
            {t("dp-11-10")}{" "}
            <DataLink link="https://support.apple.com/de-de/guide/safari/sfri11471/mac" />
          </p>

          <p>
            {t("dp-11-11")}{" "}
            <DataLink link="https://help.opera.com/de/latest/web-preferences/#cookies" />
          </p>

          <p>
            {t("dp-11-12")}{" "}
            <DataLink link="https://browser.yandex.com/help/personal-data-protection/cookies.html" />
          </p>

          <p>
            {t("dp-11-13-1")}{" "}
            <DataLink link="https://www.youronlinechoices.eu/" />{" "}
            {t("dp-11-13-2")}{" "}
            <DataLink link="https://optout.networkadvertising.org/?c=1" />
          </p>

          <p>{t("dp-11-14")}</p>

          <p>{t("dp-11-15")}</p>

          <p>
            {t("dp-11-16")}{" "}
            <DataLink link="https://support.mozilla.org/de/kb/wie-verhindere-ich-dass-websites-mich-verfolgen" />
          </p>

          <p>
            {t("dp-11-17")}{" "}
            <DataLink link="https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&hl=de" />
          </p>

          <p>
            {t("dp-11-18")}{" "}
            <DataLink link="https://support.microsoft.com/de-de/windows/verwenden-von-do-not-track-in-internet-explorer-11-ad61fa73-d533-ce96-3f64-2aa3a332e792" />
          </p>

          <p>
            {t("dp-11-19")}{" "}
            <DataLink link="https://help.opera.com/de/latest/security-and-privacy/" />
          </p>

          <p>
            {t("dp-11-20")}{" "}
            <DataLink link="https://support.apple.com/de-de/guide/safari/sfri40732/12.0/mac" />
          </p>

          <p>
            {t("dp-11-21")}{" "}
            <DataLink link="https://browser.yandex.com/help/personal-data-protection/ytp.html" />
          </p>

          <p>{t("dp-11-22")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-12-title")}>
          <p>{t("dp-12-1")}</p>

          <p>{t("dp-12-2")}</p>

          <p>{t("dp-12-3")}</p>

          <p>{t("dp-12-4")}</p>

          <p>{t("dp-12-5")}</p>

          <p>{t("dp-12-6")}</p>

          <p>{t("dp-12-7")}</p>

          <p>{t("dp-12-8")}</p>

          <p>
            {t("dp-12-9")}{" "}
            <DataLink link="https://mailchimp.com/de/?currency=EUR" />
          </p>

          <p>
            {t("dp-12-10")}{" "}
            <DataLink link="https://www.intuit.com/privacy/statement/" />
          </p>

          <p>{t("dp-12-11")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-13-title")}>
          <p>{t("dp-13-1")}</p>

          <p>{t("dp-13-2")}</p>

          <p>{t("dp-13-3")}</p>

          <p>{t("dp-13-4")}</p>

          <p className="dp_minisibtitle">{t("dp-13-5")}</p>

          <p>{t("dp-13-6")}</p>

          <p>
            {t("dp-13-7")}{" "}
            <DataLink link="https://www.google.com/intl/de_de/adsense/start/" />
          </p>

          <p>
            {t("dp-13-8")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-9-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-9-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-10")}</p>

          <p>{t("dp-13-11")}</p>

          <p>
            {t("dp-13-12")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/analytics/" />
          </p>

          <p>
            {t("dp-13-13")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />
          </p>

          <p>
            {t("dp-13-14-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-14-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-15")}</p>

          <p>{t("dp-13-16")}</p>

          <p>
            {t("dp-13-17")}{" "}
            <DataLink link="https://marketingplatform.google.com/about/analytics-360/" />
          </p>

          <p>
            {t("dp-13-18-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-18-2")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-19-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-19-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-20")}</p>

          <p>{t("dp-13-21")}</p>

          <p>
            {t("dp-13-22")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/10089681?hl=de" />
          </p>

          <p>
            {t("dp-13-23-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-23-2")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-24-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-24-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-25")}</p>

          <p>{t("dp-13-26")}</p>

          <p>
            {t("dp-13-27")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/optimize/" />
          </p>

          <p>
            {t("dp-13-28-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-28-1")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-29-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-29-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-30")}</p>

          <p>{t("dp-13-31")}</p>

          <p>
            {t("dp-13-32")}{" "}
            <DataLink link="https://support.google.com/google-ads/answer/2453998" />
          </p>

          <p>
            {t("dp-13-33")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-34-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-34-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-35")}</p>

          <p>{t("dp-13-36")}</p>

          <p>
            {t("dp-13-37")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/search-ads-360/" />
          </p>

          <p>
            {t("dp-13-38")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-39-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-39-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-40")}</p>

          <p>{t("dp-13-41")}</p>

          <p>
            {t("dp-13-42")}{" "}
            <DataLink link="https://mailchimp.com/de/?currency=EUR" />
          </p>

          <p>
            {t("dp-13-43")}{" "}
            <DataLink link="https://www.intuit.com/privacy/statement/" />
          </p>

          <p>{t("dp-13-44")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-14-title")}>
          <p>{t("dp-14-1")}</p>

          <p>{t("dp-14-2")}</p>

          <p>{t("dp-14-3")}</p>

          <p className="dp_minisibtitle">{t("dp-14-4")}</p>

          <p>{t("dp-14-5")}</p>

          <p>
            {t("dp-14-6")} <DataLink link="https://www.facebook.com/" />
          </p>

          <p>
            {t("dp-14-7-1")}{" "}
            <DataLink link="https://developers.facebook.com/docs/plugins/" />{" "}
            {t("dp-14-7-2")}{" "}
            <DataLink link="https://de-de.facebook.com/help/568137493302217" />
          </p>

          <p>{t("dp-14-8")}</p>

          <p className="dp_minisibtitle">{t("dp-14-9")}</p>

          <p>{t("dp-14-10")}</p>

          <p>
            {t("dp-14-11")} <DataLink link="https://www.instagram.com/" />
          </p>

          <p>
            {t("dp-14-12-1")}{" "}
            <DataLink link="https://help.instagram.com/581066165581870" />{" "}
            {t("dp-14-12-2")}{" "}
            <DataLink link="https://help.instagram.com/519522125107875" />
          </p>

          <p>{t("dp-14-13")}</p>

          <p className="dp_minisibtitle">{t("dp-14-14")}</p>

          <p>{t("dp-14-15")}</p>

          <p>
            {t("dp-14-16")} <DataLink link="https://www.tiktok.com/" />
          </p>

          <p>
            {t("dp-14-17")}{" "}
            <DataLink link="https://www.tiktok.com/legal/privacy-policy?lang=de" />
          </p>

          <p>{t("dp-14-18")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-15-title")}>
          <p>{t("dp-15-1")}</p>

          <p>{t("dp-15-2")}</p>

          <p>{t("dp-15-3")}</p>

          <p className="dp_minisibtitle">{t("dp-15-4")}</p>

          <p>{t("dp-15-5")}</p>

          <p>
            {t("dp-15-6")} <DataLink link="https://www.fontawesome.com/" />
          </p>

          <p>
            {t("dp-15-7")} <DataLink link="https://fontawesome.com/privacy" />
          </p>

          <p className="dp_minisibtitle">{t("dp-15-8")}</p>

          <p>{t("dp-15-9")}</p>

          <p>
            {t("dp-15-10")} <DataLink link="https://fonts.google.com/" />
          </p>

          <p>
            {t("dp-15-11")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-15-12-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-15-12-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-15-13")}</p>

          <p>{t("dp-15-14")}</p>

          <p>
            {t("dp-15-15")} <DataLink link="https://openjsf.org/" />
          </p>

          <p>
            {t("dp-15-16")}{" "}
            <DataLink link="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf" />
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-16-title")}>
          <p>{t("dp-16-1")}</p>

          <p>{t("dp-16-2")}</p>

          <p>{t("dp-16-3")}</p>

          <p className="dp_minisibtitle">{t("dp-16-4")}</p>

          <p>{t("dp-16-5")}</p>

          <p>
            {t("dp-16-6")} <DataLink link="https://www.google.de/maps" />
          </p>

          <p>
            {t("dp-16-7")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-16-8-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-16-8-2")}
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-17-title")}>
          <p>{t("dp-17-1")}</p>

          <p>{t("dp-17-2")}</p>

          <p>{t("dp-17-3")}</p>

          <p>{t("dp-17-4")}</p>

          <p className="dp_minisibtitle">{t("dp-17-5")}</p>

          <p>{t("dp-17-6")}</p>

          <p>
            {t("dp-17-7")}{" "}
            <DataLink link="https://support.google.com/merchants/answer/7124326?hl=de" />
          </p>

          <p>
            {t("dp-17-8")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-17-9-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-17-9-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-17-10")}</p>

          <p>{t("dp-17-11")}</p>

          <p>
            {t("dp-17-12")}{" "}
            <DataLink link="https://www.trustedshops.de/bewertungen/" />
          </p>

          <p>
            {t("dp-17-13")}{" "}
            <DataLink link="https://www.trustedshops.de/impressum/#datenschutz" />
          </p>

          <p className="dp_minisibtitle">{t("dp-17-14")}</p>

          <p>{t("dp-17-15")}</p>

          <p>
            {t("dp-17-16")} <DataLink link="https://de.trustpilot.com/" />
          </p>

          <p>
            {t("dp-17-17")}{" "}
            <DataLink link="https://de.legal.trustpilot.com/end-user-privacy-terms" />
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-18-title")}>
          <p>{t("dp-18-1")}</p>

          <p>{t("dp-18-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-19-title")}>
          <p>{t("dp-19-1")}</p>

          <p>{t("dp-19-2")}</p>

          <p>{t("dp-19-3")}</p>

          <p className="dp_minisibtitle">{t("dp-19-4")}</p>

          <p>{t("dp-19-5")}</p>

          <p>
            {t("dp-19-6")} <DataLink link="https://www.google.com/recaptcha/" />
          </p>

          <p>
            {t("dp-19-7")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-19-8-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-19-8-2")}
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-20-title")}>
          <p>{t("dp-20-1")}</p>

          <p>{t("dp-20-2")}</p>
        </Paragraph>

        <Paragraph>
          <p>
            {t("dp-21-1-1")} <DataLink link="https://www.getlaw.de/" />{" "}
            {t("dp-21-1-2")}
          </p>
        </Paragraph> */}

        {/* -------------------------------------------------- */}

        <Paragraph
          withTitle
          title={t("data-protection-title")}
          otherClassNameTitle="dp_title"
        >
          <h2>1) Einleitung und Kontaktdaten des Verantwortlichen</h2>

          <p>
            <b>1.1</b>&nbsp;Wir freuen uns, dass Sie unsere Website besuchen und
            bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir
            Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der
            Nutzung unserer Website. Personenbezogene Daten sind hierbei alle
            Daten, mit denen Sie pers&ouml;nlich identifiziert werden
            k&ouml;nnen.
          </p>

          <p>
            <b>1.2</b>&nbsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf
            dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist
            Company U GbR, St.Veit Stra&szlig;e 9, 27809 Lemwerder, Deutschland,
            Tel.: 01746611026, E-Mail: tamo.kroll@companyu.de. Der f&uuml;r die
            Verarbeitung von personenbezogenen Daten Verantwortliche ist
            diejenige nat&uuml;rliche oder juristische Person, die allein oder
            gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten entscheidet.
          </p>

          <h2>2) Datenerfassung beim Besuch unserer Website</h2>

          <p>
            <b>2.1</b>&nbsp;Bei der blo&szlig; informatorischen Nutzung unserer
            Website, also wenn Sie sich nicht registrieren oder uns anderweitig
            Informationen &uuml;bermitteln, erheben wir nur solche Daten, die
            Ihr Browser an den Seitenserver &uuml;bermittelt (sog.
            &bdquo;Server-Logfiles&#8220;). Wenn Sie unsere Website aufrufen,
            erheben wir die folgenden Daten, die f&uuml;r uns technisch
            erforderlich sind, um Ihnen die Website anzuzeigen:
          </p>

          <ul>
            <li>Unsere besuchte Website</li>
            <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
          </ul>

          <p>
            Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
            auf Basis unseres berechtigten Interesses an der Verbesserung der
            Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine
            Weitergabe oder anderweitige Verwendung der Daten findet nicht
            statt. Wir behalten uns allerdings vor, die Server-Logfiles
            nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete
            Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
          </p>

          <p>
            <b>2.2</b>&nbsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und
            zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer
            vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den
            Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie
            k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge
            &bdquo;https://&#8220; und dem Schloss-Symbol in Ihrer Browserzeile
            erkennen.
          </p>

          <h2>3) Cookies</h2>

          <p>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu erm&ouml;glichen, verwenden wir Cookies,
            also kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt
            werden. Teilweise werden diese Cookies nach Schlie&szlig;en des
            Browsers automatisch wieder gel&ouml;scht (sog.
            &bdquo;Session-Cookies&#8220;), teilweise verbleiben diese Cookies
            l&auml;nger auf Ihrem Endger&auml;t und erm&ouml;glichen das
            Speichern von Seiteneinstellungen (sog. &bdquo;persistente
            Cookies&#8220;). Im letzteren Fall k&ouml;nnen Sie die Speicherdauer
            der &Uuml;bersicht zu den Cookie-Einstellungen Ihres Webbrowsers
            entnehmen.
          </p>

          <p>
            Sofern durch einzelne von uns eingesetzte Cookies auch
            personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
            gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur
            Durchf&uuml;hrung des Vertrages, gem&auml;&szlig; Art. 6 Abs. 1 lit.
            a DSGVO im Falle einer erteilten Einwilligung oder gem&auml;&szlig;
            Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten
            Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website
            sowie einer kundenfreundlichen und effektiven Ausgestaltung des
            Seitenbesuchs.
          </p>

          <p>
            Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
            Setzen von Cookies informiert werden und einzeln &uuml;ber deren
            Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte
            F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen.
          </p>

          <p>
            Bitte beachten Sie, dass bei Nichtannahme von Cookies die
            Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
          </p>

          <h2>4) Kontaktaufnahme</h2>

          <p>
            Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
            E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
            der Nutzung eines Kontaktformulars erhoben werden, ist aus dem
            jeweiligen Kontaktformular ersichtlich. Diese Daten werden
            ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw.
            f&uuml;r die Kontaktaufnahme und die damit verbundene technische
            Administration gespeichert und verwendet.
          </p>

          <p>
            Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser
            berechtigtes Interesse an der Beantwortung Ihres Anliegens
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre
            Kontaktierung auf den Abschluss eines Vertrages ab, so ist
            zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6
            Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender
            Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn
            sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der
            betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und
            sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
          </p>

          <h2>5) Registrierung beim Portal bzw. Forum</h2>

          <p>
            Sie k&ouml;nnen sich auf unserer Website unter Angabe von
            personenbezogenen Daten registrieren. Welche personenbezogenen Daten
            f&uuml;r die Registrierung verarbeitet werden, ergibt sich aus der
            Eingabemaske, die f&uuml;r die Registrierung verwendet wird. Wir
            verwenden f&uuml;r die Registrierung das sog.
            Double-opt-in-Verfahren, d.&ThinSpace;h. Ihre Registrierung ist erst
            abgeschlossen, wenn Sie zuvor Ihre Anmeldung &uuml;ber eine Ihnen zu
            diesem Zweck zugesandte Best&auml;tigungs-E-Mail durch Klick auf den
            darin enthaltenem Link best&auml;tigt haben. Falls Ihre
            diesbez&uuml;gliche Best&auml;tigung nicht binnen 24 Stunden
            erfolgt, wird Ihre Anmeldung automatisch aus unserer Datenbank
            gel&ouml;scht. Die Angabe der zuvor genannten Daten ist
            verpflichtend. Alle weiteren Informationen k&ouml;nnen Sie
            freiwillig durch Nutzung unseres Portals bereitstellen.
          </p>

          <p>
            Wenn Sie unser Portal nutzen, speichern wir Ihre zur
            Vertragserf&uuml;llung erforderlichen Daten, auch evtl. Angaben zur
            Zahlungsweise, bis Sie Ihren Zugang endg&uuml;ltig l&ouml;schen.
            Weiterhin speichern wir die von Ihnen angegebenen freiwilligen Daten
            f&uuml;r die Zeit Ihrer Nutzung des Portals, soweit Sie diese nicht
            zuvor l&ouml;schen. Alle Angaben k&ouml;nnen Sie im gesch&uuml;tzten
            Kundenbereich verwalten und &auml;ndern. Rechtsgrundlage ist Art. 6
            Abs. 1 lit. f DSGVO.
          </p>

          <p>
            Dar&uuml;ber hinaus speichern wir alle von Ihnen
            ver&ouml;ffentlichten Inhalte (wie z.B. &ouml;ffentliche
            Beitr&auml;ge, Pinnwandeintr&auml;ge, G&auml;stebucheintragungen,
            etc.), um die Website zu betreiben. Wir haben ein berechtigtes
            Interesse an der Bereitstellung der Website mit dem
            vollst&auml;ndigen User-Generated-Content. Die Rechtsgrundlage
            hierf&uuml;r ist Art. 6 Abs. 1 lit. f DSGVO. Wenn Sie Ihren Account
            l&ouml;schen, bleiben Ihre &ouml;ffentlichen &Auml;u&szlig;erungen,
            insbesondere im Forum, weiterhin f&uuml;r alle Leser sichtbar, Ihr
            Account ist jedoch nicht mehr abrufbar. Alle &uuml;brigen Daten
            werden in diesem Fall gel&ouml;scht.
          </p>

          <h2>6) Nutzung von Kundendaten zur Direktwerbung</h2>

          <p>
            <b>6.1</b>&nbsp;Anmeldung zu unserem E-Mail-Newsletter
          </p>

          <p>
            Wenn Sie sich zu unserem E-Mail Newsletter anmelden, &uuml;bersenden
            wir Ihnen regelm&auml;&szlig;ig Informationen zu unseren Angeboten.
            Pflichtangabe f&uuml;r die &Uuml;bersendung des Newsletters ist
            allein Ihre E-Mailadresse. Die Angabe weiterer Daten ist freiwillig
            und wird verwendet, um Sie pers&ouml;nlich ansprechen zu
            k&ouml;nnen. F&uuml;r den Versand des Newsletters verwenden wir das
            sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst
            dann einen E-Mail Newsletter &uuml;bermitteln werden, wenn Sie uns
            ausdr&uuml;cklich best&auml;tigt haben, dass Sie in den Empfang von
            Newsletter einwilligen. Wir schicken Ihnen dann eine
            Best&auml;tigungs-E-Mail, mit der Sie gebeten werden durch Anklicken
            eines entsprechenden Links zu best&auml;tigen, dass Sie k&uuml;nftig
            den Newsletter erhalten wollen.
          </p>

          <p>
            Mit der Aktivierung des Best&auml;tigungslinks erteilen Sie uns Ihre
            Einwilligung f&uuml;r die Nutzung Ihrer personenbezogenen Daten
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum
            Newsletter speichern wir Ihre vom Internet Service-Provider (ISP)
            eingetragene IP-Adresse sowie das Datum und die Uhrzeit der
            Anmeldung, um einen m&ouml;glichen Missbrauch Ihrer E-Mail- Adresse
            zu einem sp&auml;teren Zeitpunkt nachvollziehen zu k&ouml;nnen. Die
            von uns bei der Anmeldung zum Newsletter erhobenen Daten werden
            ausschlie&szlig;lich f&uuml;r Zwecke der werblichen Ansprache im
            Wege des Newsletters benutzt. Sie k&ouml;nnen den Newsletter
            jederzeit &uuml;ber den daf&uuml;r vorgesehenen Link im Newsletter
            oder durch entsprechende Nachricht an den eingangs genannten
            Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre
            E-Mailadresse unverz&uuml;glich in unserem Newsletter-Verteiler
            gel&ouml;scht, soweit Sie nicht ausdr&uuml;cklich in eine weitere
            Nutzung Ihrer Daten eingewilligt haben oder wir uns eine
            dar&uuml;berhinausgehende Datenverwendung vorbehalten, die
            gesetzlich erlaubt ist und &uuml;ber die wir Sie in dieser
            Erkl&auml;rung informieren.
          </p>

          <p>
            <b>6.2</b>&nbsp;Versand des E-Mail-Newsletters an Bestandskunden
          </p>

          <p>
            Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw.
            Dienstleistungen zur Verf&uuml;gung gestellt haben, behalten wir uns
            vor, Ihnen regelm&auml;&szlig;ig Angebote zu &auml;hnlichen Waren
            bzw. Dienstleistungen, wie den bereits gekauften, aus unserem
            Sortiment per E-Mail zuzusenden. Hierf&uuml;r m&uuml;ssen wir
            gem&auml;&szlig; &sect; 7 Abs. 3 UWG keine gesonderte Einwilligung
            von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein
            auf Basis unseres berechtigten Interesses an personalisierter
            Direktwerbung gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Haben Sie
            der Nutzung Ihrer E-Mailadresse zu diesem Zweck anf&auml;nglich
            widersprochen, findet ein Mailversand unsererseits nicht statt.
          </p>

          <p>
            Sie sind berechtigt, der Nutzung Ihrer E-Mailadresse zu dem
            vorbezeichneten Werbezweck jederzeit mit Wirkung f&uuml;r die
            Zukunft durch eine Mitteilung an den zu Beginn genannten
            Verantwortlichen zu widersprechen. Hierf&uuml;r fallen f&uuml;r Sie
            lediglich &Uuml;bermittlungskosten nach den Basistarifen an. Nach
            Eingang Ihres Widerspruchs wird die Nutzung Ihrer E-Mailadresse zu
            Werbezwecken unverz&uuml;glich eingestellt.
          </p>

          <p>
            <b>6.3</b>&nbsp;SendGrid
          </p>

          <p>
            Der Versand unserer E-Mail-Newsletter erfolgt &uuml;ber diesen
            Anbieter: SendGrid Inc., 1801 California St #500, Denver, CO 80202,
            USA
          </p>

          <p>
            Auf Basis unseres berechtigten Interesses an einem effektiven und
            nutzerfreundlichen Newsletter-Marketing geben wir Ihre bei der
            Newsletter-Anmeldung bereitgestellten Daten gem. Art. 6 Abs. 1 lit.
            f DSGVO an diesen Anbieter weiter, damit dieser den
            Newsletterversand in unserem Auftrag &uuml;bernimmt.
          </p>

          <p>
            Vorbehaltlich Ihrer ausdr&uuml;cklichen Einwilligung gem. Art. 6
            Abs. 1 lit. a DSGVO f&uuml;hrt der Anbieter dar&uuml;ber hinaus eine
            statistische Erfolgsauswertung von Newsletter-Kampagnen mittels Web
            Beacons bzw. Z&auml;hlpixel in den versendeten E-Mails durch, die
            &Ouml;ffnungsraten und spezifische Interaktionen mit den Inhalten
            des Newsletters messen k&ouml;nnen. Dabei werden auch
            Endger&auml;teinformationen (z.B. Zeitpunkt des Aufrufs, IP-Adresse,
            Browsertyp und Betriebssystem) erhoben und ausgewertet, aber nicht
            mit anderen Datenbest&auml;nden zusammengef&uuml;hrt.
          </p>

          <p>
            Ihre Einwilligung zum Newsletter-Tracking k&ouml;nnen Sie jederzeit
            mit Wirkung f&uuml;r die Zukunft widerrufen.
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus&nbsp;sicherstellt.
          </p>

          <p>
            <b>6.4</b>&nbsp;Werbung per Briefpost
            <br />
            Auf Grundlage unseres berechtigten Interesses an personalisierter
            Direktwerbung behalten wir uns vor, Ihren Vor- und Nachnamen, Ihre
            Postanschrift und - soweit wir diese zus&auml;tzlichen Angaben im
            Rahmen der Vertragsbeziehung von Ihnen erhalten haben - Ihren Titel,
            akademischen Grad, Ihr Geburtsjahr und Ihre Berufs-, Branchen- oder
            Gesch&auml;ftsbezeichnung gem&auml;&szlig; Art. 6 Abs. 1 lit. f
            DSGVO zu speichern und f&uuml;r die Zusendung von interessanten
            Angeboten und Informationen zu unseren Produkten per Briefpost zu
            nutzen.
            <br />
            Sie k&ouml;nnen der Speicherung und Nutzung Ihrer Daten zu diesem
            Zweck jederzeit uns gegen&uuml;ber widersprechen.
          </p>

          <h2>7) Webanalysedienste</h2>

          <p>Google Analytics 4</p>

          <p>
            Diese Website benutzt Google Analytics 4, einen Webanalysedienst der
            Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5,
            Irland (&quot;Google&quot;), der eine Analyse Ihrer Benutzung
            unserer Website erm&ouml;glicht.
          </p>

          <p>
            Standardm&auml;&szlig;ig werden beim Besuch der Website durch Google
            Analytics 4 Cookies gesetzt, die als kleine Textbausteine auf Ihrem
            Endger&auml;t abgelegt werden und bestimmte Informationen erheben.
            Zum Umfang dieser Informationen geh&ouml;rt auch Ihre IP-Adresse,
            die allerdings von Google um die letzten Ziffern gek&uuml;rzt wird,
            um eine direkte Personenbeziehbarkeit auszuschlie&szlig;en.
          </p>

          <p>
            Die Informationen werden an Server von Google &uuml;bertragen und
            dort weiterverarbeitet. Dabei sind auch &Uuml;bermittlungen an
            Google LLC mit Sitz in den USA m&ouml;glich.
          </p>

          <p>
            Google nutzt die erhobenen Informationen in unserem Auftrag, um Ihre
            Nutzung der Website auszuwerten, Reports &uuml;ber die
            Websiteaktivit&auml;ten f&uuml;r uns zusammenzustellen und um
            weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser &uuml;bermittelte und gek&uuml;rzte IP-Adresse
            wird nicht mit anderen Daten von Google zusammengef&uuml;hrt. Die im
            Rahmen der Nutzung von Google Analytics 4 erhobenen Daten werden
            f&uuml;r die Dauer von zwei Monaten gespeichert und
            anschlie&szlig;end gel&ouml;scht.
          </p>

          <p>
            Alle vorstehend beschriebenen Verarbeitungen, insbesondere das
            Setzen von Cookies auf dem verwendeten Endger&auml;t, erfolgen nur,
            wenn Sie uns hierf&uuml;r Ihre ausdr&uuml;ckliche Einwilligung gem.
            Art. 6 Abs. 1 lit. a DSGVO erteilt haben.
            <br />
            Ohne Ihre Einwilligung unterbleibt der Einsatz von Google Analytics
            4 w&auml;hrend Ihres Seitenbesuchs. Sie k&ouml;nnen Ihre erteilte
            Einwilligung mit Wirkung f&uuml;r die Zukunft jederzeit widerrufen.
            Um Ihr Widerrufsrecht auszu&uuml;ben, deaktivieren Sie bitte diesen
            Dienst &uuml;ber das auf der Website bereitgestellte
            &bdquo;Cookie-Consent-Tool&#8220;.
          </p>

          <p>
            Wir haben mit Google einen Auftragsverarbeitungsvertrag geschlossen,
            der den Schutz der Daten unserer Seitenbesucher sicherstellt und
            eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            Weitere rechtliche Hinweise zu Google Analytics 4 finden Sie unter{" "}
            <a
              href="https://policies.google.com/privacy?hl=de&amp;gl=de"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=de&amp;gl=de
            </a>{" "}
            und unter{" "}
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
            >
              https://policies.google.com/technologies/partner-sites
            </a>
            <br />
            <br />
            Demografische Merkmale
            <br />
            Google Analytics 4 nutzt die spezielle Funktion &bdquo;demografische
            Merkmale&#8220; und kann dar&uuml;ber Statistiken erstellen, die
            Aussagen &uuml;ber das Alter, Geschlecht und Interessen von
            Seitenbesuchern treffen. Dies geschieht durch die Analyse von
            Werbung und Informationen von Drittanbietern. Dadurch k&ouml;nnen
            Zielgruppen f&uuml;r Marketingaktivit&auml;ten identifiziert werden.
            Die gesammelten Daten k&ouml;nnen jedoch keiner bestimmten Person
            zugeordnet werden und werden nach einer Speicherung f&uuml;r die
            Dauer von zwei Monaten gel&ouml;scht.
          </p>

          <p>
            Google Signals
            <br />
            Als Erweiterung zu Google Analytics 4 kann auf dieser Website Google
            Signals verwendet werden, um ger&auml;te&uuml;bergreifende Berichte
            erstellen zu lassen. Wenn Sie personalisierte Anzeigen aktiviert
            haben und Ihre Ger&auml;te mit Ihrem Google-Konto verkn&uuml;pft
            haben, kann Google vorbehaltlich Ihrer Einwilligung zur Nutzung von
            Google Analytics gem. Art. 6 Abs. 1 lit. a DSGVO Ihr
            Nutzungsverhalten ger&auml;te&uuml;bergreifend analysieren und
            Datenbankmodelle, unter anderem zu ger&auml;te&uuml;bergreifenden
            Conversions, erstellen. Wir erhalten keine personenbezogenen Daten
            von Google, sondern nur Statistiken. Wenn Sie die
            ger&auml;te&uuml;bergreifende Analyse stoppen m&ouml;chten,
            k&ouml;nnen Sie die Funktion &quot;Personalisierte Werbung&quot; in
            den Einstellungen Ihres Google-Kontos deaktivieren. Folgen Sie dazu
            den Anweisungen auf dieser Seite:{" "}
            <a
              href="https://support.google.com/ads/answer/2662922?hl=de"
              target="_blank"
            >
              https://support.google.com/ads/answer/2662922?hl=de
            </a>{" "}
            Weitere Informationen zu Google Signals finden Sie unter folgendem
            Link:{" "}
            <a
              href="https://support.google.com/analytics/answer/7532985?hl=de"
              target="_blank"
            >
              https://support.google.com/analytics/answer/7532985?hl=de
            </a>
          </p>

          <p>
            UserIDs
            <br />
            Als Erweiterung zu Google Analytics 4 kann auf dieser Website die
            Funktion &quot;UserIDs&quot; verwendet werden. Wenn Sie in die
            Nutzung von Google Analytics 4 gem. Art. 6 Abs. 1 lit. a DSGVO
            eingewilligt, ein Konto auf dieser Website eingerichtet haben und
            sich auf verschiedenen Ger&auml;ten mit diesem Konto anmelden,
            k&ouml;nnen Ihre Aktivit&auml;ten, darunter auch Conversions,
            ger&auml;te&uuml;bergreifend analysiert werden.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus&nbsp;sicherstellt.
          </p>

          <h2>8) Retargeting/ Remarketing und Conversion-Tracking</h2>

          <p>Facebook-Pixel zur Erstellung von Custom Audiences</p>

          <p>
            Innerhalb unseres Online-Angebots verwenden wir den Dienst
            &quot;Facebook Pixel&quot; des folgenden Anbieters: Meta Platforms
            Ireland Limited, 4 Grand Canal Quare, Dublin 2, Irland
            (&quot;Facebook&quot;)
          </p>

          <p>
            Klickt ein Nutzer auf eine von uns auf Facebook geschaltete
            Werbeanzeige, wird mithilfe von &quot;Facebook Pixel&quot; die URL
            unserer verlinkten Seite um einen Parameter erweitert. Dieser
            URL-Parameter wird dann nach der Weiterleitung durch ein Cookie, das
            unsere verlinkte Seite selbst setzt, in den Browser des Nutzers
            eingetragen.
          </p>

          <p>
            Dadurch ist es einerseits Facebook m&ouml;glich, die Besucher
            unseres Onlineangebotes als Zielgruppe f&uuml;r die Darstellung von
            Anzeigen (sog. &quot;Facebook-Ads&quot;) zu bestimmen.
            Dementsprechend setzen wir den Dienst ein, um die durch uns
            geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen,
            die auch ein Interesse an unserem Onlineangebot gezeigt haben oder
            die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
            Produkten, die anhand der besuchten Webseiten bestimmt werden)
            aufweisen, welche wir an Facebook &uuml;bermitteln (sog.
            &bdquo;Custom Audiences&#8220;).
          </p>

          <p>
            Andererseits kann mit &bdquo;Facebook Pixel&#8220; nachvollzogen
            werden, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf
            unsere Website weitergeleitet wurden und welche
            Ausf&uuml;hrungshandlungen sie dort t&auml;tigen (sog.
            &bdquo;Conversion-Tracking&#8220;).
          </p>

          <p>
            Die erhobenen Daten sind f&uuml;r uns anonym, bieten uns also keine
            R&uuml;ckschl&uuml;sse auf die Identit&auml;t der Nutzer. Allerdings
            werden die Daten von Facebook gespeichert und verarbeitet, sodass
            eine Verbindung zum jeweiligen Nutzerprofil m&ouml;glich ist und
            Facebook die Daten f&uuml;r eigene Werbezwecke verwenden kann.
          </p>

          <p>
            Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von
            Cookies f&uuml;r das Auslesen von Informationen auf dem verwendeten
            Endger&auml;t, werden nur dann vollzogen, wenn Sie uns
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
            ausdr&uuml;ckliche Einwilligung erteilt haben. Sie k&ouml;nnen Ihre
            erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft
            widerrufen, indem Sie diesen Dienst in dem auf der Webseite
            bereitgestellten &bdquo;Cookie-Consent-Tool&#8220; deaktivieren.
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            Die durch Facebook erzeugten Informationen werden in der Regel an
            einen Server von Facebook &uuml;bertragen und dort gespeichert; in
            diesem Zusammenhang kann es auch zu einer &Uuml;bertragung an Server
            von Meta Platforms Inc. in den USA kommen.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus sicherstellt.
          </p>

          <h2>9) Seitenfunktionalit&auml;ten</h2>

          <p>
            <b>9.1</b>&nbsp;Facebook-Plugins
          </p>

          <p>
            Auf unserer Website werden Plugins des sozialen Netzwerkes des
            folgenden Anbieters verwendet: Meta Platforms Ireland Ltd., 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2, Irland
          </p>

          <p>
            Diese Plugins erm&ouml;glichen direkte Interaktionen mit Inhalten
            auf dem sozialen Netzwerk.
          </p>

          <p>
            Um den Schutz Ihrer Daten beim Besuch unserer Website zu
            erh&ouml;hen, sind die Plugins zun&auml;chst deaktiviert mittels
            sogenannter &bdquo;2-Klick&#8220;- oder
            &bdquo;Shariff&#8220;-L&ouml;sung in die Seite eingebunden.
          </p>

          <p>
            Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite
            unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine
            Verbindung mit den Servern des Anbieters hergestellt wird.
          </p>

          <p>
            Erst wenn Sie die Plugins aktivieren und damit gem&auml;&szlig; Art.
            6 Abs. 1 lit. a DSGVO Ihre Einwilligung in die
            Daten&uuml;bermittlung erteilen, stellt Ihr Browser eine direkte
            Verbindung zu den Servern des Anbieters her. Hierbei werden,
            unabh&auml;ngig von einem Login in ein vorhandenes Nutzerprofil, in
            bestimmtem Umfang Informationen &uuml;ber Ihr verwendetes
            Endger&auml;t (darunter Ihre IP-Adresse), Ihren Browser und Ihren
            Seitenverlauf an den Anbieter &uuml;bermittelt und dort
            gegebenenfalls weiterverarbeitet.
          </p>

          <p>
            Wenn Sie in ein vorhandenes Nutzerprofil auf dem sozialen Netzwerk
            des Anbieters eingeloggt sind, werden Informationen zu &uuml;ber die
            Plugins vollzogenen Interaktionen au&szlig;erdem dort
            ver&ouml;ffentlicht und Ihren Kontakten angezeigt.
            <br />
            Sie k&ouml;nnen Ihre Einwilligung jederzeit widerrufen indem Sie das
            aktivierte Plugin durch erneutes Anklicken wieder deaktivieren. Der
            Widerruf hat jedoch keinen Einfluss auf die Daten, die bereits an
            den Anbieter &uuml;bertragen wurden.
          </p>

          <p>
            Daten k&ouml;nnen zudem &uuml;bertragen werden an: Meta Platforms
            Inc., USA
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus sicherstellt.
          </p>

          <p>
            <b>9.2</b>&nbsp;Instagram-Plugins
          </p>

          <p>
            Auf unserer Website werden Plugins des sozialen Netzwerkes des
            folgenden Anbieters verwendet: Meta Platforms Ireland Ltd., 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2 Irland
          </p>

          <p>
            Diese Plugins erm&ouml;glichen direkte Interaktionen mit Inhalten
            auf dem sozialen Netzwerk.
          </p>

          <p>
            Um den Schutz Ihrer Daten beim Besuch unserer Website zu
            erh&ouml;hen, sind die Plugins zun&auml;chst deaktiviert mittels
            sogenannter &bdquo;2-Klick&#8220;- oder
            &bdquo;Shariff&#8220;-L&ouml;sung in die Seite eingebunden.
          </p>

          <p>
            Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite
            unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine
            Verbindung mit den Servern des Anbieters hergestellt wird.
          </p>

          <p>
            Erst wenn Sie die Plugins aktivieren und damit gem&auml;&szlig; Art.
            6 Abs. 1 lit. a DSGVO Ihre Einwilligung in die
            Daten&uuml;bermittlung erteilen, stellt Ihr Browser eine direkte
            Verbindung zu den Servern des Anbieters her. Hierbei werden,
            unabh&auml;ngig von einem Login in ein vorhandenes Nutzerprofil, in
            bestimmtem Umfang Informationen &uuml;ber Ihr verwendetes
            Endger&auml;t (darunter Ihre IP-Adresse), Ihren Browser und Ihren
            Seitenverlauf an den Anbieter &uuml;bermittelt und dort
            gegebenenfalls weiterverarbeitet.
            <br />
            <br />
            Wenn Sie in ein vorhandenes Nutzerprofil auf dem sozialen Netzwerk
            des Anbieters eingeloggt sind, werden Informationen zu &uuml;ber die
            Plugins vollzogenen Interaktionen au&szlig;erdem dort
            ver&ouml;ffentlicht und Ihren Kontakten angezeigt.
            <br />
            Sie k&ouml;nnen Ihre Einwilligung jederzeit widerrufen indem Sie das
            aktivierte Plugin durch erneutes Anklicken wieder deaktivieren. Der
            Widerruf hat jedoch keinen Einfluss auf die Daten, die bereits an
            den Anbieter &uuml;bertragen wurden.
          </p>

          <p>
            Daten k&ouml;nnen zudem &uuml;bertragen werden: Meta Platforms Inc.,
            USA
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus sicherstellt.
          </p>

          <p>
            <b>9.3</b>&nbsp;Google Maps
          </p>

          <p>
            Diese Webseite nutzt einen Online-Kartendienst des folgenden
            Anbieters: Google Maps (API) von Google Ireland Limited, Gordon
            House, 4 Barrow St, Dublin, D04 E5W5, Irland (&#8220;Google&rdquo;).
          </p>

          <p>
            Google Maps ist ein Webdienst zur Darstellung von interaktiven
            (Land-)Karten, um geographische Informationen visuell darzustellen.
            &Uuml;ber die Nutzung dieses Dienstes wird Ihnen unser Standort
            angezeigt und eine etwaige Anfahrt erleichtert.
          </p>

          <p>
            Bereits beim Aufrufen derjenigen Unterseiten, in die die Karte von
            Google Maps eingebunden ist, werden Informationen &uuml;ber Ihre
            Nutzung unserer Website (wie z.B. Ihre IP-Adresse) an Server von
            Google &uuml;bertragen und dort gespeichert, hierbei kann es auch zu
            einer &Uuml;bermittlung an die Server der Google LLC. in den USA
            kommen. Dies erfolgt unabh&auml;ngig davon, ob Google ein
            Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind oder ob
            ein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
            Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit
            Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen Sie sich
            vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten
            (selbst f&uuml;r nicht eingeloggte Nutzer) als Nutzungsprofile und
            wertet diese aus.
          </p>

          <p>
            Die Erhebung, Speicherung und die Auswertung erfolgen
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis des
            berechtigten Interesses von Google an der Einblendung
            personalisierter Werbung, Marktforschung und/oder der
            bedarfsgerechten Gestaltung von Google-Websites. Ihnen steht ein
            Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei
            Sie sich f&uuml;r dessen Aus&uuml;bung an Google wenden m&uuml;ssen.
            Wenn Sie mit der k&uuml;nftigen &Uuml;bermittlung Ihrer Daten an
            Google im Rahmen der Nutzung von Google Maps nicht einverstanden
            sind, besteht auch die M&ouml;glichkeit, den Webdienst von Google
            Maps vollst&auml;ndig zu deaktivieren, indem Sie die Anwendung
            JavaScript in Ihrem Browser ausschalten. Google Maps und damit auch
            die Kartenanzeige auf dieser Internetseite kann dann nicht genutzt
            werden.
          </p>

          <p>
            Soweit rechtlich erforderlich, haben wir zur vorstehend
            dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie
            k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben,
            befolgen Sie bitte die vorstehend geschilderte M&ouml;glichkeit zur
            Vornahme eines Widerspruchs.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus sicherstellt.
          </p>

          <p>
            <b>9.4</b>&nbsp;Google Forms
          </p>

          <p>
            F&uuml;r die Durchf&uuml;hrung von Umfragen oder bei
            Online-Formularen verwenden wir die Dienste des folgenden Anbieters:
            Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland
          </p>

          <p>
            Neben einer &Uuml;bermittlung von Daten an den o.g. Anbieterstandort
            k&ouml;nnen Daten auch &uuml;bermittelt werden an: Google LLC, USA
          </p>

          <p>
            Der Anbieter erm&ouml;glicht es uns, Umfragen und Onlineformulare zu
            gestalten und auszuwerten. Neben den jeweiligen personenbezogenen
            Daten, die Sie in die Formulare eingeben, werden auch Informationen
            zu Ihrem Betriebssystem, Browser, Datum und Uhrzeit Ihres Besuchs,
            Referrer-URL sowie Ihre IP-Adresse erhoben, an den Anbieter
            &uuml;bermittelt und auf Servern des Anbieters gespeichert.
          </p>

          <p>
            Die Speicherung der von Ihnen in die Formulare eingegebenen
            Informationen erfolgt passwortgesch&uuml;tzt, damit sichergestellt
            ist, dass Drittzugriffe ausgeschlossen werden und nur wir die Daten
            zum im Formular jeweilig benannten Zweck auswerten k&ouml;nnen.
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten, die zur
            Erf&uuml;llung eines Vertrages mit Ihnen erforderlich sind (dies
            gilt auch f&uuml;r Verarbeitungsvorg&auml;nge, die zur
            Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich
            sind), dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Haben
            Sie uns eine Einwilligung f&uuml;r die Verarbeitung Ihrer Daten
            erteilt, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1
            lit. a DSGVO. Eine erteilte Einwilligung kann jederzeit mit Wirkung
            f&uuml;r die Zukunft widerrufen werden.
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unbefugte Weitergabe an Dritte untersagt.
          </p>

          <p>
            F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter
            dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework)
            angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen
            Datenschutzniveaus&nbsp;sicherstellt.
          </p>

          <p>
            <b>9.5</b>&nbsp;- Yumpu
          </p>

          <p>
            Wir verwenden f&uuml;r die Darstellung von PDF-Dateien auf unserer
            Webseite den Service &bdquo;Yumpu&#8220; des Anbieters i-magazine AG
            (&bdquo;Yumpu&#8220;), Gewerbestrasse 3, 9444 Diepoldsau, Schweiz.
          </p>

          <p>
            Mittels Yumpu wird der Inhalt von pdf-Dateien als sogenannter
            Bl&auml;tterkatalog f&uuml;r jedermann frei zug&auml;nglich und
            einfach lesbar direkt im Webbrowser dargestellt, ohne dass die
            Dateien zun&auml;chst heruntergeladen werden m&uuml;ssten. Zur
            Ausf&uuml;hrung des Dienstes ruft Ihr Webbrowser die Inhalte direkt
            bei Yumpu ab. Yumpu erh&auml;lt dabei Informationen &uuml;ber Ihren
            Webbrowser und das verwendete Betriebssystem ebenso wie Ihre
            IP-Adresse. Yumpu verwendet diese Daten ausschlie&szlig;lich zu
            Zwecken der Online-Darstellung der betroffenen Dateien. Soweit
            hierbei personenbezogene Daten erhoben und an Yumpu &uuml;bermittelt
            werden, erfolgt dies gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf
            Basis unseres berechtigten Interesses an der nutzerfreundlichen
            Abrufbereit und der optimalen Integration von PDF-Dateien auf
            unserem Internetauftritt. F&uuml;r den Fall der &Uuml;bermittlung
            von Daten an Yumpu in der Schweiz ist durch Angemessenheitsbeschluss
            der Europ&auml;ischen Kommission das angemessene Datenschutzniveau
            gew&auml;hrleistet.&nbsp;
          </p>

          <p>
            <b>9.6</b>&nbsp;Bewerbungen auf Stellenausschreibungen per E-Mail
          </p>

          <p>
            Auf unserer Website schreiben wir in einer gesonderten Rubrik
            aktuell vakante Stellen aus, auf die sich Interessenten per E-Mail
            an die bereitgestellte Kontaktadresse bewerben k&ouml;nnen.
          </p>

          <p>
            Die Bewerber m&uuml;ssen alle personenbezogenen Daten angeben, die
            f&uuml;r eine fundierte Beurteilung erforderlich sind,
            einschlie&szlig;lich allgemeiner Informationen wie Name, Anschrift
            und Kontaktm&ouml;glichkeiten, sowie leistungsbezogene Nachweise und
            gegebenenfalls gesundheitsbezogene Angaben. Einzelheiten zur
            Bewerbung sind der Stellenausschreibung zu entnehmen.
          </p>

          <p>
            Nach Eingang der Bewerbung per E-Mail werden die Daten
            ausschlie&szlig;lich zum Zwecke der Bewerbungsbearbeitung
            gespeichert und ausgewertet. Bei R&uuml;ckfragen nutzen wir entweder
            die E-Mail-Adresse oder Telefonnummer des Bewerbers. Die
            Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO
            (bzw. &sect; 26 Abs. 1 BDSG), in deren Sinne das Durchlaufen des
            Bewerbungsverfahrens als Arbeitsvertragsanbahnung gilt.
          </p>

          <p>
            Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von
            personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
            Gesundheitsdaten wie Angaben &uuml;ber die
            Schwerbehinderteneigenschaft) bei Bewerbern angefragt werden,
            erfolgt die Verarbeitung gem&auml;&szlig; Art. 9 Abs. 2 lit. b.
            DSGVO, damit wir die aus dem Arbeitsrecht und dem Recht der sozialen
            Sicherheit und des Sozialschutzes erwachsenden Rechte aus&uuml;ben
            und unseren diesbez&uuml;glichen Pflichten nachkommen k&ouml;nnen.
          </p>

          <p>
            Kumulativ oder alternativ kann die Verarbeitung der besonderen
            Datenkategorien auch auf Art. 9 Abs. 1 lit. h DSGVO gest&uuml;tzt
            sein, wenn sie zu Zwecken der Gesundheitsvorsorge oder der
            Arbeitsmedizin, f&uuml;r die Beurteilung der Arbeitsf&auml;higkeit
            des Bewerbers, f&uuml;r die medizinische Diagnostik, die Versorgung
            oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die
            Verwaltung von Systemen und Diensten im Gesundheits- oder
            Sozialbereich erfolgt.
          </p>

          <p>
            Kommt es nicht zu einer Auswahl des Bewerbers oder zieht ein
            Bewerber seine Bewerbung vorzeitig zur&uuml;ck, werden dessen
            &uuml;bermittelte Daten sowie s&auml;mtlicher elektronischer
            Schriftverkehr einschlie&szlig;lich der Bewerbungsmail nach einer
            entsprechenden Benachrichtigung sp&auml;testens nach 6 Monaten
            gel&ouml;scht. Diese Frist bemisst sich nach unserem berechtigten
            Interesse, etwaige Anschlussfragen zu der Bewerbung zu beantworten
            und gegebenenfalls unseren Nachweispflichten aus den Vorschriften
            zur Gleichbehandlung von Bewerbern nachkommen zu k&ouml;nnen.
          </p>

          <p>
            Im Falle einer erfolgreichen Bewerbung werden die zur Verf&uuml;gung
            gestellten Daten auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (bei
            Verarbeitung in Deutschland i.V.m. &sect; 26 Abs. 1 BDSG) zum Zwecke
            der Durchf&uuml;hrung des Besch&auml;ftigungsverh&auml;ltnisses
            verarbeitet.
          </p>

          <p>
            <b>9.7</b>&nbsp;Online-Bewerbungen &uuml;ber ein Formular
          </p>

          <p>
            Auf unserer Website schreiben wir in einer gesonderten Rubrik
            aktuell vakante Stellen aus, auf die sich Interessenten &uuml;ber
            ein entsprechendes Formular bewerben k&ouml;nnen.
          </p>

          <p>
            Die Bewerber m&uuml;ssen alle personenbezogenen Daten angeben, die
            f&uuml;r eine fundierte Beurteilung erforderlich sind,
            einschlie&szlig;lich allgemeiner Informationen wie Name, Anschrift
            und Kontaktm&ouml;glichkeiten, sowie leistungsbezogene Nachweise und
            gegebenenfalls gesundheitsbezogene Angaben. Einzelheiten zur
            Bewerbung sind der Stellenausschreibung zu entnehmen.
          </p>

          <p>
            Im Zuge des Absendens des Formulars werden die Bewerberdaten
            entsprechend dem Stand der Technik verschl&uuml;sselt an uns
            &uuml;bermittelt, von uns gespeichert und ausschlie&szlig;lich zum
            Zwecke der Bewerbungsbearbeitung ausgewertet. Die Verarbeitung
            erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (bzw. &sect; 26
            Abs. 1 BDSG), in deren Sinne das Durchlaufen des
            Bewerbungsverfahrens als Arbeitsvertragsanbahnung gilt.
          </p>

          <p>
            Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von
            personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
            Gesundheitsdaten wie Angaben &uuml;ber die
            Schwerbehinderteneigenschaft) bei Bewerbern angefragt werden,
            erfolgt die Verarbeitung gem&auml;&szlig; Art. 9 Abs. 2 lit. b.
            DSGVO, damit wir die aus dem Arbeitsrecht und dem Recht der sozialen
            Sicherheit und des Sozialschutzes erwachsenden Rechte aus&uuml;ben
            und unseren diesbez&uuml;glichen Pflichten nachkommen k&ouml;nnen.
          </p>

          <p>
            Kumulativ oder alternativ kann die Verarbeitung der besonderen
            Datenkategorien auch auf Art. 9 Abs. 1 lit. h DSGVO gest&uuml;tzt
            sein, wenn sie zu Zwecken der Gesundheitsvorsorge oder der
            Arbeitsmedizin, f&uuml;r die Beurteilung der Arbeitsf&auml;higkeit
            des Bewerbers, f&uuml;r die medizinische Diagnostik, die Versorgung
            oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die
            Verwaltung von Systemen und Diensten im Gesundheits- oder
            Sozialbereich erfolgt.
          </p>

          <p>
            Kommt es nicht zu einer Auswahl des Bewerbers oder zieht ein
            Bewerber seine Bewerbung vorzeitig zur&uuml;ck, werden dessen
            formularm&auml;&szlig;ig &uuml;bermittelte Daten sowie
            s&auml;mtlicher elektronischer Schriftverkehr einschlie&szlig;lich
            der Bewerbungsmail nach einer entsprechenden Benachrichtigung
            sp&auml;testens nach 6 Monaten gel&ouml;scht. Diese Frist bemisst
            sich nach unserem berechtigten Interesse, etwaige Anschlussfragen zu
            der Bewerbung zu beantworten und gegebenenfalls unseren
            Nachweispflichten aus den Vorschriften zur Gleichbehandlung von
            Bewerbern nachkommen zu k&ouml;nnen.
          </p>

          <p>
            Im Falle einer erfolgreichen Bewerbung werden die zur Verf&uuml;gung
            gestellten Daten auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (bei
            Verarbeitung in Deutschland i.V.m. &sect; 26 Abs. 1 BDSG) zum Zwecke
            der Durchf&uuml;hrung des Besch&auml;ftigungsverh&auml;ltnisses
            verarbeitet.
          </p>

          <h2>10) Tools und Sonstiges</h2>

          <p>
            <b>10.1</b>&nbsp;Cookie-Consent-Tool
          </p>

          <p>
            Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen
            f&uuml;r einwilligungspflichtige Cookies und cookie-basierte
            Anwendungen ein sog. &bdquo;Cookie-Consent-Tool&#8220;. Das
            &bdquo;Cookie-Consent-Tool&#8220; wird Nutzern bei Seitenaufruf in
            Form einer interaktive Benutzeroberfl&auml;che angezeigt, auf
            welcher sich per H&auml;kchensetzung Einwilligungen f&uuml;r
            bestimmte Cookies und/oder cookie-basierte Anwendungen erteilen
            lassen. Hierbei werden durch den Einsatz des Tools alle
            einwilligungspflichtigen Cookies/Dienste nur dann geladen, wenn der
            jeweilige Nutzer entsprechende Einwilligungen per
            H&auml;kchensetzung erteilt. So wird sichergestellt, dass nur im
            Falle einer erteilten Einwilligung derartige Cookies auf dem
            jeweiligen Endger&auml;t des Nutzers gesetzt werden.
          </p>

          <p>
            Das Tool setzt technisch notwendige Cookies, um Ihre
            Cookie-Pr&auml;ferenzen zu speichern. Personenbezogene Nutzerdaten
            werden hierbei grunds&auml;tzlich nicht verarbeitet.
          </p>

          <p>
            Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder
            Protokollierung von Cookie-Einstellungen doch zur Verarbeitung
            personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese
            gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
            berechtigten Interesses an einem rechtskonformen, nutzerspezifischen
            und nutzerfreundlichen Einwilligungsmanagement f&uuml;r Cookies und
            mithin an einer rechtskonformen Ausgestaltung unseres
            Internetauftritts.
          </p>

          <p>
            Weitere Rechtsgrundlage f&uuml;r die Verarbeitung ist ferner Art. 6
            Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der
            rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger
            Cookies von der jeweiligen Nutzereinwilligung abh&auml;ngig zu
            machen.
          </p>

          <p>
            Soweit erforderlich, haben wir mit dem Anbieter einen
            Auftragsverarbeitungsvertrag geschlossen, der den Schutz der Daten
            unserer Seitenbesucher sicherstellt und eine unberechtigte
            Weitergabe an Dritte untersagt.
          </p>

          <p>
            Weitere Informationen zum Betreiber und den
            Einstellungsm&ouml;glichkeiten des Cookie-Consent-Tools finden Sie
            direkt in der entsprechenden Benutzeroberfl&auml;che auf unserer
            Website.
          </p>

          <p>
            <b>10.2</b>&nbsp;All-In-One Security (AIOS)
          </p>

          <p>
            Zu Sicherheitszwecken verwendet diese Webseite den Dienst des
            folgenden Anbieters: Updraft WP Software Ltd., 11, Barringer Way,
            St. Neots, Cambs. PE19 1LW, UK
          </p>

          <p>
            Der Anbieter sch&uuml;tzt die Webseite und die damit verbundene
            IT-Infrastruktur vor unberechtigten Drittzugriffen, Cyberangriffen
            sowie vor Viren und Malware. Der Anbieter erfasst die IP-Adressen
            von Nutzern sowie gegebenenfalls weitere Daten zu Ihrem Verhalten
            auf unserer Webseite (insbesondere aufgerufene URLs und
            Header-Informationen), um illegitime Seitenzugriffe und Gefahren zu
            erkennen und abzuwehren. Dabei wird die erfasste IP-Adresse mit
            einer Liste bekannter Angreifer verglichen. Wird die erfasste
            IP-Adresse als Sicherheitsrisiko erkannt, kann der Anbieter diese
            automatische f&uuml;r den Seitenzugriff sperren. Die so erhobenen
            Informationen werden an einen Server des Anbieters &uuml;bertragen
            und dort gespeichert.
          </p>

          <p>
            Die beschriebenen Datenverarbeitungen erfolgen gem&auml;&szlig; Art.
            6 Abs. 1 lit. f DSGVO auf Basis unsere berechtigten Interessen am
            Schutz der Webseite vor sch&auml;dlichen Cyber-Angriffen und an der
            Wahrung der Struktur- und Datenintegrit&auml;t und -sicherheit.
          </p>

          <p>
            Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag
            geschlossen, der den Schutz der Daten unserer Seitenbesucher
            sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.
          </p>

          <p>
            Bei einer Daten&uuml;bermittlung an den Anbieterstandort ist ein
            angemessenes Datenschutzniveau durch einen Angemessenheitsbeschluss
            der Europ&auml;ischen Kommission gew&auml;hrleistet.
          </p>

          <h2>11) Rechte des Betroffenen</h2>

          <p>
            <b>11.1</b>&nbsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen
            gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung
            Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte
            (Auskunfts- und Interventionsrechte), wobei f&uuml;r die jeweiligen
            Aus&uuml;bungsvoraussetzungen auf die angef&uuml;hrte
            Rechtsgrundlage verwiesen wird:
          </p>

          <ul>
            <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO;</li>
            <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO;</li>
            <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO;</li>
            <li>
              Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig;
              Art. 18 DSGVO;
            </li>
            <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO;</li>
            <li>
              Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20
              DSGVO;
            </li>
            <li>
              Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art.
              7 Abs. 3 DSGVO;
            </li>
            <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO.</li>
          </ul>

          <p>
            <b>11.2</b>&nbsp;WIDERSPRUCHSRECHT
          </p>

          <p>
            WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE
            PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN
            BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE
            RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
            ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R
            DIE ZUKUNFT EINZULEGEN.
          </p>

          <p>
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
            ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
            F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE
            INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER
            WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER
            VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.
          </p>

          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
            DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN.
            SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.
          </p>

          <p>
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
          </p>

          <h2>12) Dauer der Speicherung personenbezogener Daten</h2>

          <p>
            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
            anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
            &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der
            jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und
            steuerrechtliche Aufbewahrungsfristen).
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
            ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit.
            a DSGVO werden die betroffenen Daten so lange gespeichert, bis Sie
            Ihre Einwilligung widerrufen.
          </p>

          <p>
            Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im
            Rahmen rechtsgesch&auml;ftlicher bzw.
            rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage
            von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese
            Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig
            gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
            Vertragsanbahnung erforderlich sind und/oder unsererseits kein
            berechtigtes Interesse an der Weiterspeicherung fortbesteht.
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
            bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO
            aus&uuml;ben, es sei denn, wir k&ouml;nnen zwingende
            schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
            die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die
            Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder
            Verteidigung von Rechtsanspr&uuml;chen.
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
            Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
            diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach
            Art. 21 Abs. 2 DSGVO aus&uuml;ben.
          </p>

          <p>
            Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung
            &uuml;ber spezifische Verarbeitungssituationen nichts anderes
            ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen
            dann gel&ouml;scht, wenn sie f&uuml;
          </p>
        </Paragraph>
      </div>
    </>
  );
};

export default DataProtection;
