/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./TabBarSteps.css";
import { t } from "../../languages/settingsLanguages";
import Button from "../button/Button";
// ---------------------------------------------------------

const TabBarSteps = ({
  arr = [{ completed: true }],
  currentFormStep = 1,
  onclick = () => {},
}) => {
  let element = document.querySelector("#tbs_container");
  element !== null &&
    element.addEventListener("wheel", (event) => {
      event.preventDefault();

      element.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    });

  const onClickBtn = (step) => {
    onclick(step);
    return (document.documentElement.scrollTop = 0);
  };

  return (
    <>
      {/* Class names starting with "tbs" refer to the file: TabBarSteps.css */}
      <section id="tbs_container" className="tbs_container">
        <div style={{ display: "flex", margin: "auto" }}>
          {arr.map((item, index) => {
            let step = index + 1;
            let current = currentFormStep === step;

            if (
              (step === 1 ||
                arr[index - 1].completed ||
                arr[index !== 0 && index !== arr.length - 1 ? index + 1 : index]
                  .completed ||
                arr[index].completed) &&
              !current
            ) {
              return (
                <button
                  key={`step_${step}`}
                  className={`tbs_step_container tbs_step_btn ${
                    item.completed && "tbs_step_completed"
                  }`}
                  onClick={() => onClickBtn(step)}
                >
                  <p>
                    {t("tab-bar-steps-step")} <b>{step}</b>
                  </p>
                </button>
              );
            } else {
              return (
                <div
                  key={`step_${step}`}
                  className={`tbs_step_container ${
                    current
                      ? `tbs_btn_active ${
                          item.completed && "tbs_step_completed"
                        }`
                      : "tbs_btn_disabled"
                  }`}
                >
                  <p>
                    {t("tab-bar-steps-step")} <b>{step}</b>
                  </p>

                  {current && (
                    <p className="tbs_btn_current">
                      {t("tab-bar-steps-current-step")}
                    </p>
                  )}
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};

export default TabBarSteps;

export const Step = ({
  numStep = 1,
  arr = [{ completed: true }],
  title = "",
  children = <></>,
  onClickBack = (currentStep) => {},
  onClickNext = (currentStep) => {},
  onFinish = () => {},
  lastStep = false,
  custonNameBtnBack = "",
  custonNameBtnNext = "",
}) => {
  const handleClickBack = (e, currentStep) => {
    e.preventDefault();

    onClickBack(currentStep);

    return (document.documentElement.scrollTop = 0);
  };

  const handleClickNext = (e, currentStep, disabled = false) => {
    e.preventDefault();

    if (lastStep) {
      const index = arr.findIndex((step) => !step.completed);

      if (index !== -1) {
        onClickNext(index);
      } else {
        onFinish();
      }
    } else {
      !disabled && onClickNext(currentStep);
    }

    return (document.documentElement.scrollTop = 0);
  };

  return (
    <div className="tbs_componentStep_container">
      <section className="tbs_componentStep_header">
        <p className="tbs_componentStep_numStep">
          {t("tab-bar-steps-step")} <b>{numStep}</b>
        </p>
        <div className="tbs_componentStep_spaceSeparator" />

        {title.length !== 0 && <h2>{title}</h2>}
      </section>

      <section className="tbs_componentStep_children_container">
        {children}
      </section>

      <div className="tbs_componentStep_btns_container">
        {numStep !== 1 && (
          <Button
            otherClassNameBtn="tbs_step_container"
            title={
              custonNameBtnBack === ""
                ? t("form-btn-back-step")
                : custonNameBtnBack
            }
            onclick={(e) => handleClickBack(e, numStep)}
          />
        )}
        {arr.length > 1 && (
          <>
            {arr.map((item, index) => {
              let step = index + 1;

              if (numStep === step) {
                if (item.completed) {
                  return (
                    <Button
                      otherClassNameBtn="tbs_step_container tbs_step_btn tbs_step_btn_next"
                      key={`btn_change_step_${step}`}
                      title={
                        custonNameBtnNext === ""
                          ? t("form-btn-next-step")
                          : custonNameBtnNext
                      }
                      onclick={(e) => handleClickNext(e, numStep)}
                    />
                  );
                } else {
                  return (
                    <Button
                      otherClassNameBtn="tbs_step_container tbs_btn_disabled"
                      key={`btn_change_step_${step}`}
                      title={
                        custonNameBtnNext === ""
                          ? t("form-btn-next-step")
                          : custonNameBtnNext
                      }
                      onclick={(e) => handleClickNext(e, numStep, true)}
                    />
                  );
                }
              } else
                return (
                  <div
                    key={`btn_change_step_${step}`}
                    style={{ display: "none" }}
                  />
                );
            })}
          </>
        )}
      </div>
    </div>
  );
};
