/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import SplineArea from "../spline/Spline";
import "./Banner.css";
// ---------------------------------------------------------

const Banner = ({
  otherClassNameContainer = "",
  width = "100%",
  height = "35rem",
  img,
  spline3d = false,
  splineScene = "",
  alt = "",
  objectFit = "cover",
  objectPosition = "center",
  title = "",
  subtitle = "" || <></>,
  button = <></>,
  button2 = <></>,
  colorHEXDarkLayer = "0d0d0d",
  opacityDarkLayer = 99,
}) => {
  if (
    typeof opacityDarkLayer !== "number" &&
    typeof opacityDarkLayer !== "string"
  ) {
    throw new Error('The "opacityDarkLayer" parameter must be a number type');
  } else if (opacityDarkLayer !== 0 && `${opacityDarkLayer}`.length !== 2) {
    throw new Error('"opacityDarkLayer" Must have two digits');
  } else if (colorHEXDarkLayer.length !== 6)
    throw new Error('"colorHEXDarkLayer" Must have six digits');

  return (
    <>
      {/* Class names starting with "bnn" refer to the file: Banner.css */}
      <section
        className={`bnn_container ${otherClassNameContainer}`}
        style={{ width, height }}
      >
        <div className="bnn_img_container">
          <div
            className="bnn_img_darkLayer"
            style={{
              backgroundColor: `#${colorHEXDarkLayer}${opacityDarkLayer}`,
            }}
          />

          {spline3d ? (
            <>
              <SplineArea scene={splineScene} />
            </>
          ) : (
            <>
              <img
                src={img}
                alt={alt}
                className="bnn_img_img"
                style={{ objectFit, objectPosition }}
              />
            </>
          )}
        </div>

        <div className="bnn_texts_container">
          <div className="bnn_title_container">
            <h2>{title}</h2>
          </div>

          <div className="bnn_subtitle_container">
            {typeof subtitle === "string" ? <p>{subtitle}</p> : <>{subtitle}</>}
          </div>

          <div className="bnn_btns_container">
            {button2} <div style={{ marginLeft: "0.5rem" }}>{button}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
