/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_UPDATE_APPLICANT,
  PATH_UPDATE_IMAGE_APPLICANT,
  TOKEN_DB,
} from "../../api/apis";
import { userApplicant } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesFormApplicant = async (
  data,
  id,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;
  let _data = {
    name: data.applicant_name,
    lastname: data.applicant_lastname,
    city: data.applicant_residence_city,
    zip: data.applicant_postal_code,
    tel: data.applicant_phone_number,
    birthdate: data.applicant_birthdate,
    gender: data.applicant_gender,
    terms: data.applicant_terms,
    job: data.applicant_what_job,
    apply: data.applicant_apply,
    adjetives: JSON.stringify(data.applicant_adjetives),
    timeexp: data.applicant_time_exp,
    travel: data.applicant_travel,
    selfdescription: data.applicant_selfdescription,
    lngpref: data.applicant_lng_pref,
    license: data.applicant_driving_license,
    salary: Number(data.applicant_salary),
    additional_services: JSON.stringify(data.applicant_additional_services),
    teamwork: data.applicant_teamwork,
    pressure: data.applicant_stress_and_pressure,
    working: data.applicant_I_enjoy_working,
    skills: data.applicant_I_improve_my_skills,
    adaptability: data.applicant_adaptability,
    objectively: data.applicant_conflicts_objectively,
    learning: data.applicant_I_enjoy_learning,
    responsibility: data.applicant_responsibility,
    extrahours: data.applicant_extra_hours,
    satisfied: data.applicant_satisfied_in_job,
    comment: data.applicant_short_comment,
  };

  await fetch(`${API}${PATH_UPDATE_APPLICANT}/${id}`, {
    method: "PUT",
    body: JSON.stringify(_data),
    headers: {
      "Content-Type": "application/json",
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${data.token}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.response === "successful") {
        response("successful");
        loading(false);
      }
    })
    .catch((error) => {
      // console.error(error);
      response("failed");
      loading(false);
    });
};

export default servicesFormApplicant;

export const servicesUpdateImageProfile = async (
  profile,
  id,
  token,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;

  const formData = new FormData();

  formData.append("perfil", profile);
  formData.append("applicant_id", id);

  await fetch(`${API}${PATH_UPDATE_IMAGE_APPLICANT}`, {
    method: "POST",
    body: formData,
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.response === "successful") {
        let user = userApplicant.get();
        let updateUser = {
          ...user,
          perfil: res.newLinkProfile,
        };
        localStorage.setItem("userapplicant", JSON.stringify(updateUser));
        response(res);
      }
      loading(false);
    })
    .catch((error) => {
      // console.error(error);
      response("failed");
      loading(false);
    });
};
