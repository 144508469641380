/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./WorkScreen.css";
import Header from "../../components/header/Header";
import Work from "../../components/works/Work";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// ---------------------------------------------------------

const WorkScreen = ({ docTitle = "" }) => {
  const [work, setWork] = useState({
    company: "",
    vacancy: "",
    working: "",
    qualification: "",
    zip: "",
    city: "",
    benefit: [],
    phone: "",
    mail: "",
    selfDescriptionCompany: "",
    requirements: "",
    workDescription: "",
    workPlaces: "",
    workSchedules: "",
    salaryRange: "",
    careerAndDevelopmentOpportunities: "",
  });

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const useQuery = (item) => query.get(item);
  let company = useQuery("company"),
    vacancy = useQuery("vacancy"),
    working = useQuery("working"),
    qualification = useQuery("qualification"),
    zip = useQuery("zip"),
    city = useQuery("city"),
    benefit = useQuery("benefit"),
    phone = useQuery("phone"),
    mail = useQuery("mail"),
    selfDescriptionCompany = useQuery("selfDescriptionCompany"),
    requirements = useQuery("requirements"),
    workDescription = useQuery("workDescription"),
    workPlaces = useQuery("workPlaces"),
    workSchedules = useQuery("workSchedules"),
    salaryRange = useQuery("salaryRange"),
    careerAndDevelopmentOpportunities = useQuery(
      "careerAndDevelopmentOpportunities"
    );

  useEffect(() => {
    setWork({
      ...work,
      company,
      vacancy,
      working,
      qualification,
      zip,
      city,
      benefit,
      phone,
      mail,
      selfDescriptionCompany,
      requirements,
      workDescription,
      workPlaces,
      workSchedules,
      salaryRange,
      careerAndDevelopmentOpportunities,
    });
  }, []);

  return (
    <>
      {/* Class names starting with "wrs" refer to the file: WorkScreen.css */}
      <Helmet>
        <title>
          {docTitle} | {work.vacancy}
        </title>
      </Helmet>

      <Header />

      <div className="wrs_container">
        <Work work={work} isScreen />
      </div>
    </>
  );
};

export default WorkScreen;
