/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./About.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Banner from "../../components/banner/Banner";
import Paragraph from "../../components/paragraph/Paragraph";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const About = ({ docTitle }) => {
  const AboutUs = () => {
    return (
      <div className="abt_container">
        <Paragraph otherClassNameParagraph="abt_paragraph">
          Wir sind ein dynamisches Team mit umfassender Berufserfahrung und
          einer klaren Mission: die Mitarbeitersuche zu revolutionieren. Unsere
          Plattform bietet Unternehmen eine effiziente und kostengünstige
          Alternative zu traditionellen Headhuntern. Bei uns finden Sie einfach
          und gezielt Ihren idealen Kandidaten - mit Zugriff auf wesentliche
          Informationen wie Berufserfahrung und spezielle Qualifikationen. Ein
          Blick in die Profile genügt, und Sie wissen, wer in Ihr Team passt.
        </Paragraph>

        <Paragraph otherClassNameParagraph="abt_paragraph">
          {/* {t("about-us-p-3")} */}
          Treten Sie CompanyU bei - wo die Zukunft der Arbeit bereits begonnen
          hat.
        </Paragraph>
      </div>
    );
  };

  return (
    <>
      {/* Class names starting with "abt" refer to the file: About.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-about")}
        </title>
      </Helmet>

      <Header />

      <Banner
        width="100%"
        height="80rem"
        img={assets.imgs.applicantsBgCity}
        alt="Bg about us"
        title={t("about-us-title")}
        subtitle={<AboutUs />}
        opacityDarkLayer={"d9"}
      />
    </>
  );
};

export default About;
