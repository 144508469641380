/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./FormForCompany.css";
import { WebContext } from "../../context/Context";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Button from "../../components/button/Button";
import TabBarSteps, { Step } from "../../components/tabBarSteps/TabBarSteps";
import servicesFormCompany from "./servicesFormCompany";
import Input, {
  ChildrenLabelDefault,
  Selector,
  TextArea,
} from "../../components/input/Input";
import { userCompany } from "../../utils/Helpers";
import {
  experienceOptions,
  okOptions,
  option_choose,
} from "../../utils/options";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const FormForCompany = ({ docTitle }) => {
  const { dispatch } = WebContext();

  const [currentFormStepCompany, setcurrentFormStepCompany] = useState(1);
  const [arrCompletedCompany, setArrCompletedCompany] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
  ]);
  const [formCompany, setformCompany] = useState({
    // ------------- Step 1 -------------
    company_mail: "",
    company_password: "",
    company_repeat_password: "",
    // ------------- Step 2 -------------
    company_name: "",
    company_description: "",
    company_link_website: "",
    company_postal_code: "",
    // ------------- Step 3 -------------
    company_contact_name: "",
    company_contact_mail: "",
    company_contact_phone: "",
    // ------------- Step 4 -------------
    company_what_job: "",
    company_teamwork: "",
    company_pressure: "",
    company_working: "",
    company_skills: "",
    company_adaptability: "",
    // ------------- Step 5 -------------
    company_objectively: "",
    company_learning: "",
    company_responsibility: "",
    company_extrahours: "",
    company_satisfied: "",
    // company_availability: "",
  });

  const [errorFree, setErrorFree] = useState({
    ...formCompany,
    company_link_website: true,
  });

  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);
  const navigate = useNavigate();
  const { token } = userCompany.get();

  useEffect(() => {
    token !== "" && navigate("/home-company");
  }, [token, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isResultTabVisible !== "successful") {
        // The standard message that will be displayed may be different   depending on the browser.
        let mensaje = "Are you sure you want to go out?";

        // Set the return message for the event.
        e.returnValue = mensaje; // Standard for some browsers.

        return mensaje; // Some browsers may require the message to be returned.
      }
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isResultTabVisible]);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // ---------------- Regular expresions ----------------

  const handleChangeStep = (step) => {
    setcurrentFormStepCompany(step);
  };

  const handleChangeNextStep = (step) => {
    let next = step + 1;

    handleChangeStep(next);
  };

  const handleChangeBackStep = (step) => {
    let back = step - 1;

    handleChangeStep(back);
  };

  const handleChangeInput = (target, isOK) => {
    setformCompany({
      ...formCompany,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleResponse = (res) => {
    if (res === "successful" || res.response === "successful") {
      navigate("/home-company");
    } else if (res === "failed-login" || res.response === "failed-login") {
      navigate("/login-company");
    }
    return setIsResultTabVisible(res);
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  // --------------------- Validating all steps ---------------------
  const handleChangeCompleted = (step, state = false) => {
    let indexStep = step - 1;
    let copyArrCompletedCompany = arrCompletedCompany;

    copyArrCompletedCompany[indexStep] = { completed: state };

    return setArrCompletedCompany(copyArrCompletedCompany);
  };

  // ------------ Validation step 1 ------------
  if (currentFormStepCompany === 1) {
    if (!arrCompletedCompany[0].completed) {
      // company_mail
      // company_password
      // company_repeat_password
      if (
        errorFree.company_mail &&
        errorFree.company_password &&
        errorFree.company_repeat_password
      )
        handleChangeCompleted(1, true);
    } else if (arrCompletedCompany[0].completed) {
      if (
        !errorFree.company_mail ||
        !errorFree.company_password ||
        !errorFree.company_repeat_password
      )
        handleChangeCompleted(1, false);
    }
    // ------------ /Validation step 1 ------------

    // ------------ Validation step 2 ------------
  } else if (currentFormStepCompany === 2) {
    if (!arrCompletedCompany[1].completed) {
      // company_name
      // company_description
      // company_link_website
      if (
        errorFree.company_name &&
        errorFree.company_description &&
        errorFree.company_postal_code
      ) {
        handleChangeCompleted(2, true);
      }
    } else if (arrCompletedCompany[1].completed) {
      if (
        !errorFree.company_name ||
        !errorFree.company_description ||
        !errorFree.company_postal_code
      ) {
        handleChangeCompleted(2, false);
      }
    }
    // ------------ /Validation step 2 ------------

    // ------------ Validation step 3 ------------
  } else if (currentFormStepCompany === 3) {
    if (!arrCompletedCompany[2].completed) {
      // company_contact_name
      // company_contact_mail
      // company_contact_phone
      if (
        errorFree.company_contact_name &&
        errorFree.company_contact_mail &&
        errorFree.company_contact_phone
      ) {
        handleChangeCompleted(3, true);
      }
    } else if (arrCompletedCompany[2].completed) {
      if (
        !errorFree.company_contact_name ||
        !errorFree.company_contact_mail ||
        !errorFree.company_contact_phone
      ) {
        handleChangeCompleted(3, false);
      }
    }
    // ------------ /Validation step 3 ------------

    // ------------ Validation step 4 ------------
  } else if (currentFormStepCompany === 4) {
    if (!arrCompletedCompany[3].completed) {
      // company_teamwork
      // company_pressure
      // company_working
      // company_skills
      // company_adaptability
      if (
        errorFree.company_teamwork &&
        errorFree.company_pressure &&
        errorFree.company_working &&
        errorFree.company_skills &&
        errorFree.company_adaptability
      ) {
        handleChangeCompleted(4, true);
      }
    } else if (arrCompletedCompany[3].completed) {
      if (
        !errorFree.company_teamwork ||
        !errorFree.company_pressure ||
        !errorFree.company_working ||
        !errorFree.company_skills ||
        !errorFree.company_adaptability
      ) {
        handleChangeCompleted(4, false);
      }
    }
    // ------------ /Validation step 4 ------------

    // ------------ Validation step 5 ------------
  } else if (currentFormStepCompany === 5) {
    if (!arrCompletedCompany[4].completed) {
      // company_objectively
      // company_learning
      // company_responsibility
      // company_extrahours
      // company_satisfied
      if (
        errorFree.company_objectively &&
        errorFree.company_learning &&
        errorFree.company_responsibility &&
        errorFree.company_extrahours &&
        errorFree.company_satisfied
      ) {
        handleChangeCompleted(5, true);
      }
    } else if (arrCompletedCompany[4].completed) {
      if (
        !errorFree.company_objectively ||
        !errorFree.company_learning ||
        !errorFree.company_responsibility ||
        !errorFree.company_extrahours ||
        !errorFree.company_satisfied
      ) {
        handleChangeCompleted(5, false);
      }
    }
    // ------------ /Validation step 5 ------------
    // --------------------- /Validating all steps ---------------------
  }

  return (
    <>
      {/* Class names starting with "ffc" refer to the file: FormForCompany.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-form-company")}
        </title>
      </Helmet>

      <Header />

      <div className="ffc_main_container">
        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={assets.icons.iconSending}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={assets.imgs.successful}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={assets.imgs.failed}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible !== "successful" && (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/register-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <TabBarSteps
          currentFormStep={currentFormStepCompany}
          arr={arrCompletedCompany}
          onclick={(step) => handleChangeStep(step)}
        />

        <form id="register-company" className="ffc_form_container">
          {currentFormStepCompany === 1 && (
            <Step
              numStep={1}
              currentFormStepCompany={currentFormStepCompany}
              arr={arrCompletedCompany}
              title={t("form-company-step-1-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Email -------------------- */}
              <Input
                id="company_mail"
                nameInput="company_mail"
                // placeholder={t("form-company-step-1-register-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={formCompany.company_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(formCompany.company_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-register-mail")}
                  />
                }
                errorMessage={
                  formCompany.company_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(formCompany.company_mail)
                    ? t("form-company-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Email -------------------- */}

              {/* -------------------- Password -------------------- */}
              <Input
                id="company_password"
                nameInput="company_password"
                // placeholder={t("form-company-step-1-password")}
                placeholder="******"
                defaultValue={formCompany.company_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value.length < 6;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-password")}
                  />
                }
                errorMessage={
                  formCompany.company_password.length === 0
                    ? t("form-required")
                    : formCompany.company_password.length < 6
                    ? t("form-company-step-1-please-valid-password")
                    : ""
                }
              />
              {/* -------------------- /Password -------------------- */}

              {/* -------------------- Repeat password -------------------- */}
              <Input
                id="company_repeat_password"
                nameInput="company_repeat_password"
                // placeholder={t("form-company-step-1-repeat-password")}
                placeholder="******"
                defaultValue={formCompany.company_repeat_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value !== formCompany.company_password;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-repeat-password")}
                  />
                }
                errorMessage={
                  formCompany.company_repeat_password.length === 0
                    ? t("form-required")
                    : formCompany.company_repeat_password !==
                      formCompany.company_password
                    ? t("form-company-step-1-please-valid-repeat-password")
                    : ""
                }
              />
              {/* -------------------- /Repeat password -------------------- */}
            </Step>
          )}

          {currentFormStepCompany === 2 && (
            <Step
              numStep={2}
              currentFormStepCompany={currentFormStepCompany}
              arr={arrCompletedCompany}
              title={t("form-company-step-2-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* ------------------ Company Name ------------------ */}
              <Input
                id="company_name"
                nameInput="company_name"
                // placeholder={t("form-company-step-2-compname")}
                placeholder="Company Name"
                defaultValue={formCompany.company_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-2-compname")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Company Name ----------------------- */}

              {/* ----------------- Company Description ----------------- */}
              <TextArea
                id="company_description"
                label={t("form-company-step-2-description")}
                placeholder={t("form-company-step-2-description")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={formCompany.company_description}
                required
                error={errorFree.company_description === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Company Description ----------------- */}

              {/* -------------- Company Link Website -------------- */}
              <Input
                id="company_link_website"
                nameInput="company_link_website"
                type="url"
                placeholder="https://yourwebsite.com"
                defaultValue={formCompany.company_link_website}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    txt={t("form-company-step-2-link-website")}
                  />
                }
              />
              {/* -------------- /Company Link Website ------------------- */}

              {/* -------------------- Postal code -------------------- */}
              <Input
                id="company_postal_code"
                nameInput="company_postal_code"
                type="number"
                // placeholder={t("form-applicant-step-1-postal-code")}
                placeholder="000000"
                defaultValue={formCompany.company_postal_code}
                minLength={4}
                maxLength={13}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-postal-code")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Postal code -------------------- */}
            </Step>
          )}

          {currentFormStepCompany === 3 && (
            <Step
              numStep={3}
              currentFormStepCompany={currentFormStepCompany}
              arr={arrCompletedCompany}
              title={t("form-company-step-3-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* ------------------ Contact Name ------------------ */}
              <Input
                id="company_contact_name"
                nameInput="company_contact_name"
                placeholder={t("form-company-step-3-contact-name")}
                defaultValue={formCompany.company_contact_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-name")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Contact Name ----------------------- */}

              {/* -------------------- Contact Email -------------------- */}
              <Input
                id="company_contact_mail"
                nameInput="company_contact_mail"
                // placeholder={t("form-company-step-1-register-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={formCompany.company_contact_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(formCompany.company_contact_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-mail")}
                  />
                }
                errorMessage={
                  formCompany.company_contact_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(formCompany.company_contact_mail)
                    ? t("form-company-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Contact Email -------------------- */}

              {/* ---------------- Contact Phone number ---------------- */}
              <Input
                id="company_contact_phone"
                nameInput="company_contact_phone"
                // placeholder={t("form-applicant-step-1-phone-number")}
                placeholder="04900000000"
                defaultValue={formCompany.company_contact_phone}
                type="tel"
                required
                handleBlurContainsError={(e) => {
                  let validNumberPhone =
                    e.target.value.length < 11 || e.target.value.length > 13;

                  return validNumberPhone;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-phone")}
                  />
                }
                errorMessage={
                  formCompany.company_contact_phone.length === 0
                    ? t("form-required")
                    : formCompany.company_contact_phone.length < 11 ||
                      formCompany.company_contact_phone.length > 13
                    ? t("form-applicant-step-1-please-valid-number")
                    : ""
                }
              />
              {/* ---------------- /Contact Phone number ---------------- */}
            </Step>
          )}

          {currentFormStepCompany === 4 && (
            <Step
              numStep={4}
              currentFormStep={currentFormStepCompany}
              arr={arrCompletedCompany}
              title={t("form-applicant-step-4-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- What job -------------------- */}
              <Selector
                id="company_what_job"
                label={t("Welche Stelle möchten Sie besetzen?")}
                arr={experienceOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_what_job",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_what_job}
                required
                error={errorFree.company_what_job === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /What job -------------------- */}

              {/* -------------------- Teamwork -------------------- */}
              <Selector
                id="company_teamwork"
                label={t(
                  "Wie wichtig ist es Ihrem Unternehmen, dass Ihre Mitarbeiter, gerne in Teams arbeiten möchten und auch wollen."
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_teamwork",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_teamwork}
                required
                error={errorFree.company_teamwork === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Teamwork -------------------- */}

              {/* ------------------ Stress and pressure ------------------ */}
              <Selector
                id="company_pressure"
                label={t(
                  "Wie gut sollten Ihre Mitarbeiter mit Stress und Zeitdruck umgehen können?"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_pressure",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_pressure}
                required
                error={errorFree.company_pressure === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Stress and pressure ------------------ */}

              {/* ------------------ I enjoy working ------------------ */}
              <Selector
                id="company_working"
                label={t(
                  "Wie wichtig, bzw. Ausschlaggebend, sind  Kommunikationsfähigkeiten mit Kunden und Kollegen in Ihrem Unternehmen?"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_working",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_working}
                required
                error={errorFree.company_working === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy working ------------------ */}

              {/* ------------------ I improve my skills ------------------ */}
              <Selector
                id="company_skills"
                label={t(
                  "Sollten eure Mitarbeiter, kontinuierlich an Ihren handwerklichen Fähigkeiten arbeiten wollen?"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_skills",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_skills}
                required
                error={errorFree.company_skills === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I improve my skills ------------------ */}

              {/* ------------------ Adaptability ------------------ */}
              <Selector
                id="company_adaptability"
                label={t(
                  "Wie wichtig ist Ihnen Flexibilität und Anpassungsfähigkeit an unterschiedliche Arbeitsumgebungen in Ihrem Unternehmen?"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_adaptability",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_adaptability}
                required
                error={errorFree.company_adaptability === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Adaptability ------------------ */}
            </Step>
          )}

          {currentFormStepCompany === 5 && (
            <Step
              numStep={5}
              currentFormStep={currentFormStepCompany}
              arr={arrCompletedCompany}
              title={t("form-applicant-step-5-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
              lastStep
              onFinish={() =>
                servicesFormCompany(
                  dispatch,
                  formCompany,
                  (res) => handleResponse(res),
                  (loading) => handleLoadingFinish(loading)
                )
              }
              custonNameBtnNext={t("form-btn-register")}
            >
              {/* ----------------- Conflicts Objectively ----------------- */}
              <Selector
                id="company_objectively"
                label={t(
                  "Wie würden Sie Ihre Fähigkeit einschätzen, mit Konflikten und Meinungsverschiedenheiten am Arbeitsplatz umzugehen."
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_objectively",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_objectively}
                required
                error={errorFree.company_objectively === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Conflicts Objectively ----------------- */}

              {/* ------------------ I enjoy learning ------------------ */}
              <Selector
                id="company_learning"
                label={t(
                  "Wie wichtig ist es Ihnen, dass Ihr neuer Mitarbeiter, sich gut und schnell, an neue handwerkliche Techniken und Werkzeuge gewöhnt."
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_learning",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_learning}
                required
                error={errorFree.company_learning === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy learning ------------------ */}

              {/* ------------------ Responsibility ------------------ */}
              <Selector
                id="company_responsibility"
                label={t(
                  "Legt Ihr Unternehmen wert, auf viel eigen Verantwortung, bei den Arbeitsanweisungen und Projekten, Ihrer Mitarbeiter"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_responsibility",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_responsibility}
                required
                error={errorFree.company_responsibility === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Responsibility ------------------ */}

              {/* ------------------ Extra hours ------------------ */}
              <Selector
                id="company_extrahours"
                label={t(
                  "Wie Ausschlaggebend ist es für Sie, dass Ihre Mitarbeiter, bei Bedarf Überstunden leisten oder flexibel auf wechselnde Arbeitsbedingungen reagieren."
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_extrahours",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_extrahours}
                required
                error={errorFree.company_extrahours === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Extra hours ------------------ */}

              {/* ------------------ Satisfied in job ------------------ */}
              <Selector
                id="company_satisfied"
                label={t(
                  "Wie zufrieden sind Sie mit Ihrer aktuellen Angestellten Situation?"
                )}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "company_satisfied",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={formCompany.company_satisfied}
                required
                error={errorFree.company_satisfied === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Satisfied in job ------------------ */}
            </Step>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForCompany;
