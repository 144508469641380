/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_OFFERS, TOKEN_DB } from "../../api/apis";
import { setDataArrAllWorks } from "../../context/Actions";
// ---------------------------------------------------------

export const getAllWorks = async (dispatch) => {
  let arrWorks = [];

  const API = API_BASE;

  arrWorks = await fetch(`${API}${PATH_OFFERS}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error?.message);

      return [];
    });

  return setDataArrAllWorks(dispatch, arrWorks ?? []);
};
