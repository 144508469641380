/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./ForApplicants.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import BannerWithChildren from "../../components/bannerWithChildren/BannerWithChildren";
import Paragraph from "../../components/paragraph/Paragraph";
import Button from "../../components/button/Button";
import ScrollTopAnimation from "../../components/scrollTopAnimation/ScrollTopAnimation";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const ForApplicants = ({ docTitle }) => {
  const MiniIcon = ({
    type = "adv" /* "adv" -> Advantage | dadv -> Disadvantage */,
  }) => {
    return (
      <small className="fap_miniicon_container">
        {type === "adv" ? (
          <img className="fap_miniicon" src={assets.icons.iconAdv} alt="X" />
        ) : (
          <img className="fap_miniicon" src={assets.icons.iconDadv} alt="✓" />
        )}
      </small>
    );
  };

  return (
    <>
      {/* Class names starting with "fap" refer to the file: ForApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-applicants")}
        </title>
      </Helmet>

      <Header />

      <div className="fap_main_container">
        <div className="fap_bg_img_container">
          <img
            src={assets.imgs.applicantsBgCity}
            alt="Bg city"
            className="fap_bg_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fap_banner_container"
          img={assets.imgs.applicantworking}
          alt="Work: Imagen de pvproductions en Freepik"
          opacityDarkLayer={40}
        >
          <div className="fap_child_container">
            <Paragraph
              withTitle
              title={"Fähigkeit und Erfolg verbinden"}
              otherClassNameTitle="fap_child_title"
              otherClassNameParagraph="fap_child_p fap_child_p_under_title"
            >
              {t(
                "Auf dem heutigen Arbeitsmarkt sind Sie als Fachkraft mehr gefragt denn je. Unternehmen sind auf der Suche nach Experten wie Ihnen und locken mit verlockenden Angeboten. Ergreifen Sie diese Chance und machen Sie den nächsten großen Schritt in Ihrer Karriere. Bereiten Sie sich vor auf spannende Herausforderungen und hochwertige Jobangebote!"
              )}
            </Paragraph>

            <Button
              type="link"
              link="/register-applicant"
              title={t("name-link-to-register")}
            />
          </div>
        </BannerWithChildren>
      </div>

      <div className="fap_info_container">
        {/* <ScrollTopAnimation>
          <Paragraph>{t("applicants-we-understand-you")}</Paragraph>
        </ScrollTopAnimation>

        <ScrollTopAnimation>
          <Paragraph
            withTitle
            title={t("applicants-is-that-how-it-works-title")}
          >
            <p>{t("applicants-is-that-how-it-works-p")}</p>

            <p>{t("applicants-is-that-how-it-works-p2")}</p>
          </Paragraph>
        </ScrollTopAnimation>

        <ScrollTopAnimation>
          <ContainerWithColumns>
            <div>
              <Paragraph withTitle title={t("applicants-most-notably-title")}>
                {t("applicants-most-notably-p")}
              </Paragraph>
            </div>

            <div>
              <Paragraph withTitle title={t("applicants-most-the-best-title")}>
                {t("applicants-most-the-best-p")}
              </Paragraph>
            </div>
          </ContainerWithColumns>
        </ScrollTopAnimation>

        <ScrollTopAnimation>
          <Paragraph>
            <p>{t("applicants-get-ready")}</p>

            <p>{t("applicants-we-want-to-help")}</p>
          </Paragraph>
        </ScrollTopAnimation>

        <ScrollTopAnimation>
          <Paragraph withTitle title={t("applicants-how-do-we-do-this-title")}>
            <p>{t("applicants-how-do-we-do-this-p1")}</p>

            <p>{t("applicants-how-do-we-do-this-p2")}</p>

            <p>{t("applicants-how-do-we-do-this-p3")}</p>
          </Paragraph>
        </ScrollTopAnimation> */}

        {/* New interfece */}

        <section className="fap_aboutwe_container">
          <div className="fap_aboutwe">
            <Paragraph withTitle title="Karriere ohne Company U">
              <p className="fap_aboutwe_p">
                <MiniIcon type="dadv" /> Hunderte Bewerbungsgespräche - mehr
                Zeitaufwand bei der Suche nach dem passenden Job.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon type="dadv" /> Schlechtere Konditionen
                (Zusatzleistungen).
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon type="dadv" /> Zu wenig Anerkennung als Handwerker.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon type="dadv" /> Weniger Weiterbildungsmöglichkeiten.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon type="dadv" /> Verpasst die besten Job angeboten.
              </p>
            </Paragraph>
          </div>

          <div className="fap_aboutwe">
            <Paragraph withTitle title="Karriere mit Company U">
              <p className="fap_aboutwe_p">
                <MiniIcon />
                Keine hunderten Bewerbungsgespräche mehr.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon />
                Zeitersparnis.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon />
                Zugang zu den besten Bezahlungen, Konditionen und
                Zusatzleistungen.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon />
                Die besten Job-Angebote erhalten mit nur 2 Minuten Aufwand.
              </p>

              <p className="fap_aboutwe_p">
                <MiniIcon />
                Lukrative Freizeitaktivitäten rund ums Handwerk finden.
              </p>
            </Paragraph>
          </div>
        </section>

        <section className="fap_workers_container">
          <div className="fap_worker">
            <ScrollTopAnimation
              getClass="fap_toRight_animated"
              addClassInAnimation="fap_toRight_animated_on"
            >
              <div className="fap_worker_img_container">
                <img
                  className="fap_worker_img_img"
                  src={assets.imgs.worker1}
                  alt="Worker 1"
                />
              </div>
            </ScrollTopAnimation>

            <p>
              Registrieren Sie sich, geben Sie Ihren Traumjob und Ihr
              Wunschgehalt an und lassen Sie die Angebote zu Ihnen kommen. Ganz
              ohne Stress.
            </p>

            <p>Ja, es ist wirklich so einfach.</p>
          </div>

          <div className="fap_worker fap_worker_left">
            <ScrollTopAnimation
              getClass="fap_toLeft_animated"
              addClassInAnimation="fap_toLeft_animated_on"
            >
              <div className="fap_worker_img_container">
                <img
                  className="fap_worker_img_img"
                  src={assets.imgs.worker2}
                  alt="Worker 2"
                />
              </div>
            </ScrollTopAnimation>

            <p>
              Als Bewerber nutzen Sie unseren Service komplett kostenfrei! Keine
              versteckten Gebühren, keine Abonnements. Bei CompanyU stehen alle
              Bewerber auf gleicher Stufe.
            </p>
          </div>

          <div className="fap_worker">
            <ScrollTopAnimation
              getClass="fap_toRight_animated"
              addClassInAnimation="fap_toRight_animated_on"
            >
              <div className="fap_worker_img_container">
                <img
                  className="fap_worker_img_img"
                  src={assets.imgs.worker3}
                  alt="Worker 3"
                />
              </div>
            </ScrollTopAnimation>

            <p>
              Unternehmen treten direkt mit verlockende Jobanfragen an Sie heran
              - Schluss mit endlosem Telefonieren und mühsamen
              E-Mail-Korrespondenzen!
            </p>
          </div>

          <div className="fap_worker fap_worker_left">
            <ScrollTopAnimation
              getClass="fap_toLeft_animated"
              addClassInAnimation="fap_toLeft_animated_on"
            >
              <div className="fap_worker_img_container">
                <img
                  className="fap_worker_img_img"
                  src={assets.imgs.worker4}
                  alt="Worker 4"
                />
              </div>
            </ScrollTopAnimation>

            <p>
              Wir verknüpfen Sie direkt mit Unternehmen, die händeringend nach
              Fachkräften suchen. Deren Angebote werden immer lukrativer - ein
              Zeichen, dass Ihr Know-how hoch im Kurs steht.
            </p>

            <p>Nutzen Sie diese Dynamik zu Ihrem Vorteil.</p>
          </div>
        </section>

        <section className="fap_phrase_container">
          <p className="fap_phrase_container_txt">
            Sichern Sie sich jetzt die besten Job- und Freizeitangebote mit
            Company U, bevor es ein anderer tut!
          </p>

          <Button
            type="link"
            link="/register-applicant"
            title={t("name-link-to-register")}
          />
        </section>
      </div>

      <div className="fap_second_container">
        <div className="fap_bg_img_container">
          <img
            src={assets.imgs.applicantsBgCity2}
            alt="Bg city"
            className="fap_bg_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fap_second_banner_container"
          img={assets.imgs.applicanthappy}
          alt="Work: Imagen de gpointstudio en Freepik"
          opacityDarkLayer={70}
        >
          <div className="fap_child_container">
            <Paragraph
              withTitle
              title={t("applicants-banner-2-title")}
              otherClassNameParagraph="fap_child_p"
            >
              <p>
                <b>Schokierend</b>, aber wahr: <b>Vier</b> von <b>zehn</b>{" "}
                Arbeitskräften in Deutschland sind <b>unterbezahlt</b>.
              </p>

              <p>
                Unsere <b>Vision</b> ist klar: Eine Arbeitswelt prägen, in der
                jeder <b>Handwerker</b> das Beste aus <b>Gehalt</b>,
                <b>Arbeitsbedingungen</b> und <b>Atmosphäre</b> erhält.
              </p>

              <p>
                Und mit <b>Unterstützung</b> unseres <b>Partners</b>...
              </p>

              <p>
                Wir engagieren uns leidenschaftlich dafür, dass Ihr{" "}
                <b>Gehalt</b> Ihrer <b>Leistung</b> entspricht.
              </p>

              <p>
                Werfen Sie einen Blick in den <b>Entgeltatlas</b> und
                informieren Sie sich über gängige Gehaltsspannen in Ihrem
                Berufsfeld - so sind Sie bei <b>Gehaltsverhandlungen</b> immer
                einen Schritt voraus.
              </p>
            </Paragraph>

            <div className="fap_imgPatner_container">
              <img
                src={assets.imgs.ourPatner}
                alt="Our Patner"
                className="fap_imgPatner_img"
              />
            </div>

            <Paragraph otherClassNameParagraph="fap_child_p">
              <p>{t("applicants-banner-2-we-are-engaged")}</p>

              <p>{t("applicants-banner-2-salary-atlas")}</p>
            </Paragraph>

            <Button
              type="link"
              link="https://web.arbeitsagentur.de/entgeltatlas/"
              target="_blank"
              otherClassNameBtn="fap_banner_btn"
              title={t("applicants-banner-2-payment-atlas")}
            />
          </div>
        </BannerWithChildren>
      </div>
    </>
  );
};

export default ForApplicants;
