/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

export const setDefaultTest = async (dispatch, defaultTest) => {
  dispatch({ type: "DEFAULT_TEST", payload: defaultTest });
};

export const changeLanguage = (dispatch, lng) => {
  localStorage.setItem("lng_app", lng);
  dispatch({ type: "CHANGE_LANGUAGE", payload: lng });
  return;
};

export const setArrApplicantsPreview = (dispatch, arr) => {
  dispatch({ type: "SET_ARR_APPLICANTS_PREVIEW", payload: arr });
  dispatch({ type: "SET_LOADING_ARR_APPLICANTS_PREVIEW", payload: false });
  return;
};

export const setArrApplicantsList = (dispatch, arr) => {
  dispatch({ type: "SET_ARR_APPLICANTS_WITH_FILTERS", payload: arr });
  dispatch({ type: "SET_LOADING_ARR_APPLICANTS_WITH_FILTERS", payload: false });
  return;
};

export const setUsercompany = (dispatch, state) => {
  dispatch({ type: "USER_COMPANY", payload: state });
  return;
};

export const setUserSuperadmin = (dispatch, state) => {
  dispatch({ type: "USER_SUPERADMIN", payload: state });
  return;
};

export const setUserinfluencer = (dispatch, state) => {
  dispatch({ type: "USER_INFLUENCER", payload: state });
  return;
};

export const setUserapplicant = (dispatch, state) => {
  dispatch({ type: "USER_APPLICANT", payload: state });
  return;
};

export const saveYourPackage = (dispatch, data) => {
  dispatch({ type: "YOUR_PACKAGE", payload: data });
  return;
};

export const setSaveApplicantID = (dispatch, id) => {
  dispatch({ type: "SAVE_APPLICANT_ID", payload: id });
  return;
};

export const setLastVisitedRoute = (dispatch, lastVisitedRoute = "") => {
  dispatch({ type: "LAST_VISITED_ROUTE", payload: lastVisitedRoute });
  return;
};

export const setDataArrAllWorks = (dispatch, arrAllWorks = []) => {
  dispatch({ type: "ARR_ALL_WORKS", payload: arrAllWorks });
  return;
};
