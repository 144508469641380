/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { saveYourPackage, setLastVisitedRoute } from "../context/Actions";
// ---------------------------------------------------------

export const getCoordinatesFromPostalCode = async (postalCode) => {
  const apiKey = "TU_API_KEY"; // Sustituye esto con tu API Key de Google Maps
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${apiKey}`;

  const response = await fetch(apiUrl);
  const data = await response.json();

  if (data.status !== "OK" || !data.results.length) {
    throw new Error("No se pudo obtener las coordenadas del código postal.");
  }

  const location = data.results[0].geometry.location;
  return { lat: location.lat, lng: location.lng };
};

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export const getDistanceBetweenPostalCodes = async (
  postalCode1,
  postalCode2
) => {
  try {
    const coords1 = await getCoordinatesFromPostalCode(postalCode1);
    const coords2 = await getCoordinatesFromPostalCode(postalCode2);

    const distance = haversineDistance(
      coords1.lat,
      coords1.lng,
      coords2.lat,
      coords2.lng
    );
    console.log(
      `La distancia entre ${postalCode1} y ${postalCode2} es de ${distance.toFixed(
        2
      )} km.`
    );
  } catch (error) {
    console.error("Error al calcular la distancia:", error);
  }
};

// Ejemplo de uso:
// getDistanceBetweenPostalCodes('055450', '055457');

export const thousandsSeparator = (num) => {
  return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};

export const calculateAge = (birthDateString) => {
  let today = new Date();
  let birthDate = new Date(birthDateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  let monthDifference = today.getMonth() - birthDate.getMonth();

  // Si el mes actual es anterior al mes de nacimiento, restamos 1 al año
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const validateSessionTokenApplicant = () => {};

const getUserCompany = () => {
  let _usercompany = {
    id: "",
    name: "",
    description: "",
    link: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    offers: [],
    token: "",
  };

  let usercompany = JSON.parse(localStorage.getItem("usercompany"));
  let data =
    usercompany === "" || usercompany === null || usercompany === undefined
      ? _usercompany
      : usercompany;
  return data;
};

const clearUserCompany = () => {
  localStorage.removeItem("yourpackage");
  localStorage.removeItem("usercompany");
  return;
};

export const userCompany = {
  get: getUserCompany,
  clear: clearUserCompany,
};

export const validateActiveSessionTokenCompany = (
  response,
  expectedErrors = [],
  error = ""
) => {
  const successRes = [200, 201];
  let { status } = response;
  let state = successRes.findIndex((res) => res === status);

  if (state !== -1) {
    return response.json();
  } else {
    if (expectedErrors.length !== 0) {
      let errorState = expectedErrors.findIndex((err) => err === status);

      if (errorState !== -1) {
        throw new Error(error);
      } else {
        localStorage.removeItem("usercompany");
        throw new Error("token-expired");
      }
    } else {
      localStorage.removeItem("usercompany");
      throw new Error("token-expired");
    }
  }
};

export const packagesCU = {
  without: {
    id: 0,
    as: "Ohne Paket",
    classNameBorderColor: "packagesCU_bc_without",
    classNameTxtColor: "packagesCU_txt_without",
    classNameBgColor: "packagesCU_bg_without",
    bonus: 0,
    benefits: [],
    color: "#767575",
  },
  essential: {
    id: 1,
    as: "Essential",
    classNameBorderColor: "packagesCU_bc_essential",
    classNameTxtColor: "packagesCU_txt_essential",
    classNameBgColor: "packagesCU_bg_essential",
    bonus: 0,
    benefits: [
      {
        info: `Standard Suchoption`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Standard Support`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Feedback Funktion zu Company U`,
        moreInfo: {
          type: "essential-feedback",
          data: `Wir setzten vernünftige und rellevante Vorschläge vom Kunden gerne durch unsere I-TABTEILUNG um`,
        },
      },
    ],
    color: "#c237db",
  },
  progress: {
    id: 2,
    as: "Progress",
    classNameBorderColor: "packagesCU_bc_progress",
    classNameTxtColor: "packagesCU_txt_progress",
    classNameBgColor: "packagesCU_bg_progress",
    bonus: 10,
    benefits: [
      {
        info: `Erweiterte Suchoptionen`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Priorisierter Support`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Feedback Funktion zu Company U`,
        moreInfo: {
          type: "progress-feedback",
          data: `Wir setzten vernünftige und rellevante Vorschläge vom Kunden gerne durch unsere I-TABTEILUNG um`,
        },
      },
    ],
    color: "#db963b",
  },
  dominate: {
    id: 3,
    as: "Dominate",
    classNameBorderColor: "packagesCU_bc_dominate",
    classNameTxtColor: "packagesCU_txt_dominate",
    classNameBgColor: "packagesCU_bg_dominate",
    bonus: 15,
    benefits: [
      {
        info: `Persönliche Suchoption`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Persönliche Beratung`,
        moreInfo: {
          type: "",
          data: "",
        },
      },
      {
        info: `Feedback Funktion zu Company U`,
        moreInfo: {
          type: "dominate-feedback",
          data: `Wir setzten vernünftige und rellevante Vorschläge vom Kunden gerne durch unsere I-TABTEILUNG um`,
        },
      },
      {
        info: `Angebots Analyse`,
        moreInfo: {
          type: "dominate-analyse",
          data: `Durch erfragtes Feedback des Handwerkers`,
        },
      },
    ],
    color: "#5ca8a8",
  },
};

const setYourPackage = (dispatch, subscription) => {
  const save = (packages) => {
    let _prev = {
      id: 0,
      company_id: "",
      product_id: "",
      start_date: "",
      end_date: "--/--/--",
      amount: 0,
      product: {
        id: "",
        name: "Ohne Paket",
      },
    };

    let pack = {
      subscription: subscription.length === 0 ? _prev : subscription,
      packages,
    };

    saveYourPackage(dispatch, pack);

    return localStorage.setItem("yourpackage", JSON.stringify(pack));
  };

  if (subscription.length === 0) {
    return save(packagesCU.without);
  }

  switch (subscription.product.id) {
    case 0:
      return save(packagesCU.without);

    case 1:
      return save(packagesCU.essential);

    case 2:
      return save(packagesCU.progress);

    case 3:
      return save(packagesCU.dominate);

    default:
      return save(packagesCU.without);
  }
};

const getYourPackage = () => {
  let _prev = {
    subscription: {
      id: "",
      company_id: "",
      product_id: "",
      start_date: "Loading...",
      end_date: "Loading...",
      status: "Loading...",
      amount: "Loading...",
      product: {
        id: "",
        name: "Loading...",
      },
    },
    packages: {
      id: "",
      as: "Loading...",
      classNameBorderColor: "packagesCU_bc_dominate",
      classNameTxtColor: "packagesCU_txt_dominate",
      classNameBgColor: "packagesCU_bg_dominate",
      color: "#767575",
    },
  };

  let _yourpackage = localStorage.getItem("yourpackage");
  let yourpackage = _yourpackage === null ? _prev : JSON.parse(_yourpackage);

  return yourpackage;
};

export const yourPackage = {
  set: setYourPackage,
  get: getYourPackage,
};

export const redirectLoginC = (dispatch, navigate, route) => {
  setLastVisitedRoute(dispatch, route);
  navigate("/login-company");
};

export const getRegistrationTime = (weeks = 1, days = Number(weeks) * 7) => {
  // Obtenemos la fecha actual
  let currentDate = new Date();

  // Restamos 28 días
  currentDate.setDate(currentDate.getDate() - days);

  // Formateamos la fecha
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Los meses son 0-based, por lo que sumamos 1
  let day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day} 12:00:00`;
};

const getUserInfluencer = () => {
  let _userinfluencer = {
    id: "",
    mail: "",
    phone: "",
    city: "",
    zip: "",
    token: "",
  };

  let userinfluencer = JSON.parse(localStorage.getItem("userinfluencer"));
  let data =
    userinfluencer === "" ||
    userinfluencer === null ||
    userinfluencer === undefined
      ? _userinfluencer
      : userinfluencer;
  return data;
};

const clearUserinfluencer = () => {
  localStorage.removeItem("userinfluencer");
  return;
};

export const userInfluencer = {
  get: getUserInfluencer,
  clear: clearUserinfluencer,
};

const getUserApplicant = () => {
  let _userapplicant = {
    id: "",
    perfil: null,
    name: "Loading...",
    lastname: "Loading...",
    city: "Loading...",
    zip: "Loading...",
    tel: "Loading...",
    birthdate: "Loading...",
    gender: "Loading...",
    mail: "Loading...",
    job: "",
    apply: "",
    adjetives: "",
    timeexp: "",
    travel: "",
    selfdescription: "",
    lngpref: "",
    license: "",
    teamwork: "",
    pressure: "",
    working: "",
    skills: "",
    adaptability: "",
    objectively: "",
    learning: "",
    responsibility: "",
    extrahours: "",
    satisfied: "",
    comment: "",
    validated: "",
    salary: "Loading...",
    terms: "1",
    additional_services: "",
    register: "Loading...",
    influencer: "",
    availability: "",
    token: "",
  };

  let userapplicant = JSON.parse(localStorage.getItem("userapplicant"));
  let data =
    userapplicant === "" ||
    userapplicant === null ||
    userapplicant === undefined
      ? _userapplicant
      : userapplicant;
  return data;
};

const clearUserApplicant = () => {
  localStorage.removeItem("userapplicant");
  return;
};

export const userApplicant = {
  get: getUserApplicant,
  clear: clearUserApplicant,
};

const getUserSuperAdmin = () => {
  let _usersuperadmin = {
    id: "",
    name: "",
    mail: "",
    token: "",
  };

  let usersuperadmin = JSON.parse(localStorage.getItem("usersuperadmin"));
  let data =
    usersuperadmin === "" ||
    usersuperadmin === null ||
    usersuperadmin === undefined
      ? _usersuperadmin
      : usersuperadmin;
  return data;
};

const clearUserSuperAdmin = () => {
  localStorage.removeItem("usersuperadmin");
  return;
};

export const userSuperAdmin = {
  get: getUserSuperAdmin,
  clear: clearUserSuperAdmin,
};

export const formatNumberWithDecimals = (num) => {
  // Convertir el número a cadena y dividir la parte entera de la decimal
  const partes = num.toString().split(".");

  // Agregar puntos cada 3 dígitos en la parte entera
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Unir la parte entera y decimal de nuevo
  const resultado = partes.join(".");

  return resultado;
};
