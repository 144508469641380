/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
// ---------------------------------------------------------
import "./Login.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import SplineArea from "../../components/spline/Spline";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const MultiActionAreaCard = ({ img, title }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const Login = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "log" refer to the file: Login.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-login")}
        </title>
      </Helmet>

      <Header />

      <div className="log_container">
        <div className="log_bg_container">
          <SplineArea scene="https://prod.spline.design/hafkmwaIOvw0m4Md/scene.splinecode" />

          <div className="log_bg_darkLayer" />
        </div>

        <nav className="log_nav_login">
          <Link
            to={`/login-applicant`}
            target="_top"
            className="log_link_container"
          >
            <MultiActionAreaCard
              img={assets.imgs.bannerApplicants}
              title={t("login-for-applicants")}
            />
          </Link>

          <Link
            to={`/login-associated`}
            target="_top"
            className="log_link_container"
          >
            <MultiActionAreaCard
              img={assets.imgs.bannerInfluencer}
              title={t("login-for-influencer")}
            />
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Login;
