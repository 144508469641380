/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./Header.css";
import { _languages, t } from "../../languages/settingsLanguages";
import { WebContext } from "../../context/Context";
import { changeLanguage } from "../../context/Actions";
import {
  userApplicant,
  userCompany,
  userInfluencer,
  userSuperAdmin,
} from "../../utils/Helpers";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const HeaderInAdmin = ({ typeAdmin = "" }) => {
  const { dispatch, language } = WebContext();

  // ----------------- useState -----------------
  const [lngsOpen, setLngsOpen] = useState(false);
  // ----------------- /useState -----------------

  const navigate = useNavigate();

  window.addEventListener("click", (e) => {
    if (document.getElementById("languagesContainer") !== null) {
      if (document.getElementById("languagesContainer").contains(e.target)) {
      } else {
        setLngsOpen(false);
      }
    }
  });

  return (
    <>
      {/* Class names starting with "hd" refer to the file: Header.css */}
      <header className="hd_container">
        <Link
          to={`${
            typeAdmin === "company"
              ? "/home-company"
              : typeAdmin === "influencer"
              ? "/home-associated"
              : typeAdmin === "applicant"
              ? "/home-applicant"
              : typeAdmin === "superadmin"
              ? "/home-superadmin"
              : "/home"
          }`}
          target="_top"
          className="hd_img_container"
        >
          <img
            className="hd_logo_img"
            src={assets.brand.logoColorBgTransparent}
            alt="Logo CompanyU"
          />
        </Link>

        <div className="hd_rightInfo_container">
          <button
            className="hd_logout_btn"
            onClick={() => {
              if (window.confirm("Willst du abhängen?")) {
                if (typeAdmin === "company") {
                  userCompany.clear();
                  navigate("/login-company");
                } else if (typeAdmin === "influencer") {
                  userInfluencer.clear();
                  navigate("/login-associated");
                } else if (typeAdmin === "applicant") {
                  userApplicant.clear();
                  navigate("/login-applicant");
                } else if (typeAdmin === "superadmin") {
                  userSuperAdmin.clear();
                  navigate("/login-superadmin");
                } else {
                  userCompany.clear();
                  userInfluencer.clear();
                  userApplicant.clear();
                  userSuperAdmin.clear();
                  navigate("/login");
                }
              }
            }}
          >
            <p>{t("admin-company-header-logout")}</p>
          </button>
          {_languages.length > 1 && (
            <div id="languagesContainer" className="hd_btnsLanguages_container">
              <div
                className={`hd_btnsLngs_details ${
                  lngsOpen && "hd_btnsLngs_details_open"
                }`}
                style={{
                  height: lngsOpen ? `${3 * _languages.length}rem` : "3rem",
                  backgroundColor: lngsOpen ? "#333" : "transparent",
                }}
              >
                {_languages.map((lng, id) => {
                  // Language in use
                  return (
                    lng.shortName === language && (
                      <button
                        onClick={() => {
                          setLngsOpen(!lngsOpen);
                        }}
                        key={`${lng.shortName}_${id}`}
                        className="hd_btnLng"
                      >
                        {lng.shortName}
                      </button>
                    )
                  );
                })}
                {_languages.map((lng, id) => {
                  // More languages options
                  return (
                    lng.shortName !== language && (
                      <button
                        onClick={() => {
                          setLngsOpen(!lngsOpen);
                          changeLanguage(dispatch, lng.shortName);
                        }}
                        key={`${lng.shortName}_${id}`}
                        className="hd_btnLng"
                      >
                        {lng.shortName}
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default HeaderInAdmin;
