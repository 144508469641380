/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Link, useLocation } from "react-router-dom";
// ---------------------------------------------------------
import "./Footer.css";
import { t } from "../../languages/settingsLanguages";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const Footer = () => {
  const location = useLocation();

  const socialNetworks = [
    {
      link: "https://instagram.com/companyu_/",
      icon: assets.icons.iconInstagram,
      name: "Instagram",
    },
    {
      link: "https://www.facebook.com/profile.php?id=61550685537067",
      icon: assets.icons.iconFacebook,
      name: "Facebook",
    },
    {
      link: "https://www.tiktok.com/@companyu_de?_t=8gPt6hBL6tF&_r=1",
      icon: assets.icons.iconTiktok,
      name: "TikTok",
    },
  ];

  const footerLinks = [
    {
      path: "/",
      name: t("name-link-home"),
    },
    {
      path: "/for-applicants",
      name: t("name-link-applicants"),
    },
    {
      path: "/for-companies",
      name: t("name-link-companies"),
    },
    {
      path: "/register-associated",
      name: t("Partnerportal"),
    },
    {
      path: "/about",
      name: t("name-link-about"),
    },
    {
      path: "/data-protection",
      name: t("data-protection-title"),
    },
    {
      path: "/faq",
      name: t("faq-title"),
    },
  ];

  const BtnSocialNetworks = ({
    link = "https://companyu.de",
    icon,
    name = "",
  }) => {
    return (
      <a
        href={link}
        rel="noreferrer"
        target="_blank"
        className="ft_sn_btn_container"
      >
        <img className="ft_sn_btn_img" src={icon} alt={`Icon ${name}`} />
      </a>
    );
  };

  return (
    <>
      {/* Class names starting with "ft" refer to the file: Footer.css */}
      <footer className="ft_container">
        {footerLinks.length !== 0 && (
          <nav id="links" className="ft_col">
            <h3 className="ft_col_subtitle">{t("footer-subtitle-links")}</h3>

            {footerLinks.map((item, index) => {
              if (item.path !== location.pathname) {
                return (
                  <Link
                    key={`nav_item_${index}`}
                    to={item.path}
                    target="_top"
                    className="ft_link"
                  >
                    <p>{item.name}</p>
                  </Link>
                );
              } else {
                return (
                  <div
                    key={`nav_item_${index}`}
                    className="ft_link ft_link_current"
                  >
                    <p>{item.name}</p>
                  </div>
                );
              }
            })}
          </nav>
        )}

        <div id="about_us" className="ft_col">
          <h3 className="ft_col_subtitle">{t("footer-subtitle-contact-us")}</h3>

          <a
            href="tel:+4915161402225"
            target="_blank"
            rel="noreferrer"
            className="ft_aboutUs_info"
          >
            <p>+49 1516 1402225</p>
          </a>

          <a
            href="mailto:info@companyu.de"
            target="_blank"
            rel="noreferrer"
            className="ft_aboutUs_info"
          >
            <p>info@companyu.de</p>
          </a>

          <div className="ft_aboutUs_info ft_aboutUs_info_colorSolid">
            <p>St. Veit Straße 9, 27809 Lemwerder</p>
          </div>
        </div>

        <div id="brand" className="ft_col">
          <div className="ft_brand_container">
            <div className="ft_brand_logo_container">
              <img
                className="ft_brand_logo_img"
                src={assets.brand.logoColorBgTransparent}
                alt="Logo Company U"
              />
            </div>

            {socialNetworks.length !== 0 && (
              <div className="ft_brand_socialNetworks_container">
                {socialNetworks.map((btn, index) => {
                  return (
                    <BtnSocialNetworks
                      key={`btn_socialNetwork_${index}`}
                      link={btn.link}
                      icon={btn.icon}
                      name={btn.name}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
