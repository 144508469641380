/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// ---------------------------------------------------------
import "./Header.css";
import { _languages, t } from "../../languages/settingsLanguages";
import { WebContext } from "../../context/Context";
import { changeLanguage } from "../../context/Actions";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const Header = () => {
  const { dispatch, language } = WebContext();

  // ----------------- useState -----------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [lngsOpen, setLngsOpen] = useState(false);
  // ----------------- /useState -----------------

  const location = useLocation();

  const arrLinksInHeader = [
    {
      path: "/",
      name: t("name-link-home"),
    },
    {
      path: "/services",
      name: t("Leistungen"),
    },
    {
      path: "/for-applicants",
      name: t("name-link-applicants"),
    },
    {
      path: "/for-companies",
      name: t("name-link-companies"),
    },
    // {
    //   path: "/about",
    //   name: t("name-link-about"),
    // },
    // {
    //   path: "/login",
    //   name: t("name-link-login"),
    // },
    {
      path: "/register",
      // name: t("name-link-register"),
      name: "Account",
    },
  ];

  useEffect(() => {
    !mobileMenuOpen && (document.body.style.overflow = "visible");
  });

  // ----------------------- handles -----------------------

  const onBtnMenu = () => {
    !mobileMenuOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");

    return setMobileMenuOpen(!mobileMenuOpen);
  };

  const onLink = () => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "visible";
      setMobileMenuOpen(false);
      return;
    }
  };

  window.addEventListener("click", (e) => {
    if (document.getElementById("languagesContainer") !== null) {
      if (document.getElementById("languagesContainer").contains(e.target)) {
      } else {
        setLngsOpen(false);
      }
    }
  });
  // ----------------------- /handles -----------------------

  const Nav = () => {
    return (
      <>
        {arrLinksInHeader.map((item, index) => {
          if (item.path !== location.pathname) {
            return (
              <Link
                key={`nav_item_${index}`}
                to={item.path}
                target="_top"
                className={`hd_nav_item ${
                  (item.path === "/register" || item.path === "/account") &&
                  "hd_nav_specialItem"
                }`}
                onClick={onLink}
              >
                <p>{item.name}</p>

                <div className="hd_nav_item_cursorHover" />
              </Link>
            );
          } else {
            return (
              <div
                key={`nav_item_${index}`}
                className="hd_nav_item hd_nav_item_current"
              >
                <p>{item.name}</p>
              </div>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      {/* Class names starting with "hd" refer to the file: Header.css */}
      <header className="hd_container">
        <Link to="/" target="_top" className="hd_img_container">
          <img
            className="hd_logo_img"
            src={assets.brand.logoColorBgTransparent}
            alt="Logo CompanyU"
          />
        </Link>

        <nav className="hd_nav">
          <Nav />
        </nav>

        <div className="hd_rightInfo_container">
          {_languages.length > 1 && (
            <div id="languagesContainer" className="hd_btnsLanguages_container">
              <div
                className={`hd_btnsLngs_details ${
                  lngsOpen && "hd_btnsLngs_details_open"
                }`}
                style={{
                  height: lngsOpen ? `${3 * _languages.length}rem` : "3rem",
                  backgroundColor: lngsOpen ? "#333" : "transparent",
                }}
              >
                {_languages.map((lng, id) => {
                  // Language in use
                  return (
                    lng.shortName === language && (
                      <button
                        onClick={() => {
                          setLngsOpen(!lngsOpen);
                        }}
                        key={`${lng.shortName}_${id}`}
                        className="hd_btnLng"
                      >
                        {lng.shortName}
                      </button>
                    )
                  );
                })}
                {_languages.map((lng, id) => {
                  // More languages options
                  return (
                    lng.shortName !== language && (
                      <button
                        onClick={() => {
                          setLngsOpen(!lngsOpen);
                          changeLanguage(dispatch, lng.shortName);
                        }}
                        key={`${lng.shortName}_${id}`}
                        className="hd_btnLng"
                      >
                        {lng.shortName}
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          )}

          {arrLinksInHeader.length !== 0 && (
            <button
              className={`hd_btnMenu_container ${
                mobileMenuOpen && "hd_btnMenu_close"
              }`}
              onClick={onBtnMenu}
            >
              <div className="hd_btnMenu_lineContainer">
                <div className="hd_btnMenu_line" />
                <div className="hd_btnMenu_line" />
                <div className="hd_btnMenu_line" />
              </div>
            </button>
          )}
        </div>

        <div
          className={`hd_menuMobile_darkLayer ${
            mobileMenuOpen && "hd_menuMobile_darkLayer_active"
          }`}
          onClick={onBtnMenu}
        />

        {/* --------------- Menu Mobile --------------- */}

        {arrLinksInHeader.length !== 0 && (
          <div
            className={`hd_menuMobile_container ${
              mobileMenuOpen && "hd_menuMobile_container_open"
            }`}
          >
            <div
              style={{
                height: "15%",
              }} /* This fragment contain a height equal that menu footer height in phone */
            />

            <nav className="hd_menuMobile_nav">
              <div className="hd_menuMobile_nav_contain">
                <Nav />
              </div>
            </nav>

            <div className="hd_menuMobile_footer">
              <p>COMPANY U</p>
            </div>
          </div>
        )}
        {/* --------------- /Menu Mobile --------------- */}
      </header>
    </>
  );
};

export default Header;
