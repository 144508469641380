/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import { getAllWorksInSuperAdmin } from "./servicesSuperAdmin";
import { WebContext } from "../../context/Context";
import { userSuperAdmin } from "../../utils/Helpers";
import Work from "../../components/works/Work";
// ---------------------------------------------------------

const ViewAllWorksSuperAdmin = () => {
  const { dispatch, arrAllWorks } = WebContext();
  const { token } = userSuperAdmin.get();

  useEffect(() => {
    getAllWorksInSuperAdmin(dispatch, token);
  }, [dispatch, token]);

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}

      <section className="hma_works_container">
        <h2 className="hma_works_title">Verfügbare Jobs</h2>

        <div className="hma_works_arr_container">
          {arrAllWorks.length === 0 ? (
            <>
              <p>Keine Jobs verfügbar</p>
            </>
          ) : (
            <>
              {arrAllWorks.map((work, index) => {
                return <Work key={`work_${index}`} work={work} />;
              })}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ViewAllWorksSuperAdmin;
