/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./CompanyPackages.css";
import { t } from "../../languages/settingsLanguages";
import Header from "../../components/header/Header";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const BuyFailed = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "cpk" refer to the file: CompanyPackages.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-buy-failed")}
        </title>
      </Helmet>

      <Header />

      <div className="cpk_buy_message_container">
        <h2 style={{ color: "#ff0000" }}>{t("company-buy-failed-title")}</h2>

        <div className="cpk_buy_message_img">
          <img src={assets.imgs.failed} alt="Buy failed" />
        </div>
      </div>
    </>
  );
};

export default BuyFailed;
