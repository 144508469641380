/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./BannerWithChildren.css";
// ---------------------------------------------------------

const BannerWithChildren = ({
  otherClassNameContainer = "",
  positionImg = "left" || "right" || "top" || "bottom",
  img,
  alt = "",
  objectFit = "cover",
  objectPosition = "center",
  colorHEXDarkLayer = "0d0d0d",
  opacityDarkLayer = 0,
  children = <></>,
}) => {
  if (
    typeof opacityDarkLayer !== "number" &&
    typeof opacityDarkLayer !== "string"
  ) {
    throw new Error('The "opacityDarkLayer" parameter must be a number type');
  } else if (opacityDarkLayer !== 0 && `${opacityDarkLayer}`.length !== 2) {
    throw new Error('"opacityDarkLayer" Must have two digits');
  } else if (colorHEXDarkLayer.length !== 6)
    throw new Error('"colorHEXDarkLayer" Must have six digits');

  let flexDirection =
    positionImg === "left"
      ? "bwc_left"
      : positionImg === "right"
      ? "bwc_right"
      : positionImg === "top"
      ? "bwc_top"
      : positionImg === "bottom"
      ? "bwc_bottom"
      : "bwc_left";

  return (
    <>
      {/* Class names starting with "bwc" refer to the file: BannerWithChildren.css */}
      <section
        className={`bwc_container ${flexDirection} ${otherClassNameContainer}`}
      >
        <div className="bwc_col_1">{children}</div>

        <div className="bwc_col_2">
          <div className="bwc_col_img_container">
            <img
              className="bwc_col_img_img"
              src={img}
              alt={alt}
              style={{ objectFit, objectPosition }}
            />
          </div>
          <div
            className="bwc_col_darkLayer"
            style={{
              backgroundColor: `#${colorHEXDarkLayer}${opacityDarkLayer}`,
            }}
          />
        </div>
      </section>
    </>
  );
};

export default BannerWithChildren;
