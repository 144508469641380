/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_LOGIN_APPLICANT, TOKEN_DB } from "../../api/apis";
import { setUserapplicant } from "../../context/Actions";
import { userCompany, userInfluencer } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesLoginApplicant = async (
  dispatch,
  credentials,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  const API = API_BASE;

  let _credentials = {
    mail: credentials.login_mail,
    password: credentials.login_password,
  };

  await fetch(`${API}${PATH_LOGIN_APPLICANT}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_credentials),
  })
    .then((response) => response.json())
    .then((res) => {
      let userapplicant = {
        id: res.id,
        perfil: res.perfil,
        name: res.name,
        lastname: res.lastname,
        city: res.city,
        zip: res.zip,
        tel: res.tel,
        birthdate: res.birthdate,
        gender: res.gender,
        mail: res.mail,
        job: res.job,
        apply: res.apply,
        adjetives: res.adjetives,
        timeexp: res.timeexp,
        travel: res.travel,
        selfdescription: res.selfdescription,
        lngpref: res.lngpref,
        license: res.license,
        teamwork: res.teamwork,
        pressure: res.pressure,
        working: res.working,
        skills: res.skills,
        adaptability: res.adaptability,
        objectively: res.objectively,
        learning: res.learning,
        responsibility: res.responsibility,
        extrahours: res.extrahours,
        satisfied: res.satisfied,
        comment: res.comment,
        validated: res.validated,
        salary: res.salary,
        terms: res.terms,
        additional_services: res.additional_services,
        register: res.register,
        influencer: res.influencer,
        availability: res.availability,
        token: res.token,
      };

      setUserapplicant(dispatch, userapplicant);
      localStorage.setItem("userapplicant", JSON.stringify(userapplicant));

      response(
        res.message === "failed credentails"
          ? "failed-credentials"
          : "successful",
        res.message === "failed credentails"
          ? "failed-credentials"
          : "successful"
      );

      loading(false);

      userCompany.clear();
      userInfluencer.clear();
    })
    .catch((error) => {
      // console.error(error);
      response("failed");
      loading(false);
    });
};

export default servicesLoginApplicant;
