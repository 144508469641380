/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_REGISTER_COMPANY, TOKEN_DB } from "../../api/apis";
import servicesLoginCompany from "../loginCompany/servicesLoginCompany";
// ---------------------------------------------------------

const servicesFormCompany = async (
  dispatch,
  data,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;

  const _data = {
    login_email: data.company_mail,
    password: data.company_password,
    name: data.company_name,
    description: data.company_description,
    link: data.company_link_website,
    zip: data.company_postal_code,
    contact_name: data.company_contact_name,
    contact_email: data.company_contact_mail,
    contact_phone: data.company_contact_phone,
    job: data.company_what_job,
    teamwork: data.company_teamwork,
    pressure: data.company_pressure,
    working: data.company_working,
    skills: data.company_skills,
    adaptability: data.company_adaptability,
    objectively: data.company_objectively,
    learning: data.company_learning,
    responsibility: data.company_responsibility,
    extrahours: data.company_extrahours,
    satisfied: data.company_satisfied,
    availability: "",
  };

  await fetch(`${API}${PATH_REGISTER_COMPANY}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-token": TOKEN_DB,
    },
    body: JSON.stringify(_data),
  })
    .then((response) => response.json())
    .then((res) => {
      let credentials = {
        login_mail: _data.company_mail,
        login_password: _data.company_password,
      };

      servicesLoginCompany(
        dispatch,
        credentials,
        (res) => response(res === "failed" ? "failed-login" : res),
        (load) => loading(load)
      );
    })
    .catch((error) => {
      response("failed");
      loading(false);
    });
};

export default servicesFormCompany;
