/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
// ---------------------------------------------------------
import "./ScrollTopAnimation.css";
// ---------------------------------------------------------

const ScrollTopAnimation = ({
  children,
  getClass = "",
  addClassInAnimation = "",
}) => {
  useEffect(() => {
    let animated = document.querySelectorAll(
      getClass.length ? `.${getClass}` : ".sta_animated"
    );
    const ToShowElements = () => {
      let scrollTop = document.documentElement.scrollTop;
      for (let i = 0; i < animated.length; i++) {
        let heightAnimated = animated[i].offsetTop;
        if (heightAnimated - 600 < scrollTop) {
          animated[i].classList.add(
            addClassInAnimation.length ? addClassInAnimation : "sta_animated_on"
          );
        } else {
          animated[i].classList.remove(
            addClassInAnimation.length ? addClassInAnimation : "sta_animated_on"
          );
        }
      }
    };
    window.addEventListener("scroll", ToShowElements);
  });

  return (
    <>
      {/* Class names starting with "sta" refer to the file: ScrollTopAnimation.css */}
      <section className={getClass.length ? getClass : "sta_animated"}>
        <>{children}</>
      </section>
    </>
  );
};

export default ScrollTopAnimation;
