/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// ---------------------------------------------------------
import "./ContactForm.css";
import { helpHttp } from "../../utils/helpHttp";
import Input, { ChildrenLabelDefault, TextArea } from "../input/Input";
import { t } from "../../languages/settingsLanguages";
// ---------------------------------------------------------

let mailto = "info@companyu.de";

const ContactForm = ({
  color = "#0d0d0d",
  txtColorForm = "#f3f3f3",
  initialForm = {
    Service: "",
    name: "",
    lastname: "",
    nameUnternehmens: "",
    datapicker: "",
    mail: "",
    phone: "",
    Message: "",
    checkbox_legalConditions: true,
    ready: false,
  },
  onChangeForm = () => {},
}) => {
  const [form, setForm] = useState(initialForm);

  const [errorsForm, setErrorsForm] = useState(form);
  const [minDate, setMinDate] = useState("");

  const [loadingSending, setLoadingSending] = useState("without-sending");

  const handleChange = (e) => {
    let { name, value } = e;

    onChangeForm({
      ...form,
      [name]: value,
    });

    return setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (e, error) => {
    let { name } = e;

    return setErrorsForm({
      ...errorsForm,
      [name]: error,
    });
  };

  const handleLegalConditions = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      checkbox_legalConditions: !form.checkbox_legalConditions,
    });

    setErrorsForm({
      ...errorsForm,
      checkbox_legalConditions: form.checkbox_legalConditions ? "require" : "",
    });

    return;
  };

  const dateAvailable = (dataPicker) => {
    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0); // Set hours to midnight for comparison

    // Convert the date from the datepicker to a Date object
    const datePickerDate = new Date(dataPicker);

    // Set hours to midnight for comparison
    datePickerDate.setHours(0, 0, 0, 0);

    // Compare the date from the datepicker with tomorrow's date
    const isAvailable = datePickerDate > tomorrow;

    return isAvailable;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loadingSending === "without-sending" && form.ready) {
      setLoadingSending("sending");

      helpHttp()
        .post(`https://formsubmit.co/ajax/${mailto}`, {
          body: {
            Leistung: initialForm.Service,
            Name: initialForm.name,
            Nachname: initialForm.lastname,
            "Datum des Termins": initialForm.datapicker,
            "E-Mail": initialForm.mail,
            Telefonnummer: initialForm.phone,
            Nachricht: initialForm.Message,
            Geschäftsbedingungen: initialForm && "Akzeptiert",
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log(
            "res:",
            res,
            "res.success:",
            res.success,
            "!res.success:",
            !res.success
          );
          setLoadingSending(res.success ? "successful-send" : "failed-sending");
        });
    }

    return;
  };

  let regIsTxt = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regIsMail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  let regIsDescription = /^.{1,255}$/;
  let regIsNum = /[0-9]/;
  let regIsPhone = /^\+?\d{10,13}$/;

  useEffect(() => {
    // Get today's date
    const today = new Date();

    // Get the date for the day after today
    const nextDay = new Date();
    nextDay.setDate(today.getDate() + 1);

    // Compare the date from the datepicker with the day after today
    let minDate = `${nextDay}`.slice(4, 15);

    // Return the results
    setMinDate(minDate);
  }, []);

  useEffect(
    () =>
      setForm({
        ...form,
        Service: initialForm.Service,
      }),
    [initialForm]
  );

  useEffect(() => {
    if (!form.ready) {
      if (
        form.name.length >= 1 &&
        form.lastname.length >= 1 &&
        form.nameUnternehmens.length >= 1 &&
        form.mail.length >= 1 &&
        form.phone.length >= 1 &&
        form.Message.length >= 1 &&
        form.checkbox_legalConditions &&
        errorsForm.name.length === 0 &&
        errorsForm.lastname.length === 0 &&
        errorsForm.nameUnternehmens.length === 0 &&
        errorsForm.mail.length === 0 &&
        errorsForm.phone.length === 0 &&
        errorsForm.Message.length === 0
      ) {
        setForm({
          ...form,
          ready: true,
        });
      }
    } else {
      if (
        form.name.length >= 1 &&
        form.lastname.length >= 1 &&
        form.nameUnternehmens.length >= 1 &&
        form.mail.length >= 1 &&
        form.phone.length >= 1 &&
        form.Message.length >= 1 &&
        form.checkbox_legalConditions &&
        errorsForm.name.length === 0 &&
        errorsForm.lastname.length === 0 &&
        errorsForm.nameUnternehmens.length === 0 &&
        errorsForm.mail.length === 0 &&
        errorsForm.phone.length === 0 &&
        errorsForm.Message.length === 0
      ) {
      } else {
        setForm({
          ...form,
          ready: false,
        });
      }
    }
  }, [form, errorsForm]);

  return (
    <>
      <form
        id="contact_form"
        className="conform_container"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="conform__title">
          <h2 style={{ color }}>Unverbindliches Erstgespräch vereinbaren!</h2>
        </div>

        {/* -------------------- Name -------------------- */}
        <Input
          id="Name"
          nameInput="name"
          // placeholder={t("form-applicant-step-1-name")}
          placeholder="Jon"
          defaultValue={initialForm.name}
          required
          responseInput={(target, isOK) => handleChange(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Name"} color={txtColorForm} />
          }
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Name ------------------------- */}

        {/* -------------------- Lastname -------------------- */}
        <Input
          id="Lastname"
          nameInput="lastname"
          placeholder="Smith"
          defaultValue={initialForm.lastname}
          required
          responseInput={(target, isOK) => handleChange(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault
              required
              txt={"Nachname"}
              color={txtColorForm}
            />
          }
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Lastname ------------------------- */}

        {/* ------------------- Name des Unternehmens ------------------- */}
        <Input
          id="Name Unternehmens"
          nameInput="nameUnternehmens"
          // placeholder={t("form-applicant-step-1-name")}
          placeholder="Name des Unternehmens"
          defaultValue={initialForm.name}
          required
          responseInput={(target, isOK) => handleChange(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault
              required
              txt={"Name des Unternehmens"}
              color={txtColorForm}
            />
          }
          errorMessage={t("form-required")}
        />
        {/* ------------------- /Name des Unternehmens ------------------------ */}

        {/* -------------------- Email -------------------- */}
        <Input
          id="Email"
          nameInput="mail"
          placeholder="yourmail@mail.com"
          defaultValue={initialForm.mail}
          type="email"
          required
          handleBlurContainsError={(e) => {
            return e.target.value.length < 6 || !regIsMail.test(form.mail);
          }}
          responseInput={(target, isOK) => handleChange(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault
              required
              txt={"E-Mail Adresse"}
              color={txtColorForm}
            />
          }
          errorMessage={
            form.mail.length === 0
              ? t("form-required")
              : !regIsMail.test(form.mail)
              ? t("form-applicant-step-1-please-valid-mail")
              : ""
          }
        />
        {/* -------------------- /Email -------------------- */}

        {/* -------------------- Phone number -------------------- */}
        <Input
          id="Phone"
          nameInput="phone"
          placeholder="04900000000"
          defaultValue={initialForm.phone}
          type="tel"
          required
          handleBlurContainsError={(e) => {
            let validNumberPhone = !regIsPhone.test(e.target.value);

            return validNumberPhone;
          }}
          responseInput={(target, isOK) => handleChange(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault
              required
              txt={"Telefonnummer"}
              color={txtColorForm}
            />
          }
          errorMessage={
            form.phone.length === 0
              ? t("form-required")
              : !regIsPhone.test(form.phone)
              ? t("form-applicant-step-1-please-valid-number")
              : ""
          }
        />
        {/* -------------------- /Phone number -------------------- */}

        {/* -------------------- Data Picker -------------------- */}
        <Input
          id="Date"
          nameInput="date"
          defaultValue={initialForm.datapicker}
          type="datetime-local"
          min={""}
          required
          responseInput={(target, isOK) => handleChange(target, isOK)}
          handleBlurContainsError={(e) => !dateAvailable(e.target.value)}
          label
          childrenLabel={
            <ChildrenLabelDefault
              required
              txt={"Wann sollen wir Sie kontaktieren?"}
              color={txtColorForm}
            />
          }
          errorMessage={
            !dateAvailable(form.datapicker)
              ? `Das angegebene Datum muss später sein als ${minDate}`
              : form.datapicker?.length === 0
              ? t("form-required")
              : ""
          }
        />
        {/* -------------------- /Data Picker -------------------- */}

        {/* -------------------- Message -------------------- */}
        <TextArea
          id="Message"
          label={"Ihre Nachricht"}
          colorLabel={txtColorForm}
          placeholder={"Ihre Nachricht"}
          responseTextArea={(target, isOK) => {
            handleChange(target, isOK);
          }}
          defaultValue={initialForm.Message}
          required
          error={errorsForm.Message === false}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Message -------------------- */}

        <p style={{ color: "#f3f3f3" }}>
          Beim Absenden, erkläre ich mich mit den Nutzungsbedingungen und der{" "}
          <Link
            style={{ color: "#f3f3f3" }}
            to={`/data-protection`}
            target="_blank"
          >
            Datenschutzerklärung
          </Link>{" "}
          von CompanyU einverstanden.
        </p>

        {loadingSending === "without-sending" ? (
          <>
            {initialForm.ready ? (
              <button
                type="submit"
                style={{ backgroundColor: color }}
                className="conform_btn_send"
                onClick={(e) => handleSubmit(e)}
              >
                Senden
              </button>
            ) : (
              <div
                style={{ backgroundColor: color }}
                className="conform_btn_send conform_btn_send_disable"
              >
                Senden
              </div>
            )}
          </>
        ) : loadingSending === "successful-send" ? (
          <div className="conform_btn_sending">
            <p>Senden</p>
          </div>
        ) : (
          loadingSending === "failed-sending" && (
            <button
              type="submit"
              style={{ backgroundColor: color }}
              className="conform_btn_send"
              onClick={(e) => handleSubmit(e)}
            >
              Es ist ein Fehler aufgetreten, versuchen Sie es erneut.
            </button>
          )
        )}
      </form>
    </>
  );
};

export default ContactForm;
