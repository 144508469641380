/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_BUY_PACKAGE, TOKEN_DB } from "../../api/apis";
import { validateActiveSessionTokenCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const getBilling = async (
  dispatch,
  id,
  privateSessionToken,
  responsePackages = () => {}
) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_BUY_PACKAGE}/${id}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      responsePackages(res.reverse());
    })
    .catch((error) => {
      // console.error(error);
      responsePackages(
        error.message === "token-expired" ? error.message : "unexpected-error"
      );
    });
};

export default getBilling;

export const cancelBuy = async (
  transaction_id,
  privateSessionToken,
  response = () => {}
) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_BUY_PACKAGE}/${transaction_id}`, {
    method: "DELETE",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      response(res);
    })
    .catch((error) => {
      // console.error(error);
    });
};
