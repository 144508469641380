/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_APPLICANT_UNLOCK, TOKEN_DB } from "../../api/apis";
import { setArrApplicantsList } from "../../context/Actions";
import { validateActiveSessionTokenCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesApplicantUnlocked = async (
  dispatch,
  id,
  applicant_id,
  privateSessionToken,
  responseInfo = () => {}
) => {
  const API = API_BASE;

  await fetch(
    `${API}${PATH_APPLICANT_UNLOCK}?company_id=${id}&applicant_id=${applicant_id}`,
    {
      method: "GET",
      headers: {
        "x-token": TOKEN_DB,
        Authorization: `Bearer ${privateSessionToken}`,
      },
    }
  )
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
    //   console.log(res);
      //   setArrApplicantsList(dispatch, res.reverse());
      responseInfo(res);
    })
    .catch((error) => {
      // console.error(error);
      //   setArrApplicantsList(dispatch, []);
    });
};

export default servicesApplicantUnlocked;
