/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_GET_APPLICANT_BY_ID, TOKEN_DB } from "../../api/apis";
import { validateActiveSessionTokenCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesApplicant = async (
  loading = (state) => {},
  privateSessionToken,
  id,
  company_id,
  responseApplicant = (data) => {}
) => {
  loading(true);
  const API = API_BASE;

  let saveDataFilters = JSON.parse(localStorage.getItem("filters"));

  await fetch(
    `${API}${PATH_GET_APPLICANT_BY_ID}?applicant_id=${id}&company_id=${company_id}${saveDataFilters.job}`,
    {
      method: "GET",
      headers: {
        "x-token": TOKEN_DB,
        Authorization: `Bearer ${privateSessionToken}`,
      },
    }
  )
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      responseApplicant(res);
      loading(false);
    })
    .catch((error) => {
      loading(false);
      responseApplicant(
        error.message === "token-expired" ? "token-expired" : "empty"
      );
    });
};

export default servicesApplicant;
