/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./ApplicantCard.css";
import { t } from "../../languages/settingsLanguages";
import {
  calculateAge,
  redirectLoginC,
  userCompany,
  yourPackage,
} from "../../utils/Helpers";
import { setSaveApplicantID } from "../../context/Actions";
import { WebContext } from "../../context/Context";
import Paragraph from "../paragraph/Paragraph";
import Button from "../button/Button";
import { unlockApplicant } from "../../screens/applicantsList/servicesApplicantsList";
import servicesApplicantUnlocked from "./servicesApplicant";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const ApplicantCard = ({ data, toUnblock = false }) => {
  const { dispatch, yourpackage } = WebContext();

  const [showImg, setShowImg] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataInformation, setDataInformation] = useState({
    tel: "Loading...",
    mail: "Loading...",
  });

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { subscription } = yourPackage.get();
  const { amount } = subscription === undefined ? yourpackage : subscription;

  let {
    id,
    unlock,
    perfil,
    name,
    lastname,
    city,
    zip,
    job,
    timeexp,
    birthdate,
    selfdescription,
    license,
    adjetives,
    km,
    lngpref,
    match,
  } = data;

  let age = calculateAge(birthdate);
  let bgColorMatch =
    Number(match) < 25
      ? "#f5bab6"
      : Number(match) < 50
      ? "#f5d2b6"
      : Number(match) < 75
      ? "#f5ecb6"
      : "#c7f5b6";

  let _prevAdjectives = { one: "", two: "", three: "" };
  adjetives = JSON.parse(adjetives);
  let arrAdjectives = adjetives === null ? _prevAdjectives : adjetives;

  let _license =
    license === null ? "" : t(`form-applicant-step-3-license-${license}`);

  let _timeexp =
    timeexp === null
      ? ""
      : `${t(`form-applicant-step-3-time-exp-${timeexp}`)} ${
          timeexp === "option-1" ? "" : t("applicant-card-experience")
        }`;

  const company_id = userCompany.get().id;
  const token = userCompany.get().token;

  useEffect(() => {
    token === "" && redirectLoginC(dispatch, navigate, `applicant/${id}`);
  });

  useEffect(() => {
    if (data.unlock) {
      servicesApplicantUnlocked(dispatch, company_id, id, token, (res) => {
        setDataInformation({
          tel: res.tel,
          mail: res.mail,
        });
      });
    }
  }, [data, dispatch, company_id, id, token]);

  const Adjective = ({ adjective = "", prevT = "" }) => {
    let txtAdj = adjective === null ? "" : t(`${prevT}${adjective}`);

    return (
      <div className="appc_info_adj">
        <div className="appc_info_adj_dot" />
        <p>{txtAdj}</p>
      </div>
    );
  };

  const ModalConfirm = () => {
    const handleUnblock = () => {
      setModal(false);
      unlockApplicant(company_id, id, token, (res) =>
        res === "token-expired"
          ? redirectLoginC(dispatch, navigate, `applicant/${id}`)
          : window.location.reload()
      );
    };

    return (
      <>
        <div className="appc_modalConfirm_container">
          <div className="app_modal_confirm">
            <Paragraph withTitle title={t("admin-company-unblock-confirm")} />

            <Paragraph otherClassNameParagraph="app_modal_confirm_note">
              <p>{t("admin-company-unblock-confirm-note")}</p>
            </Paragraph>

            <div className="app_modal_confirm_btns_container">
              <Button
                otherClassNameBtn="app_modal_confirm_btn"
                title={t("admin-company-unblock-confirm-cancel")}
                onclick={() => setModal(false)}
              />

              <Button
                otherClassNameBtn="app_modal_confirm_btn app_modal_confirm_btn_confirm"
                title={t("admin-company-unblock-confirm-confirm")}
                onclick={handleUnblock}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* Class names starting with "appc" refer to the file: ApplicantCard.css */}
      {name !== "" && (
        <section className="appc_container">
          {toUnblock && modal ? <ModalConfirm /> : null}

          <div className="appc_profile_container">
            <button
              className={`appc_profile_img_container ${
                showImg && "appc_profile_img_big_container"
              }`}
              onClick={() => setShowImg(!showImg)}
            >
              <img
                className="appc_profile_img_img"
                src={
                  perfil === "" ||
                  perfil === "null" ||
                  perfil === "undefined" ||
                  perfil === null ||
                  perfil === undefined
                    ? assets.icons.prewProfileImg
                    : `https://companyu.de/backend/${perfil}`
                }
                alt={`Profile applicant: ${data.name}`}
              />
            </button>

            <div className="appc_profile_age_container">
              <p>
                {age} {t("applicant-year")}
              </p>
            </div>

            <div
              className="appc_profile_match_container"
              style={{ backgroundColor: bgColorMatch }}
            >
              <div className="app_profile_match">
                <p>{data.match}%</p>
              </div>
            </div>
          </div>

          <div className="appc_info_container">
            <div className="appc_info_header_container">
              <div className="appc_info_header_username_container">
                <h3>
                  {name} {unlock ? lastname : ""}
                </h3>
              </div>

              <div className="appc_info_header_btns_container">
                {/* <button className="appc_info_header_btn">
                    <div className="appc_info_header_btn_start" />
                </button> */}

                {pathname === "/preview-applicants" ||
                pathname === "/applicants-list" ? (
                  <Link
                    className="appc_info_all_link_container"
                    target="_top"
                    to={`/applicant/${id}`}
                    onClick={() => setSaveApplicantID(dispatch, id)}
                  >
                    <div className="appc_info_all_link_line appc_info_all_link_line_top" />
                    <div className="appc_info_all_link_line appc_info_all_link_line_bottom" />
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div
              className={`appc_info_detail_container ${
                toUnblock || unlock ? "appc_info_detail_toUnblock" : ""
              }`}
            >
              <div className="appc_info_detail_userinfo">
                <div className="appc_info_profession">
                  <p>
                    <b>{t(`form-applicant-step-2-experience-${job}`)},</b>{" "}
                    {_timeexp}
                  </p>
                </div>

                <div className="appc_info_selfdescription">
                  <p>{selfdescription}</p>
                </div>

                <div className="appc_info_license">
                  <p>
                    <b>{t("applicant-card-city")}:</b> {city}
                  </p>
                </div>

                <div className="appc_info_license">
                  <p>
                    <b>{t("applicant-card-zip")}:</b> {zip}
                  </p>
                </div>

                <div className="appc_info_license">
                  <p>
                    <b>{t("applicant-card-license")}:</b> {_license}
                  </p>
                </div>

                <div className="appc_info_adjectives_container">
                  <div className="appc_info_adjectives">
                    {arrAdjectives.one !== "" && (
                      <Adjective
                        adjective={arrAdjectives.one}
                        prevT={"form-applicant-step-2-adjective-"}
                      />
                    )}

                    {arrAdjectives.two !== "" && (
                      <Adjective
                        adjective={arrAdjectives.two}
                        prevT={"form-applicant-step-2-adjective-"}
                      />
                    )}

                    {arrAdjectives.three !== "" && (
                      <Adjective
                        adjective={arrAdjectives.three}
                        prevT={"form-applicant-step-2-adjective-"}
                      />
                    )}
                  </div>

                  <div style={{ flex: 1 }} />

                  <div className="appc_info_otherDatas">
                    {/* <Adjective adjective={`~${km} km`} /> */}
                    <Adjective
                      adjective={lngpref}
                      prevT="form-applicant-step-3-lngpref-"
                    />
                  </div>
                </div>
              </div>

              <div
                className={`appc_info_btns_contact_container ${
                  unlock && "appc_info_btns_contact_extend"
                }`}
              >
                {unlock ? (
                  <div className="appc_btns_contact_available_container">
                    <Button
                      otherClassNameBtn="appc_btns_contact_available"
                      // title={dataInformation.tel}
                      title="Telefontaste wird gewartet (7 Werktage)"
                      // type="link"
                      // link={`tel:${dataInformation.tel}`}
                    />
                    <Button
                      otherClassNameBtn="appc_btns_contact_available"
                      title={dataInformation.mail}
                      type="link"
                      link={`mailto:${dataInformation.mail}`}
                    />
                  </div>
                ) : (
                  <>
                    {toUnblock ? (
                      <button
                        className="appc_info_btn"
                        onClick={() =>
                          amount === 0
                            ? navigate("/company-packages")
                            : setModal(true)
                        }
                      >
                        <h4>{t("admin-company-unblock")}</h4>

                        <div className="appc_info_btn_img_blocked_container">
                          <img
                            className="appc_info_btn_img"
                            src={assets.icons.iconPadlock}
                            alt="To contact"
                          />
                        </div>
                      </button>
                    ) : (
                      <Link
                        className="appc_info_btn"
                        target="_top"
                        to={`/applicant/${id}`}
                        onClick={() => setSaveApplicantID(dispatch, id)}
                      >
                        <img
                          className="appc_info_btn_img"
                          src={assets.icons.iconContact}
                          alt="To contact"
                        />

                        <div className="appc_info_btn_img_blocked_container">
                          <img
                            className="appc_info_btn_img"
                            src={assets.icons.iconPadlock}
                            alt="To contact"
                          />
                        </div>
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ApplicantCard;
