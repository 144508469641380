/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./HomeInfluencer.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import servicesHomeInfluencer from "./servicesHomeInfluencer";
import { userInfluencer } from "../../utils/Helpers";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const HomeInfluencer = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const { name, id, token } = userInfluencer.get();

  const navigate = useNavigate();

  useEffect(() => {}, [dispatch, id, token]);

  useEffect(() => {
    if (token === "" || token === undefined) {
      navigate("/login-associated");
    }
  });

  let linkshare = `https://companyu.de/register-applicant?idpartner=${id}`;

  return (
    <>
      {/* Class names starting with "hmi" refer to the file: HomeInfluencer.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-home-company")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="influencer" />

      <div className="hmi_container">
        <section className="hmi_card_container">
          <div>
            <h2>Registrierungslink teilen</h2>

            <div className="hmi_card_info">
              <p>Teilen Sie diesen Link</p>

              <a
                href={linkshare}
                target="_blank"
                rel="noreferrer"
                className="hmi_card_link"
              >
                <p>{linkshare}</p>
              </a>
            </div>
          </div>
        </section>

        <section className="hmi_card_container">
          <h2>Affiliate-Insight-Dashboard von Partner {name}</h2>

          <p>
            In der Abrechnungstabelle sehen Sie alle Bewerber, die sich mit
            Ihrem Code registriert haben und pro Monat kontaktiert wurden
          </p>

          <Button type="link" link="/gains-associated" title="Generiert" />
        </section>
      </div>
    </>
  );
};

export default HomeInfluencer;
