/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Button from "../../components/button/Button";
import Input, { ChildrenLabelDefault } from "../../components/input/Input";
import servicesSuperAdmin from "./servicesSuperAdmin";
import { WebContext } from "../../context/Context";
// ---------------------------------------------------------

const LoginSuperAdmin = ({ docTitle = "" }) => {
  const { dispatch } = WebContext();

  const [modalRegister, setModalRegister] = useState(false);
  // const [registerData, setRegisterData] = useState({
  //   register_mail: "",
  //   register_password: "",
  //   register_repeatPassword: "",
  // });
  const [loginData, setLoginData] = useState({
    login_mail: "",
    login_password: "",
  });
  const [errorFreeLogin, setErrorFreeLogin] = useState(loginData);
  // const [errorFreeRegister, setErrorFreeRegiser] = useState(registerData);

  const navigate = useNavigate();

  // const handleChangeInputRegister = (target, isOK) => {
  //   setRegisterData({
  //     ...registerData,
  //     [target.name]: target.value,
  //   });

  //   setErrorFreeRegiser({
  //     ...errorFreeRegister,
  //     [target.name]: isOK,
  //   });
  // };

  const handleChangeInputLogin = (target, isOK) => {
    setLoginData({
      ...loginData,
      [target.name]: target.value,
    });

    setErrorFreeLogin({
      ...errorFreeLogin,
      [target.name]: isOK,
    });
  };

  const handleSubmit = () => {
    servicesSuperAdmin.login(
      dispatch,
      {
        mail: loginData.login_mail,
        password: loginData.login_password,
      },
      (res) => res === "successful" && navigate("/home-superadmin")
    );
  };

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}
      <Helmet>
        <title>
          {docTitle} | {t("Login Super Admin")}
        </title>
      </Helmet>

      <Header />

      <div className="super_container">
        <section
          className={`super_register_container ${
            modalRegister && "super_register_open"
          }`}
        ></section>

        <div className="super_login_container">
          <h3 className="super_login_title">LOG IN | SUPER ADMINISTRADOR</h3>

          <Input
            id="login_mail"
            nameInput="login_mail"
            type="email"
            placeholder="yourmail@mail.com"
            responseInput={(target, isOK) => {
              handleChangeInputLogin(target, isOK);
            }}
            defaultValue={loginData.login_mail}
            required
            label
            childrenLabel={<ChildrenLabelDefault txt={t("Email")} required />}
            errorMessage={t("form-required")}
          />

          <Input
            id="login_password"
            nameInput="login_password"
            type="password"
            placeholder="******"
            responseInput={(target, isOK) => {
              handleChangeInputLogin(target, isOK);
            }}
            defaultValue={loginData.login_password}
            required
            label
            childrenLabel={
              <ChildrenLabelDefault txt={t("Passwort")} required />
            }
            errorMessage={t("form-required")}
          />

          <Button title="Log In" onclick={handleSubmit} />

          {/* <div className="super_line" />

          <button
            className="super_btn_new_account"
            onClick={() => setModalRegister(true)}
          >
            <p>Create a new account</p>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default LoginSuperAdmin;
