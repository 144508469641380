/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import assets from "../assets/assets";
// ---------------------------------------------------------

export const option_choose = "choose";

// export const experienceOptions = [
//   {
//     name: "form-please-choose-option",
//     value: option_choose,
//   },
//   {
//     name: "form-applicant-step-2-experience-option-1",
//     value: "option-1",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-2",
//     value: "option-2",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-3",
//     value: "option-3",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-4",
//     value: "option-4",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-5",
//     value: "option-5",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-6",
//     value: "option-6",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-7",
//     value: "option-7",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-8",
//     value: "option-8",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-9",
//     value: "option-9",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-10",
//     value: "option-10",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-11",
//     value: "option-11",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-12",
//     value: "option-12",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-13",
//     value: "option-13",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-14",
//     value: "option-14",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-15",
//     value: "option-15",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-16",
//     value: "option-16",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-17",
//     value: "option-17",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-18",
//     value: "option-18",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-19",
//     value: "option-19",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-20",
//     value: "option-20",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-21",
//     value: "option-21",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-22",
//     value: "option-22",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-23",
//     value: "option-23",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-24",
//     value: "option-24",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-25",
//     value: "option-25",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-26",
//     value: "option-26",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-27",
//     value: "option-27",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-28",
//     value: "option-28",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-29",
//     value: "option-29",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-30",
//     value: "option-30",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-31",
//     value: "option-31",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-32",
//     value: "option-32",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-33",
//     value: "option-33",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-34",
//     value: "option-34",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-35",
//     value: "option-35",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-36",
//     value: "option-36",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-37",
//     value: "option-37",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-38",
//     value: "option-38",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-39",
//     value: "option-39",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-40",
//     value: "option-40",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-41",
//     value: "option-41",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-42",
//     value: "option-42",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-43",
//     value: "option-43",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-44",
//     value: "option-44",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-45",
//     value: "option-45",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-46",
//     value: "option-46",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-47",
//     value: "option-47",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-48",
//     value: "option-48",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-49",
//     value: "option-49",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-50",
//     value: "option-50",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-51",
//     value: "option-51",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-52",
//     value: "option-52",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-53",
//     value: "option-53",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-54",
//     value: "option-54",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-55",
//     value: "option-55",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-56",
//     value: "option-56",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-57",
//     value: "option-57",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-58",
//     value: "option-58",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-59",
//     value: "option-59",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-60",
//     value: "option-60",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-61",
//     value: "option-61",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-62",
//     value: "option-62",
//   },
//   {
//     name: "form-applicant-step-2-experience-option-63",
//     value: "option-63",
//   },
// ];

export const experienceOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "Elektroanlagenmonteur/in",
    value: "option-1",
  },
  {
    name: "Elektromaschinenbauer/in",
    value: "option-2",
  },
  {
    name: "Systemelektroniker/in",
    value: "option-3",
  },
  {
    name: "Informationselektroniker/in",
    value: "option-4",
  },
  {
    name: "Industrieelektriker/in",
    value: "option-5",
  },
  {
    name: "Mechatroniker/in",
    value: "option-6",
  },
  {
    name: "Maurer/in",
    value: "option-7",
  },
  {
    name: "Wärme-, Kälte- und Schallschutzisolierer/in",
    value: "option-8",
  },
  {
    name: "Dachdecker/in",
    value: "option-9",
  },
  {
    name: "Straßenbauer/in",
    value: "option-10",
  },
  {
    name: "Tiefbaufacharbeiter/in",
    value: "option-11",
  },
  {
    name: "Zimmerer/Zimmerin",
    value: "option-12",
  },
  {
    name: "Tischler/in (Schreiner/in)",
    value: "option-13",
  },
  {
    name: "Holzmechaniker/ Holzmechanikerin",
    value: "option-14",
  },
  {
    name: "Parkettleger/Parkettlege-rin",
    value: "option-15",
  },
  {
    name: "Drechsler/Drechslerin",
    value: "option-16",
  },
  {
    name: "Holzbildhauer/ Holzbildhauerin",
    value: "option-17",
  },
  {
    name: "Beton- und Stahlbeton-bauer/in",
    value: "option-18",
  },
  {
    name: "Maler/in und Lackierer/in",
    value: "option-19",
  },
  {
    name: "Luftheizungs-bauer/in",
    value: "option-20",
  },
  {
    name: "Bodenleger/in",
    value: "option-21",
  },
  {
    name: "Gerüstbauer/in",
    value: "option-22",
  },
  {
    name: "Metallbauer/in",
    value: "option-23",
  },
  {
    name: "Feinwerkmechaniker/in",
    value: "option-24",
  },
  {
    name: "Anlagenmechaniker/in",
    value: "option-25",
  },
  {
    name: "Zerspanungsmechaniker/ in",
    value: "option-26",
  },
  {
    name: "Industriemechaniker/in",
    value: "option-27",
  },
  {
    name: "Metallbildner/in",
    value: "option-28",
  },
  {
    name: "Bauschlosser/in",
    value: "option-29",
  },
  {
    name: "Karosserie- und",
    value: "option-30",
  },
  {
    name: "Fahrzeugbaumechaniker/in",
    value: "option-31",
  },
];

export const studiesOptions = [
  {
    name: "Quereinsteiger",
    value: "lateral",
    icon: assets.icons.iconNo,
  },
  {
    name: "Geselle",
    value: "official",
    icon: assets.icons.iconOfficial,
  },
  {
    name: "Meister",
    value: "master",
    icon: assets.icons.iconCongratulation,
  },
];

export const arrivalOptions = [
  {
    name: "TikTok",
    value: "tiktok",
    icon: assets.icons.iconTiktok,
  },
  {
    name: "Facebook",
    value: "facebook",
    icon: assets.icons.iconFacebook,
  },
  {
    name: "Instagram",
    value: "instagram",
    icon: assets.icons.iconInstagram,
  },
  {
    name: "Zeitung",
    value: "newspaper",
    icon: assets.icons.iconNewspaper,
  },
  {
    name: "Weiter Empfehlung",
    value: "recommended",
    icon: assets.icons.iconRecommended,
  },
  {
    name: "Flyer",
    value: "flyer",
    icon: assets.icons.iconFlyer,
  },
  {
    name: "Sonstiges",
    value: "etc",
    icon: assets.icons.iconEtc,
  },
];

export const timeExpOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-3-time-exp-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-3-time-exp-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-3-time-exp-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-3-time-exp-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-3-time-exp-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-3-time-exp-option-6",
    value: "option-6",
  },
];

export const travelOptions = [
  // {
  //   name: "form-please-choose-option",
  //   value: option_choose,
  // },
  {
    name: "form-applicant-step-3-travel-option-1",
    value: "option-1",
    icon: assets.icons.iconBenefitsHomeoffice,
  },
  {
    name: "form-applicant-step-3-travel-option-2",
    value: "option-2",
    icon: assets.icons.iconBenefitsLittleTraveling,
  },
  {
    name: "form-applicant-step-3-travel-option-3",
    value: "option-3",
    icon: assets.icons.iconBenefitsMediumTraveling,
  },
  {
    name: "form-applicant-step-3-travel-option-4",
    value: "option-4",
    icon: assets.icons.iconBenefitsLotTraveling,
  },
  // {
  //   name: "form-applicant-step-3-travel-option-5",
  //   value: "option-5",
  // },
];

export const lngPrefOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-3-lngpref-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-3-lngpref-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-3-lngpref-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-3-lngpref-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-3-lngpref-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-3-lngpref-option-6",
    value: "option-6",
  },
  {
    name: "form-applicant-step-3-lngpref-option-7",
    value: "option-7",
  },
  {
    name: "form-applicant-step-3-lngpref-option-8",
    value: "option-8",
  },
  {
    name: "form-applicant-step-3-lngpref-option-9",
    value: "option-9",
  },
  {
    name: "form-applicant-step-3-lngpref-option-10",
    value: "option-10",
  },
  {
    name: "form-applicant-step-3-lngpref-option-11",
    value: "option-11",
  },
  {
    name: "form-applicant-step-3-lngpref-option-12",
    value: "option-12",
  },
  {
    name: "form-applicant-step-3-lngpref-option-13",
    value: "option-13",
  },
  {
    name: "form-applicant-step-3-lngpref-option-14",
    value: "option-14",
  },
  {
    name: "form-applicant-step-3-lngpref-option-15",
    value: "option-15",
  },
  {
    name: "form-applicant-step-3-lngpref-option-16",
    value: "option-16",
  },
  {
    name: "form-applicant-step-3-lngpref-option-17",
    value: "option-17",
  },
  {
    name: "form-applicant-step-3-lngpref-option-18",
    value: "option-18",
  },
  {
    name: "form-applicant-step-3-lngpref-option-19",
    value: "option-19",
  },
  {
    name: "form-applicant-step-3-lngpref-option-20",
    value: "option-20",
  },
];

export const okOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-ok-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-ok-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-ok-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-ok-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-ok-option-5",
    value: "option-5",
  },
];

export const adjetivesOptions = [
  {
    name: "form-applicant-step-2-adjective-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-2-adjective-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-2-adjective-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-2-adjective-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-2-adjective-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-2-adjective-option-6",
    value: "option-6",
  },
  {
    name: "form-applicant-step-2-adjective-option-7",
    value: "option-7",
  },
  {
    name: "form-applicant-step-2-adjective-option-8",
    value: "option-8",
  },
  {
    name: "form-applicant-step-2-adjective-option-9",
    value: "option-9",
  },
  {
    name: "form-applicant-step-2-adjective-option-10",
    value: "option-10",
  },
  {
    name: "form-applicant-step-2-adjective-option-11",
    value: "option-11",
  },
  {
    name: "form-applicant-step-2-adjective-option-12",
    value: "option-12",
  },
  {
    name: "form-applicant-step-2-adjective-option-13",
    value: "option-13",
  },
  {
    name: "form-applicant-step-2-adjective-option-14",
    value: "option-14",
  },
  {
    name: "form-applicant-step-2-adjective-option-15",
    value: "option-15",
  },
  {
    name: "form-applicant-step-2-adjective-option-16",
    value: "option-16",
  },
  {
    name: "form-applicant-step-2-adjective-option-17",
    value: "option-17",
  },
  {
    name: "form-applicant-step-2-adjective-option-18",
    value: "option-18",
  },
  {
    name: "form-applicant-step-2-adjective-option-19",
    value: "option-19",
  },
  {
    name: "form-applicant-step-2-adjective-option-20",
    value: "option-20",
  },
  {
    name: "form-applicant-step-2-adjective-option-21",
    value: "option-21",
  },
  {
    name: "form-applicant-step-2-adjective-option-22",
    value: "option-22",
  },
  {
    name: "form-applicant-step-2-adjective-option-23",
    value: "option-23",
  },
  {
    name: "form-applicant-step-2-adjective-option-24",
    value: "option-24",
  },
  {
    name: "form-applicant-step-2-adjective-option-25",
    value: "option-25",
  },
  {
    name: "form-applicant-step-2-adjective-option-26",
    value: "option-26",
  },
  {
    name: "form-applicant-step-2-adjective-option-27",
    value: "option-27",
  },
  {
    name: "form-applicant-step-2-adjective-option-28",
    value: "option-28",
  },
  {
    name: "form-applicant-step-2-adjective-option-29",
    value: "option-29",
  },
  {
    name: "form-applicant-step-2-adjective-option-30",
    value: "option-30",
  },
  {
    name: "form-applicant-step-2-adjective-option-31",
    value: "option-31",
  },
  {
    name: "form-applicant-step-2-adjective-option-32",
    value: "option-32",
  },
  {
    name: "form-applicant-step-2-adjective-option-33",
    value: "option-33",
  },
  {
    name: "form-applicant-step-2-adjective-option-34",
    value: "option-34",
  },
  {
    name: "form-applicant-step-2-adjective-option-35",
    value: "option-35",
  },
  {
    name: "form-applicant-step-2-adjective-option-36",
    value: "option-36",
  },
  {
    name: "form-applicant-step-2-adjective-option-37",
    value: "option-37",
  },
  {
    name: "form-applicant-step-2-adjective-option-38",
    value: "option-38",
  },
  {
    name: "form-applicant-step-2-adjective-option-39",
    value: "option-39",
  },
  {
    name: "form-applicant-step-2-adjective-option-40",
    value: "option-40",
  },
];

export const additionalServices = [
  {
    name: "form-applicant-step-3-additional-option-1",
    value: "option-1",
    icon: assets.icons.iconBenefitsBalcony,
  },
  {
    name: "form-applicant-step-3-additional-option-2",
    value: "option-2",
    icon: assets.icons.iconBenefitsAccessibility,
  },
  {
    name: "form-applicant-step-3-additional-option-3",
    value: "option-3",
    icon: assets.icons.iconBenefitsMoreMoney,
  },
  {
    name: "form-applicant-step-3-additional-option-4",
    value: "option-4",
    icon: assets.icons.iconBenefitsAccessibility,
  },
  {
    name: "form-applicant-step-3-additional-option-5",
    value: "option-5",
    icon: assets.icons.iconBenefitsWork,
  },
  {
    name: "form-applicant-step-3-additional-option-6",
    value: "option-6",
    icon: assets.icons.iconBenefitsBenefit,
  },
  {
    name: "form-applicant-step-3-additional-option-7",
    value: "option-7",
    icon: assets.icons.iconBenefitsEvents,
  },
  {
    name: "form-applicant-step-3-additional-option-8",
    value: "option-8",
    icon: assets.icons.iconBenefitsPhone,
  },
  {
    name: "form-applicant-step-3-additional-option-9",
    value: "option-9",
    icon: assets.icons.iconBenefitsGym,
  },
  {
    name: "form-applicant-step-3-additional-option-10",
    value: "option-10",
    icon: assets.icons.iconBenefitsHierarchy,
  },
  {
    name: "form-applicant-step-3-additional-option-11",
    value: "option-11",
    icon: assets.icons.iconBenefitsFlexible,
  },
  {
    name: "form-applicant-step-3-additional-option-12",
    value: "option-12",
    icon: assets.icons.iconBenefitsFree,
  },
  {
    name: "form-applicant-step-3-additional-option-13",
    value: "option-13",
    icon: assets.icons.iconBenefitsHomeoffice,
  },
  {
    name: "form-applicant-step-3-additional-option-14",
    value: "option-14",
    icon: assets.icons.iconBenefitsClothes,
  },
  {
    name: "form-applicant-step-3-additional-option-15",
    value: "option-15",
    icon: assets.icons.iconBenefitsMoreMoney,
  },
  {
    name: "form-applicant-step-3-additional-option-16",
    value: "option-16",
    icon: assets.icons.iconBenefitsComputer,
  },
  {
    name: "form-applicant-step-3-additional-option-17",
    value: "option-17",
    icon: assets.icons.iconBenefitsSavings,
  },
  {
    name: "form-applicant-step-3-additional-option-18",
    value: "option-18",
    icon: assets.icons.iconBenefitsCleaning,
  },
  {
    name: "form-applicant-step-3-additional-option-19",
    value: "option-19",
    icon: assets.icons.iconBenefitsRest,
  },
  {
    name: "form-applicant-step-3-additional-option-20",
    value: "option-20",
    icon: assets.icons.iconBenefitsTeam,
  },
  {
    name: "form-applicant-step-3-additional-option-21",
    value: "option-21",
    icon: assets.icons.iconBenefitsTraining,
  },
  {
    name: "form-applicant-step-3-additional-option-22",
    value: "option-22",
    icon: assets.icons.iconBenefitsWork,
  },
  {
    name: "form-applicant-step-3-additional-option-23",
    value: "option-23",
    icon: assets.icons.iconBenefitsMoreMoney,
  },
  {
    name: "form-applicant-step-3-additional-option-24",
    value: "option-24",
    icon: assets.icons.iconBenefitsMoreMoney,
  },
  {
    name: "form-applicant-step-3-additional-option-25",
    value: "option-25",
    icon: assets.icons.iconBenefitsVacation,
  },
  {
    name: "form-applicant-step-3-additional-option-26",
    value: "option-26",
    icon: assets.icons.iconBenefitsLicense,
  },
  {
    name: "form-applicant-step-3-additional-option-27",
    value: "option-27",
    icon: assets.icons.iconBenefitsSure,
  },
  {
    name: "form-applicant-step-3-additional-option-28",
    value: "option-28",
    icon: assets.icons.iconBenefitsMoreMoney,
  },
  {
    name: "form-applicant-step-3-additional-option-29",
    value: "option-29",
    icon: assets.icons.iconBenefitsDoctor,
  },
  {
    name: "form-applicant-step-3-additional-option-30",
    value: "option-30",
    icon: assets.icons.iconBenefitsErgonomic,
  },
  {
    name: "form-applicant-step-3-additional-option-31",
    value: "option-31",
    icon: assets.icons.iconBenefitsFitness,
  },
  {
    name: "form-applicant-step-3-additional-option-32",
    value: "option-32",
    icon: assets.icons.iconBenefitsHealth,
  },
  {
    name: "form-applicant-step-3-additional-option-33",
    value: "option-33",
    icon: assets.icons.iconBenefitsDoctor,
  },
  {
    name: "form-applicant-step-3-additional-option-34",
    value: "option-34",
    icon: assets.icons.iconBenefitsSport,
  },
  {
    name: "form-applicant-step-3-additional-option-35",
    value: "option-35",
    icon: assets.icons.iconBenefitsExams,
  },
  {
    name: "form-applicant-step-3-additional-option-36",
    value: "option-36",
    icon: assets.icons.iconBenefitsDental,
  },
  {
    name: "form-applicant-step-3-additional-option-37",
    value: "option-37",
    icon: assets.icons.iconBenefitsDiningRoom,
  },
  {
    name: "form-applicant-step-3-additional-option-38",
    value: "option-38",
    icon: assets.icons.iconBenefitsKitchen,
  },
  {
    name: "form-applicant-step-3-additional-option-39",
    value: "option-39",
    icon: assets.icons.iconBenefitsConsignment,
  },
  {
    name: "form-applicant-step-3-additional-option-40",
    value: "option-40",
    icon: assets.icons.iconBenefitsFruit,
  },
  {
    name: "form-applicant-step-3-additional-option-41",
    value: "option-41",
    icon: assets.icons.iconBenefitsDrink,
  },
  {
    name: "form-applicant-step-3-additional-option-42",
    value: "option-42",
    icon: assets.icons.iconBenefitsEducation,
  },
  {
    name: "form-applicant-step-3-additional-option-43",
    value: "option-43",
    icon: assets.icons.iconBenefitsOffers,
  },
  {
    name: "form-applicant-step-3-additional-option-44",
    value: "option-44",
    icon: assets.icons.iconBenefitsOpportunities,
  },
  {
    name: "form-applicant-step-3-additional-option-45",
    value: "option-45",
    icon: assets.icons.iconBenefitsFinancing,
  },
  {
    name: "form-applicant-step-3-additional-option-46",
    value: "option-46",
    icon: assets.icons.iconBenefitsEducation,
  },
  {
    name: "form-applicant-step-3-additional-option-47",
    value: "option-47",
    icon: assets.icons.iconBenefitsBicycle,
  },
  {
    name: "form-applicant-step-3-additional-option-48",
    value: "option-48",
    icon: assets.icons.iconBenefitsTicket,
  },
  {
    name: "form-applicant-step-3-additional-option-49",
    value: "option-49",
    icon: assets.icons.iconBenefitsCar,
  },
  {
    name: "form-applicant-step-3-additional-option-50",
    value: "option-50",
    icon: assets.icons.iconTruck,
  },
  {
    name: "form-applicant-step-3-additional-option-51",
    value: "option-51",
    icon: assets.icons.iconBenefitsSubsidy,
  },
  {
    name: "form-applicant-step-3-additional-option-52",
    value: "option-52",
    icon: assets.icons.iconBenefitsParking,
  },
  {
    name: "form-applicant-step-3-additional-option-53",
    value: "option-53",
    icon: assets.icons.iconBenefitsCard,
  },
  {
    name: "form-applicant-step-3-additional-option-54",
    value: "option-54",
    icon: assets.icons.iconBenefitsKindergarten,
  },
  {
    name: "form-applicant-step-3-additional-option-55",
    value: "option-55",
    icon: assets.icons.iconBenefitsDog,
  },
  {
    name: "form-applicant-step-3-additional-option-56",
    value: "option-56",
    icon: assets.icons.iconBenefitsKindergarten,
  },
  {
    name: "form-applicant-step-3-additional-option-57",
    value: "option-57",
    icon: assets.icons.iconBenefitsFaceMask,
  },
  {
    name: "form-applicant-step-3-additional-option-58",
    value: "option-58",
    icon: assets.icons.iconBenefitsLocation,
  },
  {
    name: "form-applicant-step-3-additional-option-59",
    value: "option-59",
    icon: assets.icons.iconBenefitsFitness,
  },
  {
    name: "form-applicant-step-3-additional-option-60",
    value: "option-60",
    icon: assets.icons.iconBenefitsHealth,
  },
];

export const licenseOptions = [
  {
    name: "form-applicant-step-3-license-no",
    value: "no",
    // icon: require(),
  },
  {
    name: "form-applicant-step-3-license-class-b",
    value: "class-b",
    icon: assets.icons.iconCar,
  },
  {
    name: "form-applicant-step-3-license-class-b-c",
    value: "class-b-c",
    icon: assets.icons.iconTruck,
  },
];

export const genderOptions = [
  {
    name: "form-gender-male",
    value: "male",
  },
  {
    name: "form-gender-female",
    value: "female",
  },
  {
    name: "form-gender-other",
    value: "other",
  },
];

export const availableOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "Ab Sofort",
    value: "option-1",
  },
  {
    name: "1-2 Wochen",
    value: "option-2",
  },
  {
    name: "2-4 Wochen",
    value: "option-3",
  },
  {
    name: "4-8 Wochen",
    value: "option-4",
  },
  {
    name: "8-12 Wochen",
    value: "option-5",
  },
];

export const registrationTimeOptions = [
  {
    name: "admin-company-filter-registration-time-1",
    value: 1,
  },
  {
    name: "admin-company-filter-registration-time-2",
    value: 2,
  },
  {
    name: "admin-company-filter-registration-time-3",
    value: 3,
  },
  {
    name: "admin-company-filter-registration-time-4",
    value: 4,
  },
];
