/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

const getURLAsset = (route = "", nameImg = "") => {
  if (!!!nameImg) {
    return console.error("Se espera un nombre de imagen");
  } else {
    let subpath = route === "" ? "" : "/";
    return `https://www.companyu.de/assets/${route}${subpath}${nameImg}`;
  }
};

const assets = {
  brand: {
    logoColorBgTransparent: getURLAsset(
      "brand",
      "logo-color--bg-transparent.png"
    ),
  },
  icons: {
    prewProfileImg: getURLAsset("icons", "prewProfileImg.png"),
    iconTruck: getURLAsset("icons", "icon-truck.png"),
    iconTime212121: getURLAsset("icons", "icon-time-212121.png"),
    iconTiktok: getURLAsset("icons", "icon-tiktok.png"),
    iconSending: getURLAsset("icons", "icon-sending.png"),
    iconSend: getURLAsset("icons", "icon-send.png"),
    iconRegisterCompany: getURLAsset("icons", "icon-register-company.png"),
    iconRegisterApplicant: getURLAsset("icons", "icon-register-applicant.png"),
    iconRecommended: getURLAsset("icons", "icon-recommended.png"),
    iconQualification212121: getURLAsset(
      "icons",
      "icon-qualification-212121.png"
    ),
    iconPadlock: getURLAsset("icons", "icon-padlock.png"),
    iconOfficial: getURLAsset("icons", "icon-official.png"),
    iconNo: getURLAsset("icons", "icon-no.png"),
    iconNext: getURLAsset("icons", "icon-next.png"),
    iconNewspaper: getURLAsset("icons", "icon-newspaper.png"),
    iconLocation212121: getURLAsset("icons", "icon-location-212121.png"),
    iconInstagram: getURLAsset("icons", "icon-instagram.png"),
    iconFlyer: getURLAsset("icons", "icon-flyer.png"),
    iconFilter: getURLAsset("icons", "icon-filter.png"),
    iconFacebook: getURLAsset("icons", "icon-facebook.png"),
    iconEtc: getURLAsset("icons", "icon-etc.png"),
    iconDadv: getURLAsset("icons", "icon-dadv.png"),
    iconContact: getURLAsset("icons", "icon-contact.png"),
    iconCongratulation: getURLAsset("icons", "icon-congratulation.png"),
    iconCompanya6a6a6: getURLAsset("icons", "icon-company-a6a6a6.png"),
    iconChoose: getURLAsset("icons", "icon-choose.png"),
    iconCar: getURLAsset("icons", "icon-car.png"),
    iconBack: getURLAsset("icons", "icon-back.png"),
    iconAdv: getURLAsset("icons", "icon-adv.png"),
    iconBonusLess: getURLAsset("icons/icons-bonus", "less-.png"),
    iconBonusWork: getURLAsset("icons/icons-bonus", "icon-work.png"),
    iconBonusSaveTime: getURLAsset("icons/icons-bonus", "icon-save-time.png"),
    iconBonusMoreMoney: getURLAsset("icons/icons-bonus", "icon-more-money.png"),
    iconBonusLessStress: getURLAsset(
      "icons/icons-bonus",
      "icon-less-stress.png"
    ),
    iconBonusLessInterviews: getURLAsset(
      "icons/icons-bonus",
      "icon-less-interviews.png"
    ),
    iconBonusFree: getURLAsset("icons/icons-bonus", "icon-free.png"),
    iconBonusCool: getURLAsset("icons/icons-bonus", "icon-cool.png"),
    iconBonusActivity: getURLAsset("icons/icons-bonus", "icon-activity.png"),
    iconBenefitsWork: getURLAsset("icons/icons-benefits", "icon-work.png"),
    iconBenefitsVacation: getURLAsset(
      "icons/icons-benefits",
      "icon-vacation.png"
    ),
    iconBenefitsTruck: getURLAsset("icons/icons-benefits", "icon-truck.png"),
    iconBenefitsTraining: getURLAsset(
      "icons/icons-benefits",
      "icon-training.png"
    ),
    iconBenefitsTicket: getURLAsset("icons/icons-benefits", "icon-ticket.png"),
    iconBenefitsTeam: getURLAsset("icons/icons-benefits", "icon-team.png"),
    iconBenefitsSure: getURLAsset("icons/icons-benefits", "icon-sure.png"),
    iconBenefitsSubsidy: getURLAsset(
      "icons/icons-benefits",
      "icon-subsidy.png"
    ),
    iconBenefitsSport: getURLAsset("icons/icons-benefits", "icon-sport.png"),
    iconBenefitsSavings: getURLAsset(
      "icons/icons-benefits",
      "icon-savings.png"
    ),
    iconBenefitsRest: getURLAsset("icons/icons-benefits", "icon-rest.png"),
    iconBenefitsPhone: getURLAsset("icons/icons-benefits", "icon-phone.png"),
    iconBenefitsParking: getURLAsset(
      "icons/icons-benefits",
      "icon-parking.png"
    ),
    iconBenefitsOpportunities: getURLAsset(
      "icons/icons-benefits",
      "icon-opportunities.png"
    ),
    iconBenefitsOffers: getURLAsset("icons/icons-benefits", "icon-offers.png"),
    iconBenefitsMoreMoney: getURLAsset(
      "icons/icons-benefits",
      "icon-more-money.png"
    ),
    iconBenefitsMediumTraveling: getURLAsset(
      "icons/icons-benefits",
      "icon-medium-traveling.png"
    ),
    iconBenefitsLotTraveling: getURLAsset(
      "icons/icons-benefits",
      "icon-lot-traveling.png"
    ),
    iconBenefitsLocation: getURLAsset(
      "icons/icons-benefits",
      "icon-location.png"
    ),
    iconBenefitsLittleTraveling: getURLAsset(
      "icons/icons-benefits",
      "icon-little-traveling.png"
    ),
    iconBenefitsLicense: getURLAsset(
      "icons/icons-benefits",
      "icon-license.png"
    ),
    iconBenefitsKitchen: getURLAsset(
      "icons/icons-benefits",
      "icon-kitchen.png"
    ),
    iconBenefitsKindergarten: getURLAsset(
      "icons/icons-benefits",
      "icon-kindergarten.png"
    ),
    iconBenefitsHomeoffice: getURLAsset(
      "icons/icons-benefits",
      "icon-homeoffice.png"
    ),
    iconBenefitsHierarchy: getURLAsset(
      "icons/icons-benefits",
      "icon-hierarchy.png"
    ),
    iconBenefitsHealth: getURLAsset("icons/icons-benefits", "icon-health.png"),
    iconBenefitsGym: getURLAsset("icons/icons-benefits", "icon-gym.png"),
    iconBenefitsFruit: getURLAsset("icons/icons-benefits", "icon-fruit.png"),
    iconBenefitsFree: getURLAsset("icons/icons-benefits", "icon-free.png"),
    iconBenefitsFlexible: getURLAsset(
      "icons/icons-benefits",
      "icon-flexible.png"
    ),
    iconBenefitsFitness: getURLAsset(
      "icons/icons-benefits",
      "icon-fitness.png"
    ),
    iconBenefitsFinancing: getURLAsset(
      "icons/icons-benefits",
      "icon-financing.png"
    ),
    iconBenefitsFaceMask: getURLAsset(
      "icons/icons-benefits",
      "icon-face-mask.png"
    ),
    iconBenefitsExams: getURLAsset("icons/icons-benefits", "icon-exams.png"),
    iconBenefitsEvents: getURLAsset("icons/icons-benefits", "icon-events.png"),
    iconBenefitsErgonomic: getURLAsset(
      "icons/icons-benefits",
      "icon-ergonomic.png"
    ),
    iconBenefitsEducation: getURLAsset(
      "icons/icons-benefits",
      "icon-education.png"
    ),
    iconBenefitsDrink: getURLAsset("icons/icons-benefits", "icon-drink.png"),
    iconBenefitsDog: getURLAsset("icons/icons-benefits", "icon-dog.png"),
    iconBenefitsDoctor: getURLAsset("icons/icons-benefits", "icon-doctor.png"),
    iconBenefitsDiningRoom: getURLAsset(
      "icons/icons-benefits",
      "icon-dining-room.png"
    ),
    iconBenefitsDental: getURLAsset("icons/icons-benefits", "icon-dental.png"),
    iconBenefitsConsignment: getURLAsset(
      "icons/icons-benefits",
      "icon-consignment.png"
    ),
    iconBenefitsComputer: getURLAsset(
      "icons/icons-benefits",
      "icon-computer.png"
    ),
    iconBenefitsClothes: getURLAsset(
      "icons/icons-benefits",
      "icon-clothes.png"
    ),
    iconBenefitsCleaning: getURLAsset(
      "icons/icons-benefits",
      "icon-cleaning.png"
    ),
    iconBenefitsCard: getURLAsset("icons/icons-benefits", "icon-card.png"),
    iconBenefitsCar: getURLAsset("icons/icons-benefits", "icon-car.png"),
    iconBenefitsBicycle: getURLAsset(
      "icons/icons-benefits",
      "icon-bicycle.png"
    ),
    iconBenefitsBenefit: getURLAsset(
      "icons/icons-benefits",
      "icon-benefit.png"
    ),
    iconBenefitsBalcony: getURLAsset(
      "icons/icons-benefits",
      "icon-balcony.png"
    ),
    iconBenefitsAccessibility: getURLAsset(
      "icons/icons-benefits",
      "icon-accessibility.png"
    ),
  },
  imgs: {
    worker4: getURLAsset("imgs", "worker4.png"),
    worker3: getURLAsset("imgs", "worker3.png"),
    worker2: getURLAsset("imgs", "worker2.png"),
    worker1: getURLAsset("imgs", "worker1.png"),
    successful: getURLAsset("imgs", "successful.png"),
    ourPatner: getURLAsset("imgs", "our-patner.png"),
    newspaper2: getURLAsset("imgs", "newspaper2.png"),
    newspaper1: getURLAsset("imgs", "newspaper1.png"),
    Marius: getURLAsset("imgs", "Marius.jpeg"),
    manageSocialMedia: getURLAsset("imgs", "manage-social-media.png"),
    Guido: getURLAsset("imgs", "Guido.jpeg"),
    failed: getURLAsset("imgs", "failed.png"),
    error404: getURLAsset("imgs", "error-404.png"),
    Emilio: getURLAsset("imgs", "Emilio.jpeg"),
    decoration: getURLAsset("imgs", "decoration.png"),
    companyhelp: getURLAsset("imgs", "companyhelp.jpeg"),
    companiesBgCity: getURLAsset("imgs", "companies-bg-city.jpg"),
    companiesBanner: getURLAsset("imgs", "companies-banner.jpeg"),
    bgServiceSocialMedia: getURLAsset("imgs", "bg-service-social-media.png"),
    bannerInfluencer: getURLAsset("imgs", "banner-influencer.jpg"),
    bannerHome: getURLAsset("imgs", "banner-home.jpg"),
    bannerHome2: getURLAsset("imgs", "banner-home.jpeg"),
    bannerCompanies: getURLAsset("imgs", "banner-companies.jpg"),
    bannerApplicants: getURLAsset("imgs", "banner-applicants.jpg"),
    bannerApplicants2: getURLAsset("imgs", "banner-applicants-2.avif"),
    applicantworking: getURLAsset("imgs", "applicantworking.jpeg"),
    applicantsBgCity: getURLAsset("imgs", "applicants-bg-city.jpg"),
    applicantsBgCity2: getURLAsset("imgs", "applicants-bg-city-2.jpg"),
    applicanthappy: getURLAsset("imgs", "applicanthappy.jpeg"),
    houseStep11: getURLAsset("imgs/steps-form-applicant", "house-step-11.png"),
    houseStep10: getURLAsset("imgs/steps-form-applicant", "house-step-10.png"),
    houseStep9: getURLAsset("imgs/steps-form-applicant", "house-step-9.png"),
    houseStep8: getURLAsset("imgs/steps-form-applicant", "house-step-8.png"),
    houseStep7: getURLAsset("imgs/steps-form-applicant", "house-step-7.png"),
    houseStep6: getURLAsset("imgs/steps-form-applicant", "house-step-6.png"),
    houseStep5: getURLAsset("imgs/steps-form-applicant", "house-step-5.png"),
    houseStep4: getURLAsset("imgs/steps-form-applicant", "house-step-4.png"),
    houseStep3: getURLAsset("imgs/steps-form-applicant", "house-step-3.png"),
    houseStep2: getURLAsset("imgs/steps-form-applicant", "house-step-2.png"),
    houseStep1: getURLAsset("imgs/steps-form-applicant", "house-step-1.png"),
  },
};

export default assets;
