/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
// ---------------------------------------------------------
import WebProvider from "./context/Context";
import {
  ProtectedRouteApplicant,
  ProtectedRouteCompany,
  ProtectedRouteInfluencer,
  ProtectedRouteSuperAdmin,
} from "./utils/ProtectedRoute";
import Home from "./screens/home/Home";
import ForApplicants from "./screens/forApplicants/ForApplicants";
import ForCompanies from "./screens/forCompanies/ForCompanies";
import About from "./screens/about/About";
import DataProtection from "./screens/dataprotection/DataProtection";
import Register from "./screens/register/Register";
import FormForApplicant from "./screens/formForApplicant/FormForApplicant";
import FormForCompany from "./screens/formForCompany/FormForCompany";
import FormForInfluencer from "./screens/formForInfluencer/FormForInfluencer";
import PreviewApplicants from "./screens/previewApplicants/PreviewApplicants";
import LoginCompany from "./screens/loginCompany/LoginCompany";
import HomeCompany from "./screens/homeCompany/HomeCompany";
import ApplicantsList from "./screens/applicantsList/ApplicantsList";
import Applicant from "./screens/applicant/Applicant";
import CompanyPackages from "./screens/companyPackages/CompanyPackages";
import Billing from "./screens/billing/Billing";
import BuySuccessful from "./screens/companyPackages/BuySuccessful";
import BuyFailed from "./screens/companyPackages/BuyFailed";
import ConfirmationMail from "./screens/register/ConfirmationMail";
import Error404 from "./screens/error404/Error404";
import Footer from "./components/footer/Footer";
import InConstruction from "./components/inConstruction/InConstruction";
import Login from "./screens/login/Login";
import LoginInfluencer from "./screens/loginInfluencer/LoginInfluencer";
import HomeInfluencer from "./screens/homeInfluencer/HomeInfluencer";
import FAQ from "./screens/faq/FAQ";
import RegisterApplicant from "./screens/register/RegisterApplicant";
import LoginApplicant from "./screens/loginApplicant/LoginApplicant";
import HomeApplicant from "./screens/homeApplicant/HomeApplicant";
import GainsInfluencer from "./screens/GainsInfluencer/GainsInfluencer";
import LoginSuperAdmin from "./screens/superadmin/LoginSuperAdmin";
import HomeSuperAdmin from "./screens/superadmin/HomeSuperAdmin";
import TestForm from "./screens/testForm/TestForm";
import Services from "./screens/services/Services";
import { getArrWorksHomeScreen } from "./screens/home/Home.services";
import WorkScreen from "./screens/works/WorkScreen";
import { formatedSubpathWork } from "./utils/Helpers";
// ---------------------------------------------------------

const App = () => {
  const brand = "CompanyU";

  return (
    <WebProvider>
      <Router basename="/">
        <Routes>
          <Route exact path="/" element={<Home docTitle={`${brand}`} />} />

          <Route
            exact
            path="/for-applicants"
            element={<ForApplicants docTitle={`${brand}`} />} /* Für Bewerber */
          />

          <Route
            exact
            path="/for-companies"
            element={
              <ForCompanies docTitle={`${brand}`} />
            } /* Für Unternehmen */
          />

          <Route
            exact
            path="/about"
            element={<About docTitle={`${brand}`} />} /* About und Impressum */
          />

          <Route
            exact
            path="/data-protection"
            element={
              <DataProtection docTitle={`${brand}`} />
            } /* Data Protection */
          />

          <Route
            exact
            path="/faq"
            element={<FAQ docTitle={`${brand}`} />} /* Data Protection */
          />

          <Route
            exact
            path="/register"
            element={<Register docTitle={`${brand}`} />} /* Zur Registrierung */
          />

          <Route
            exact
            path="/register-applicant"
            element={
              <RegisterApplicant docTitle={`${brand}`} />
            } /* Form for applicants */
          />

          <Route
            exact
            path="/update-applicant"
            element={
              <ProtectedRouteApplicant>
                <FormForApplicant docTitle={`${brand}`} />
              </ProtectedRouteApplicant>
            } /* Form for applicants */
          />

          <Route
            exact
            path="/register-company"
            element={
              <FormForCompany docTitle={`${brand}`} />
            } /* Form for company */
          />

          <Route
            exact
            path="/register-associated"
            element={
              <FormForInfluencer docTitle={`${brand}`} />
            } /* Form for company */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/preview-applicants"
            element={
              <PreviewApplicants docTitle={`${brand}`} />
            } /* Preview Applicants */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/login"
            element={<Login docTitle={`${brand}`} />}
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/login-applicant"
            element={<LoginApplicant docTitle={`${brand}`} />}
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/login-company"
            element={<LoginCompany docTitle={`${brand}`} />}
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/login-associated"
            element={<LoginInfluencer docTitle={`${brand}`} />}
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/home-applicant"
            element={
              <ProtectedRouteApplicant>
                <HomeApplicant docTitle={`${brand}`} />
              </ProtectedRouteApplicant>
            }
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/home-company"
            element={
              <ProtectedRouteCompany>
                <HomeCompany docTitle={`${brand}`} />
              </ProtectedRouteCompany>
            }
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/home-associated"
            element={
              <ProtectedRouteInfluencer>
                <HomeInfluencer docTitle={`${brand}`} />
              </ProtectedRouteInfluencer>
            }
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/gains-associated"
            element={
              <ProtectedRouteInfluencer>
                <GainsInfluencer docTitle={`${brand}`} />
              </ProtectedRouteInfluencer>
            }
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/applicants-list"
            element={
              <ProtectedRouteCompany>
                <ApplicantsList docTitle={`${brand}`} />
              </ProtectedRouteCompany>
            } /* View Applicant */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/applicant/:id"
            element={
              <ProtectedRouteCompany>
                <Applicant docTitle={`${brand}`} />
              </ProtectedRouteCompany>
            } /* View Applicant */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/company-packages"
            element={
              <ProtectedRouteCompany>
                <CompanyPackages docTitle={`${brand}`} />
              </ProtectedRouteCompany>
            } /* View Applicant */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/billing"
            element={
              <ProtectedRouteCompany>
                <Billing docTitle={`${brand}`} />
              </ProtectedRouteCompany>
            } /* Billing */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/buy-successful"
            element={
              <BuySuccessful docTitle={`${brand}`} />
            } /* Buy Successful */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/buy-failed"
            element={<BuyFailed docTitle={`${brand}`} />} /* Buy Failed */
            // element={<InConstruction />}
          />

          <Route
            exact
            path="/confirmation-mail"
            element={
              <ConfirmationMail docTitle={`${brand}`} />
            } /* Buy Failed */
            // element={<InConstruction />}
          />

          <Route
            path="/login-superadmin"
            element={<LoginSuperAdmin docTitle={`${brand}`} />}
          />

          <Route
            path="/home-superadmin"
            element={
              <ProtectedRouteSuperAdmin>
                <HomeSuperAdmin />
              </ProtectedRouteSuperAdmin>
            }
          />

          <Route
            path="/services"
            element={<Services docTitle={`${brand}`} />}
          />

          <Route
            path={`offer-work`}
            element={<WorkScreen docTitle={`${brand}`} />}
          />

          <Route path="/crmform" element={<TestForm docTitle={`${brand}`} />} />

          <Route path="/Home.html" element={<Navigate to="/" />} />

          <Route path="*" element={<Error404 docTitle={`${brand} `} />} />
        </Routes>

        <Footer />
      </Router>
    </WebProvider>
  );
};

export default App;
