/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../paragraph/Paragraph";
// ---------------------------------------------------------

const LoadingTab = () => {
  return (
    <>
      {/* Class names starting with "ldt" refer to the file: LoadingTab.css */}
      <div className="ldt_container">
        <Paragraph>{t("loading-tab-txt")}</Paragraph>
      </div>
    </>
  );
};

export default LoadingTab;
