/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./Register.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../../components/paragraph/Paragraph";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const Register = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "reg" refer to the file: Register.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-register")}
        </title>
      </Helmet>

      <Header />

      <div className="reg_header_container">
        <Paragraph
          otherClassNameTitle="reg_header_title"
          withTitle
          title={"Account"}
        />
      </div>

      <nav className="reg_navregister_container">
        <div className="reg_btns_container">
          <Link className="reg_btn" to="/login-applicant" target="_top">
            <div className="reg_btn_info_container">
              <img
                src={assets.icons.iconRegisterApplicant}
                alt="Icon Log In applicant"
                className="reg_btn_info_img"
              />

              <p>Log In</p>
            </div>

            <div className="reg_btn_img_container">
              <img
                src={assets.imgs.bannerApplicants}
                alt="Register applicants"
                className="reg_btn_img_img"
              />
            </div>
          </Link>

          <Link className="reg_btn" to="/register-applicant" target="_top">
            <div className="reg_btn_info_container">
              <img
                src={assets.icons.iconRegisterApplicant}
                alt="Icon Log In applicant"
                className="reg_btn_info_img"
              />

              <p>Register</p>
            </div>

            <div className="reg_btn_img_container">
              <img
                src={assets.imgs.companyhelp}
                alt="Register applicants"
                className="reg_btn_img_img"
              />
            </div>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Register;
