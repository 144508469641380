/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { API_BASE, PATH_OFFERS, TOKEN_DB } from "../../api/apis";
import { setDataArrAllWorks } from "../../context/Actions";

// ---------------------------------------------------------
// ---------------------------------------------------------

export const getArrWorksHomeScreen = async (dispatch = undefined) => {
  let arrWorks = [];

  const API = API_BASE;

  arrWorks = await fetch(`${API}${PATH_OFFERS}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
    },
  })
    .then((response) => response.json())
    .then((res) => res ?? [])
    .catch((error) => {
      console.error(error?.message);

      return [];
    });

  return dispatch !== undefined
    ? setDataArrAllWorks(dispatch, arrWorks ?? [])
    : arrWorks;
};
