/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./HomeCompany.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import servicesHomeCompany from "./servicesHomeCompany";
import { userCompany, yourPackage } from "../../utils/Helpers";
import Button from "../../components/button/Button";
import Paragraph from "../../components/paragraph/Paragraph";
import { getSubscription } from "../loginCompany/servicesLoginCompany";
// ---------------------------------------------------------

const HomeCompany = ({ docTitle }) => {
  const { dispatch, yourpackage } = WebContext();
  const { name, id, token } = userCompany.get();
  const { subscription, packages } = yourPackage.get();
  const { end_date, amount } =
    subscription === undefined ? yourpackage : subscription;
  const { as, classNameBorderColor, classNameTxtColor, classNameBgColor } =
    packages === undefined ? yourpackage : packages;

  const navigate = useNavigate();

  useEffect(() => {
    if (token === "" || token === undefined) {
      navigate("/login-company");
    }
  });

  useEffect(() => {
    token !== "" && getSubscription(dispatch, id, token);
  }, [dispatch, id, token]);

  return (
    <>
      {/* Class names starting with "hmc" refer to the file: HomeCompany.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-home-company")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="company" />

      <div className="hmc_container">
        <div className="hmc_welcome_container">
          <h2>
            {t("admin-company-welcome")} {name}
          </h2>
        </div>

        <div className="hmc_dashboard_container">
          <div className={`hmc_card_container ${classNameBorderColor}`}>
            <Paragraph
              withTitle
              title={
                <p>
                  {t("admin-company-yourpackages")}:{" "}
                  <i className={`${classNameTxtColor}`}>{as}</i>
                </p>
              }
            />

            <Paragraph>
              <p>{t("admin-company-used-unlocks")}:</p>
              <p>
                <b>0</b>
              </p>
            </Paragraph>

            <Paragraph>
              <p>{t("admin-company-available-unlocks")}:</p>
              <p>
                <b className={`${classNameTxtColor}`}>{amount}</b>
              </p>
            </Paragraph>

            <small>
              {t("admin-company-expiration")}: <b>{end_date.slice(0, 10)}</b>
            </small>

            <div className="hmc_card_btn_container">
              <Link
                to={"/billing"}
                className={`hmc_card_link_to_billing ${classNameTxtColor}`}
              >
                <p>{t("admin-company-billing")}</p>
              </Link>

              <div style={{ flex: 1 }} />

              {/* <Button
                otherClassNameBtn={`${classNameBgColor} ${classNameBorderColor}`}
                type="link"
                link="/company-packages"
                title={t("admin-company-see-packages")}
              /> */}
            </div>
          </div>

          <div className={`hmc_card_container ${classNameBorderColor}`}>
            <Paragraph
              withTitle
              title={t("admin-company-see-applicants-list")}
            />

            <Paragraph>
              <p>1) {t("admin-company-step-step-1")}</p>

              <p>2) {t("admin-company-step-step-2")}</p>

              <p>3) {t("admin-company-step-step-3")}</p>

              <p>4) {t("admin-company-step-step-4")}</p>
            </Paragraph>

            <div className="hmc_card_btn_container">
              <Button
                otherClassNameBtn={`${classNameBgColor} ${classNameBorderColor}`}
                type="link"
                link="/applicants-list"
                title={t("admin-company-see-applicants-list")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCompany;
