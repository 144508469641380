/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------
import "./Billing.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import {
  redirectLoginC,
  userCompany,
} from "../../utils/Helpers";
import Button from "../../components/button/Button";
import Paragraph from "../../components/paragraph/Paragraph";
import getBilling, { cancelBuy } from "./servicesBilling";
import { setLastVisitedRoute } from "../../context/Actions";
// ---------------------------------------------------------

const Billing = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const [billing, setBilling] = useState([]);
  const navigate = useNavigate();
  const { id, token } = userCompany.get();

  useEffect(() => {
    // const redirectLoginC = () => {
    //   setLastVisitedRoute(dispatch, `billing`);
    //   navigate("/login-company");
    // };

    getBilling(dispatch, id, token, (res) =>
      res === "token-expired"
        ? redirectLoginC(dispatch, navigate, "billing")
        : typeof res === "string"
        ? setBilling([])
        : setBilling(res)
    );
  }, [dispatch, id, token, navigate]);

  return (
    <>
      {/* Class names starting with "bll" refer to the file: Billing.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-billing")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="company" />

      <div className="bll_container">
        <h2>{t("admin-company-billing")}</h2>

        <table className="bll_table">
          <tbody>
            <tr>
              <th>{t("admin-company-billing-table-title-date")}</th>
              <th>{t("admin-company-billing-table-title-package")}</th>
              <th>{t("admin-company-billing-table-title-status")}</th>
            </tr>

            {billing.map((item, index) => {
              return (
                <tr key={`billing_item_${index}`}>
                  <td>{item.date}</td>
                  <td>{item.product.name}</td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color:
                        item.status === "cancelled"
                          ? "#ff0000"
                          : item.status === "paid"
                          ? "#009900"
                          : "#000000",
                    }}
                  >
                    {item.status === "unpaid" ? (
                      <>
                        <button
                          className="bll_btn_cancel"
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Do you want to delete the purchase?"
                              )
                            ) {
                              cancelBuy(
                                item.id,
                                token,
                                (res) =>
                                  res.status === "cancelled" &&
                                  window.location.reload()
                              );
                            }
                          }}
                        >
                          {t("admin-company-cancel")}
                        </button>

                        <Button
                          type="link"
                          target="_blank"
                          link={item.url}
                          title={t("admin-company-billing-table-title-pay")}
                        />
                      </>
                    ) : item.status === "paid" ? (
                      t("admin-company-billing-table-title-paid")
                    ) : (
                      item.status === "cancelled" &&
                      t("admin-company-is-cancel")
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Billing;
