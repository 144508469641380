/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { API_BASE, PATH_LOGIN_INFLUENCER, TOKEN_DB } from "../../api/apis";
import { setUserinfluencer } from "../../context/Actions";
import { userApplicant, userCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesLoginInfluencer = async (
  dispatch,
  credentials,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;
  let _credentials = {
    mail: credentials.mail,
    password: credentials.password,
  };

  await fetch(`${API}${PATH_LOGIN_INFLUENCER}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_credentials),
  })
    .then((res) => {
      if (res.status !== 200 && res.status !== 201 && res.status !== 202) {
        response("failed");
        loading(false);
        return;
      } else {
        return res.json();
      }
    })
    .then((res) => {
      let userinfluencer = {
        id: res.codigo,
        mail: res.mail,
        name: res.name,
        phone: res.phone,
        city: res.city,
        zip: res.zip,
        token: res.token,
      };

      setUserinfluencer(dispatch, userinfluencer);
      localStorage.setItem("userinfluencer", JSON.stringify(userinfluencer));
      response("successful");
      loading(false);

      userCompany.clear();
      userApplicant.clear();
    })
    .catch((error) => {
      console.error(error);
      response("failed");
      loading(false);
    });
};

export default servicesLoginInfluencer;
