/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useState } from "react";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./Services.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import ContactForm from "../../components/contactForm/ContactForm";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const Services = ({ docTitle }) => {
  const [tabView, setTabView] = useState(-1);
  const [stateForm, setStateForm] = useState({
    Service: "",
    name: "",
    lastname: "",
    mail: "",
    datapicker: "",
    phone: "",
    Message: "",
    checkbox_legalConditions: true,
    ready: false,
  });

  const arrServices = [
    {
      title: "CompanyU: Ihr Schlüssel zum Erfolg in der Mitarbeitergewinnung",
      colorTitle: "#be8870",
      description:
        "Heutzutage ist es entscheidend, einen starken und verlässlichen Partner an Ihrer Seite zu haben, ...",
      bgimg: assets.imgs.bannerApplicants,
      icon: "https://cdn-icons-png.flaticon.com/512/1436/1436708.png",
      classNameBgColor: "ser_article_companyServices",
      information: [
        `Heutzutage ist es entscheidend, einen starken und verlässlichen Partner an Ihrer Seite zu haben, der nicht nur Ihre Anforderungen versteht, sondern auch effektiv umsetzt.`,
        `Genau hier kommt CompanyU ins Spiel. Wir sind die Plattform, die Ihr Unternehmen direkt mit qualifizierten Handwerksfachkräften verbindet.`,
        `Als Ihr Partner in der Rekrutierung bieten wir Ihnen:`,
        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              Zielgerichtete Vermittlung:
            </b>
          </p>

          <p>
            Erhalten Sie Zugang zu einem Pool von top-qualifizierten
            Handwerkern, die genau zu Ihren Bedürfnissen passen.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              Effizienz und Kostenersparnis:
            </b>
          </p>

          <p>
            Vergessen Sie langwierige Rekrutierungsprozesse. Mit CompanyU sparen
            Sie Zeit und Ressourcen bei der Suche nach den besten Talenten.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              Persönliche Unterstützung und moderne Tools:
            </b>
          </p>

          <p>
            Unsere Plattform ist intuitiv und benutzerfreundlich. Zudem steht
            Ihnen unser Team stets zur Seite, um Ihre Rekrutierung so effektiv
            wie möglich zu gestalten.
          </p>
        </div>,

        `Entscheiden Sie sich für CompanyU - Ihren verlässlichen Partner in der modernen Mitarbeitergewinnung.`,
      ],
      datanumbers: [
        {
          number: "5,6 Millionen",
          description: "Beschäftigte Handwerker gibt es in Deutschland",
        },
      ],
      childrenInformation: <></>,
    },
    {
      title: "Tradition trifft Technologie",
      subtitle: `Webdesign für Handwerksbetriebe`,
      colorTitle: "#0d71c8",
      description: "Webdesign für Handwerksbetriebe ...",
      bgimg:
        "https://www.checkatrade.com/blog/wp-content/uploads/2022/05/Feature-website-for-small-business.jpg",
      classNameBgColor: "ser_article_webapp",
      information: [
        `Wir errichten Ihnen ihren digitalen Fußabdruck im Netz. Unsere Websites sind weit mehr als nur digitale Visitenkarten; sie sind dynamische Schnittstellen, die Ihre Kunden auf einer überzeugenden Reise begleiten und nachhaltig zum Unternehmenserfolg beitragen. In einer Welt, in der der Online-Wettbewerb immer intensiver wird, ist eine maßgeschneiderte, kreative und technisch ausgefeilte Website kein Luxus mehr, sondern eine Notwendigkeit.`,

        `Unser versiertes IT-Team bringt eine Mischung aus künstlerischer Kreativität und technischer Exzellenz ein, um Ihre Visionen auf den Bildschirm zu bringen. Wir bieten ein sorgloses Rundumpaket, das Ihnen die Digitalisierung Ihres Handwerksbetriebs erleichtert. Bei uns sind Sie nicht nur ein Kunde, sondern ein Partner, mit dem wir gemeinsam eine langfristige Beziehung aufbauen möchten, basierend auf Vertrauen, Flexibilität und einer unübertroffenen Zuverlässigkeit.`,

        `Entdecken Sie unsere spezialisierten Dienstleistungen, die Ihre Website in ein kraftvolles Werkzeug zur Kundengewinnung verwandeln und speziell auf Handwerksbetriebe zugeschnitten sind:`,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              1. Responsive Design:
            </b>
          </p>

          <p>
            Ihre Website funktioniert perfekt auf allen Geräten, um Kunden
            unterwegs zu erreichen.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              2. Content-Management-Systeme (CMS):
            </b>
          </p>

          <p>
            Aktualisieren Sie einfach Ihre Dienstleistungen, Preise und Fotos.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              3. Webhosting und Domain-Services:
            </b>
          </p>

          <p>
            Zuverlässiges Hosting und Hilfe bei der Wahl einer passenden Domain.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              4. Website-Wartung und -Support:
            </b>
          </p>

          <p>
            Stets verfügbar und aktuell durch regelmäßige technische
            Überprüfungen.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              5. Geschwindigkeitsoptimierung:
            </b>
          </p>

          <p>
            Schnelle Ladezeiten, um Kunden schnell Informationen zu liefern.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              6. Datenschutz und Sicherheit:
            </b>
          </p>

          <p>
            Schutz der Website und Kundendaten, besonders für
            Online-Terminbuchungen und Angebote.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              7. Analyse und Reporting:
            </b>
          </p>

          <p>
            Einblicke in die Nutzung Ihrer Website zur Identifikation von
            Verbesserungspotenzialen.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              8. Social-Media-Integration:
            </b>
          </p>

          <p>
            Präsentieren Sie Ihre Projekte und teilen Sie Kundenbewertungen auf
            sozialen Netzwerken.
          </p>
        </div>,

        <div className="ser_article_tab_info_benefit">
          <p>
            <b className="ser_article_tab_info_subtitle_txt">
              9. Multisprachige Websites:{" "}
            </b>
          </p>

          <p>
            Erreichen Sie eine breitere Kundengruppe in mehrsprachigen Regionen.
          </p>
        </div>,

        `...und vieles mehr. Egal, ob Sie ein verbessertes Ranking, die Integration neuer Funktionen oder ein moderneres Erscheinungsbild mit qualitativ hochwertigem visuellen Inhalt wünschen - unsere Experten kreieren Websites, die Ihre Marke authentisch repräsentieren und ein unvergleichliches Nutzererlebnis bieten. Lassen Sie uns gemeinsam Ihre digitale Präsenz revolutionieren!`,
      ],
      datanumbers: [
        {
          number: "72%",
          description:
            "der deutschen Unternehmen besitzen bereits eine moderne Website.",
        },
      ],
      childrenInformation: <></>,
    },
  ];

  const ArticleServices = ({
    id = 0,
    decorationTop = true,
    decorationBottom = true,
    data = {
      title: "",
      colorTitle: "",
      description: "",
      bgimg: "",
      icon: "",
      classNameBgColor: "",
    },
  }) => {
    return (
      <article
        className={`ser_article_service ${
          decorationTop && decorationBottom
            ? "ser_decoration_top_and_bottom"
            : decorationTop && !decorationBottom
            ? "ser_decoration_only_top"
            : !decorationTop && decorationBottom
            ? "ser_decoration_only_bottom"
            : "ser_decoration_without"
        } ${data.classNameBgColor} ${
          !decorationBottom && "ser_article_end_spacing"
        }`}
      >
        {/* <span
          className={`ser_decoration ser_decoration_top ${data.classNameBgColor}`}
        /> */}

        <div className="ser_article_bgimg_container">
          <img
            className="ser_article_bgimg_img"
            src={data.bgimg}
            alt="Bg service"
          />

          <div
            className={`ser_article_bgimg_darkLayer ${data.classNameBgColor}`}
          />
        </div>

        <div className="ser_article_service_content">
          <h2 className="ser_article_info_title">{data.title}</h2>

          <div className="ser_article_info">
            <p className="ser_article_info_p">{data.description}</p>
          </div>
        </div>

        <button
          className="ser_article_btn_see"
          onClick={() => {
            setStateForm({
              ...stateForm,
              Service: data.title,
            });
            setTabView(id);
          }}
        >
          <h4>Mehr</h4>
        </button>
      </article>
    );
  };

  return (
    <>
      {/* Class names starting with "ser" refer to the file: Services.css */}
      <Helmet>
        <title>
          {docTitle} | {t("Leistungen")}
        </title>
      </Helmet>

      <Header />

      <div className="ser_main_container">
        <div className="ser_bgimg_page">
          <img
            className="ser_bgimg_page_img"
            src="https://aqui.madrid/wp-content/uploads/2020/10/contenidos-digitales-comunicacio%CC%81n.jpg"
            alt="Bg page"
          />
        </div>

        {arrServices.map((service, index) => {
          return (
            <ArticleServices
              key={`service_${index}`}
              id={index + 1}
              data={service}
              decorationTop={index !== 0}
              decorationBottom={index !== arrServices.length - 1}
            />
          );
        })}

        {arrServices.map((service, index) => {
          return (
            <div
              key={`service_open_${index + 1}`}
              style={{
                zIndex: index + 1 === tabView ? 10000000 : -1,
                opacity: index + 1 === tabView ? 1 : 0,
              }}
              className={`ser_article_tab_container ${
                index + 1 === tabView && "ser_article_tab_open"
              }`}
            >
              <div
                onClick={() => {
                  setTabView(-1);
                }}
                className="ser_article_tab_bg_close"
              />

              <div className="ser_article_tab_bg_container">
                <img
                  className="ser_article_tab_bg_img"
                  src={service.bgimg}
                  alt={`Background img: ${service.title}`}
                />
              </div>

              <div className="ser_article_tab">
                <button
                  style={{
                    zIndex: index + 1 === tabView ? 100001 : -1,
                    opacity: index + 1 === tabView ? 1 : 0,
                  }}
                  className="ser_article_tab_close_btn"
                  onClick={() => {
                    setTabView(-1);
                  }}
                >
                  <div className="ser_article_tab_close_btn_line" />
                  <div className="ser_article_tab_close_btn_line" />
                </button>

                <div className="ser_article_tab_information_container">
                  <h2
                    style={{ color: service.colorTitle }}
                    className={`ser_article_tab_title`}
                  >
                    {service.title}
                  </h2>

                  {service?.subtitle && (
                    <h4
                      style={{
                        margin: `0 0 4rem`,
                        fontSize: 18,
                        color: service.colorTitle,
                      }}
                      className={`ser_article_tab_title`}
                    >
                      {service.subtitle}
                    </h4>
                  )}

                  <section className="ser_article_tab_info_container">
                    <div className="ser_article_tab_info_img_container">
                      <div
                        style={{ backgroundColor: service.colorTitle }}
                        className="ser_article_tab_info_img_decoration"
                      />

                      <img
                        className="ser_article_tab_info_img_img"
                        src={service.bgimg}
                        alt={`Service: ${service.title}`}
                      />
                    </div>

                    <div className="ser_article_tab_info_area">
                      {service.information.map((info, index) => {
                        if (index <= 1) {
                          return (
                            <p
                              key={`service_info_${index}`}
                              className="ser_article_tab_info_txt"
                            >
                              {info}
                            </p>
                          );
                        } else return null;
                      })}
                    </div>
                  </section>

                  <section className="ser_article_tab_datanumber_container">
                    {service.datanumbers.map((num, index) => {
                      return (
                        <div
                          key={`datanumbers_num_${index}`}
                          className="ser_article_tab_datanumber"
                        >
                          <h2 style={{ color: service.colorTitle }}>
                            {num.number}
                          </h2>

                          <p>{num.description}</p>
                        </div>
                      );
                    })}
                  </section>

                  <div className="ser_article_tab_info_area ser_article_tab_info_area_end">
                    {service.information.map((info, index) => {
                      if (index > 1) {
                        return (
                          <p
                            key={`service_info_${index}`}
                            className="ser_article_tab_info_txt"
                          >
                            {info}
                          </p>
                        );
                      } else return null;
                    })}
                  </div>

                  <section className="ser_article_tab_form_container">
                    <div
                      style={{ backgroundColor: service.colorTitle }}
                      className="ser_article_tab_form_decorationTop"
                    />

                    <ContactForm
                      color={service.colorTitle}
                      initialForm={stateForm}
                      onChangeForm={(form) => setStateForm(form)}
                    />
                  </section>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Services;
