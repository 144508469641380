/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./LoginCompany.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../../components/paragraph/Paragraph";
import Input, { ChildrenLabelDefault } from "../../components/input/Input";
import Button from "../../components/button/Button";
import servicesLoginCompany from "./servicesLoginCompany";
import { WebContext } from "../../context/Context";
import { userCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const LoginCompany = ({ docTitle }) => {
  const { dispatch, saveApplicantID, lastVisitedRoute } = WebContext();

  const [login, setLogin] = useState({
    login_mail: "",
    login_password: "",
  });

  const [errorFree, setErrorFree] = useState(login);

  const [sendLoading, setSendLoading] = useState(false);
  const [responseSend, setResponseSend] = useState("");

  const handleChangeInput = (target, isOK) => {
    setLogin({
      ...login,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (errorFree.login_mail !== true || errorFree.login_password !== true) {
      setErrorFree({
        login_mail: errorFree.login_mail !== true ? false : true,
        login_password: errorFree.login_password !== true ? false : true,
      });

      return (document.documentElement.scrollTop = 0);
    } else {
      servicesLoginCompany(
        dispatch,
        login,
        (response) => setResponseSend(response),
        (loading) => setSendLoading(loading)
      );
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (
      responseSend === "successful" &&
      responseSend !== "failed" &&
      responseSend !== "failed-credentials"
    ) {
      if (saveApplicantID !== "") {
        navigate(`/applicant/${saveApplicantID}`);
      } else if (lastVisitedRoute !== "") {
        navigate(`/${lastVisitedRoute}`);
      } else {
        navigate("/home-company");
      }
    }
  }, [responseSend, saveApplicantID, lastVisitedRoute, navigate]);

  useEffect(() => {
    userCompany.get().token !== "" &&
      userCompany.get().token !== undefined &&
      navigate("/home-company");
  }, [navigate]);

  return (
    <>
      {/* Class names starting with "lgc" refer to the file: LoginCompany.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-login")}
        </title>
      </Helmet>

      <Header />

      <div className="lgc_container">
        <div className="lgc_darklayer" />

        <div className="lgc_login_container">
          <Paragraph
            withTitle
            title={t("login-title")}
            otherClassNameContainer="lgc_title_container"
            otherClassNameTitle="lgc_title"
            otherClassNameParagraph="lgc_subtitle_container"
          >
            <p>{t("company-login-subtitle")}</p>
          </Paragraph>

          <div className="lgc_line" />

          <Input
            id="login_mail"
            nameInput="login_mail"
            type="email"
            placeholder="yourmail@mail.com"
            responseInput={(target, isOK) => {
              handleChangeInput(target, isOK);
            }}
            defaultValue={login.login_mail}
            required
            label
            childrenLabel={
              <ChildrenLabelDefault
                color="#f3f3f3"
                txt={t("company-login-mail")}
                required
              />
            }
            errorMessage={t("form-required")}
          />

          <Input
            id="login_password"
            nameInput="login_password"
            type="password"
            placeholder="******"
            responseInput={(target, isOK) => {
              handleChangeInput(target, isOK);
            }}
            defaultValue={login.login_password}
            required
            label
            childrenLabel={
              <ChildrenLabelDefault
                color="#f3f3f3"
                txt={t("company-login-password")}
                required
              />
            }
            errorMessage={t("form-required")}
          />

          {/* <Link to="/recover-password-company" className="lgc_p lgc_link">
            <p>{t("company-login-recover-password")}</p>
          </Link> */}

          <Button
            otherClassNameBtn={
              sendLoading
                ? "lgc_sending"
                : !errorFree.login_mail || !errorFree.login_password
                ? "lgc_inactive"
                : ""
            }
            title={
              sendLoading
                ? t("company-login-btn-loading")
                : responseSend === ""
                ? t("company-login-btn")
                : t("company-login-btn-retry")
            }
            onclick={(e) =>
              responseSend === "" ||
              responseSend === "failed" ||
              responseSend === "failed-credentials"
                ? handleSubmit(e)
                : null
            }
          />

          {responseSend === "failed-credentials" && (
            <p style={{ color: "#f3f3f3" }}>Falsche Anmeldeinformationen</p>
          )}

          <div className="lgc_line" />

          <p className="lgc_p">
            {t("company-login-without-account")}{" "}
            <Link
              to="/register-company"
              className="lgc_p lgc_link"
              target="_top"
            >
              {t("company-login-to-register")}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginCompany;
