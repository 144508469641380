/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_LOGIN_APPLICANT,
  PATH_REGISTER_APPLICANT,
  PATH_UPDATE_APPLICANT,
  TOKEN_DB,
} from "../../api/apis";
import servicesLoginApplicant from "../loginApplicant/servicesLoginApplicant";
// ---------------------------------------------------------

export const initialFormRegisterApplicant = {
  influencer: "" /* str */,
  works: "" /* str */,
  technique: "" /* str */,
  zip: "" /* str */,
  benefits: [] /* arr (almacena str, ex: ['car', 'bonus']) */,
  travel: "" /* str */,
  license: "" /* str */,
  name: "" /* str */,
  lastname: "" /* str */,
  mail: "" /* str. Mail para registrarse */,
  password: "" /* str. Contraseña para registrarse */,
  phone: "" /* str */,
  arrival: "" /* str */,
};

const servicesRegisterApplicant = async (
  dispatch,
  data,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  console.log("services sending")
  loading(true);
  const API = API_BASE;

  const form = {
    influencer: data.influencer /* str */,
    works: data.works /* str */,
    technique: data.technique /* str */,
    zip: data.zip /* str */,
    benefits: data.benefits /* arr (almacena str, ex: ['car', 'bonus']) */,
    travel: data.travel /* str */,
    license: data.license /* str */,
    name: data.name /* str */,
    lastname: data.lastname /* str */,
    mail: data.mail /* str. Mail para registrarse */,
    password: data.password /* str. Contraseña para registrarse */,
    phone: data.phone /* str */,
    arrival: data.arrival /* str */,
  };

  await fetch(`${API}${PATH_REGISTER_APPLICANT}`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.response === "successful") {
        response("successful");

        // servicesLoginApplicant2(
        //   dispatch,
        //   {
        //     mail: data.mail,
        //     password: data.password,
        //   },
        //   (res, data) =>
        //     res === "successful" || res.response === "successful"
        //       ? servicesUpdateStep1Applicant({ ...dataInfo, ...data })
        //       : null,
        //   (load) => loading(load)
        // );
        servicesLoginApplicant(
          dispatch,
          {
            login_mail: data.mail,
            login_password: data.password,
          },
          (res) => response(res)
        );

        loading(false);
      }
    })
    .catch((error) => {
      // console.error(error);
      response("failed");
      loading(false);
    });
};

export default servicesRegisterApplicant;

const servicesLoginApplicant2 = async (
  dispatch,
  credentials,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_LOGIN_APPLICANT}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((response) => response.json())
    .then((res) => {
      response("successful", res);
      loading(false);
    })
    .catch((error) => {
      console.error(error);
      response("failed");
      loading(false);
    });
};

const servicesUpdateStep1Applicant = async (
  data,
  response = () => {},
  loading = () => {}
) => {
  const API = API_BASE;

  let _infoapplicant = {
    name: data.applicant_name,
    lastname: data.applicant_lastname,
    city: data.applicant_residence_city,
    zip: data.applicant_postal_code,
    tel: data.applicant_phone_number,
    birthdate: data.applicant_birthdate,
    gender: data.applicant_gender,
    job: data.applicant_what_job,
    timeexp: data.applicant_time_exp,
    lngpref: data.applicant_lng_pref,
    license: data.applicant_driving_license,
    terms: true,
  };

  await fetch(`${API}${PATH_UPDATE_APPLICANT}/${data.id}`, {
    method: "PUT",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.token}`,
    },
    body: JSON.stringify(_infoapplicant),
  })
    .then((response) => response.json())
    .then((res) => {
      response("successful");
      loading(false);
    })
    .catch((error) => {
      console.error(error);
      response("failed");
      loading(false);
    });
};
