/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_GET_PREVIEW_APPLICANTS,
  TOKEN_DB,
} from "../../api/apis";
import { setArrApplicantsPreview } from "../../context/Actions";
// ---------------------------------------------------------

const servicesPreviewApplicants = async (dispatch) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_GET_PREVIEW_APPLICANTS}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      setArrApplicantsPreview(dispatch, res.reverse());
    })
    .catch((error) => {
      setArrApplicantsPreview(dispatch, []);
    });
};

export default servicesPreviewApplicants;
