/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import Spline from "@splinetool/react-spline";
// ---------------------------------------------------------
// ---------------------------------------------------------

const SplineArea = ({ scene = "" }) => {
  return (
    <>
      {/* Class names starting with "spl" refer to the file: SplineArea.css */}
      <Spline scene={scene} />
    </>
  );
};

export default SplineArea;
