/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_FILTER_APPLICANTS,
  PATH_UNLOCK,
  TOKEN_DB,
} from "../../api/apis";
import { setArrApplicantsList } from "../../context/Actions";
import { validateActiveSessionTokenCompany } from "../../utils/Helpers";
// ---------------------------------------------------------

const servicesApplicantsList = async (
  dispatch,
  id,
  privateSessionToken,
  filters = "",
  response = () => {}
) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_FILTER_APPLICANTS}?company_id=${id}${filters}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      setArrApplicantsList(dispatch, res.reverse());
    })
    .catch((error) => {
      // console.error(error);
      setArrApplicantsList(dispatch, []);
      response(
        error.message === "token-expired" ? "token-expired" : "unknown-error"
      );
    });
};

export default servicesApplicantsList;

export const unlockApplicant = async (
  company_id,
  applicant_id,
  privateSessionToken,
  responseUnlock = () => {}
) => {
  const API = API_BASE;
  await fetch(`${API}${PATH_UNLOCK}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
      Authorization: `Bearer ${privateSessionToken}`,
    },
    body: JSON.stringify({ applicant_id, company_id }),
  })
    // .then((response) => console.log(response))
    .then((response) => validateActiveSessionTokenCompany(response))
    .then((res) => {
      responseUnlock(res);
    })
    .catch((error) => {
      responseUnlock(
        error.message === "token-expired" ? "token-expired" : "unknown-error"
      );
    });
};
