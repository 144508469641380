/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
// ---------------------------------------------------------
import "./Applicant.css";
// import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import ApplicantCard from "../../components/applicantCard/ApplicantCard";
import LoadingTab from "../../components/loading/LoadingTab";
import ApplicantCardEmpty from "../../components/applicantCard/ApplicantCardEmpty";
import servicesApplicant from "./servicesApplicant";
import { userCompany } from "../../utils/Helpers";
import { t } from "../../languages/settingsLanguages";
import { setLastVisitedRoute } from "../../context/Actions";
import { WebContext } from "../../context/Context";
// ---------------------------------------------------------

const Applicant = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const { token } = userCompany.get();
  const { id } = useParams();
  const [applicant, setApplicant] = useState("empty");
  const [loadingApplicant, setLoadingApplicant] = useState(true);
  const company_id = userCompany.get().id;

  useEffect(() => {
    servicesApplicant(
      (state) => setLoadingApplicant(state),
      token,
      id,
      company_id,
      (data) => setApplicant(data)
    );
  }, [id, company_id, token]);

  const navigate = useNavigate();

  useEffect(() => {
    const redirectLoginC = () => {
      setLastVisitedRoute(dispatch, `applicant/${id}`);
      navigate("/login-company");
    };

    applicant === "token-expired" && redirectLoginC();
  }, [dispatch, id, applicant, navigate]);

  return (
    <>
      {/* Class names starting with "app" refer to the file: Applicant.css */}
      <Helmet>
        <title>
          {docTitle} |{" "}
          {typeof applicant === "string"
            ? t("applicant-doctitle")
            : `${applicant.name} ${applicant.lastname}`}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="company" />

      <div className="app_container">
        {loadingApplicant ? (
          <LoadingTab />
        ) : typeof applicant === "string" ? (
          <ApplicantCardEmpty />
        ) : (
          <>
            <h4 className="app_subtitle">
              {t("admin-company-introduce-applicant")}:
            </h4>
            <ApplicantCard data={applicant} toUnblock />
          </>
        )}
      </div>
    </>
  );
};

export default Applicant;
