/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./RegisterApplicant.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Input, {
  ChildrenLabelDefault,
  Options,
  Selector,
} from "../../components/input/Input";
import Button from "../../components/button/Button";
import { WebContext } from "../../context/Context";
import servicesRegisterApplicant from "./RegisterApplicant.services";
import {
  additionalServices,
  arrivalOptions,
  experienceOptions,
  licenseOptions,
  option_choose,
  studiesOptions,
  travelOptions,
} from "../../utils/options";
import { userApplicant } from "../../utils/Helpers";
import { initialFormRegisterApplicant } from "./RegisterApplicant.services";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const RegisterApplicant = ({ docTitle }) => {
  const { dispatch } = WebContext();
  const { token } = userApplicant.get();

  const [currentStepForm, setCurrentStepForm] = useState(1);
  const [autofocusForm, setAutofocusForm] = useState(1);

  const [form, setForm] = useState({
    ...initialFormRegisterApplicant,
    repeat_password: "",

    applicant_newsletter: false,
  });
  const [errorFree, setErrorFree] = useState({
    influencer: false,
    works: false,
    technique: false,
    zip: false,
    benefits: false,
    travel: false,
    license: false,
    name: false,
    lastname: false,
    person: false,
    mail: false,
    password: false,
    credentials: false,
    phone: false,
    arrival: false,

    applicant_newsletter: true,
  });
  const [validAge, setValidAge] = useState("");

  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let regIsPhone = /^\+?\d{10,13}$/;
  // ---------------- Regular expresions ----------------

  const verifyAge = (e) => {
    let _birthdate = e.target.value;
    let today = new Date();
    let birthdate = new Date(_birthdate);
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = birthdate.getMonth() - today.getMonth();
    let day =
      Number(_birthdate.slice(_birthdate.length - 2, _birthdate.length)) -
      Number(`${today}`.slice(7, 10));

    let greater_than =
      age === 18 && m < 0
        ? false
        : age === 18 && m === 0 && day < 0
        ? false
        : age < 17
        ? false
        : true;

    setValidAge(greater_than);

    return !greater_than;
  };

  const handleChangeInput = (target, isOK) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleChangeAddServices = (value) => {
    let newArr = form.benefits;

    let exists = newArr.findIndex((date) => date.value === value);

    if (exists === -1) {
      if (newArr.length < 5) {
        newArr.push({ value });
        setForm({
          ...form,
          benefits: newArr,
        });
      }
    } else {
      newArr.splice(exists, 1);
      setForm({
        ...form,
        benefits: newArr,
      });
    }

    setErrorFree({ ...errorFree, benefits: newArr.length >= 1 });
  };

  const handleResponse = (res) => {
    return setIsResultTabVisible(res);
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("sending");
    servicesRegisterApplicant(
      dispatch,
      form,
      (response) => setIsResultTabVisible(response),
      (loading) => setIsLoadingTabVisible(loading)
    );
  };

  const navigate = useNavigate();
  let { search } = useLocation();

  useEffect(() => {
    if (token !== "") {
      navigate("/home-applicant");
    }
  }, [token, navigate]);

  useEffect(() => {
    let query = new URLSearchParams(search);
    let partnerportal = query.get("idpartner");

    setForm({
      ...form,
      influencer:
        partnerportal === null ||
        partnerportal === undefined ||
        partnerportal === ""
          ? ""
          : partnerportal,
    });
  }, [search]);

  const arrSteps = [
    // --------------- Works ---------------
    {
      question: `Über dich`,
      imgProgress: assets.imgs.houseStep1,
      name: "works",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- What job -------------------- */}
            <Selector
              id="works"
              label={"Was ist dein Spezialgebiet?"}
              arr={experienceOptions}
              onclick={(value) => {
                handleChangeInput(
                  {
                    name: "works",
                    value,
                  },
                  value !== option_choose
                );
              }}
              select={form.works}
              required
              error={errorFree.works === false}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /What job -------------------- */}
          </div>
        </>
      ),
      // bgColorTest: "red",
    },
    // --------------- Technique ---------------
    {
      question: `Über dich`,
      imgProgress: assets.imgs.houseStep2,
      name: "technique",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- What job -------------------- */}
            <Options
              label={"Was ist deine Qualifikation?"}
              arr={studiesOptions}
              onclick={(value) => {
                handleChangeInput(
                  {
                    name: "technique",
                    value,
                  },
                  value !== option_choose
                );
              }}
              select={form.technique}
              required
              error={errorFree.technique === false}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /What job -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "violet",
    },
    // --------------- Zip ---------------
    {
      question: `Über dich`,
      name: "zip",
      imgProgress: assets.imgs.houseStep3,
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- Postal code -------------------- */}
            <Input
              id="zip"
              nameInput="zip"
              autofocus={currentStepForm === 3 && autofocusForm === 3}
              onfocus={() => setAutofocusForm(3)}
              onblur={() => setAutofocusForm(-1)}
              type="number"
              // placeholder={t("form-applicant-step-1-postal-code")}
              placeholder="000000"
              defaultValue={form.zip}
              required
              responseInput={(target, isOK) => handleChangeInput(target, isOK)}
              label
              childrenLabel={
                <ChildrenLabelDefault required txt={"PLZ Abfragen"} />
              }
              errorMessage={t("form-required")}
            />
            {/* -------------------- /Postal code -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "yellow",
    },
    // --------------- Benefits ---------------
    {
      question: `Über dich`,
      imgProgress: assets.imgs.houseStep4,
      name: "benefits",
      children: (
        <>
          <div className="rap_input_container">
            {/* ---------------- Additional Servicies ---------------- */}
            <Options
              otherClassNameOption="ffa_options_adjetives"
              label={"Was ist dir an deiner neuen Stelle wichtig?"}
              arr={additionalServices}
              onclick={(value) => handleChangeAddServices(value)}
              select={form.benefits}
              arrSelects={form.benefits}
            />
            {/* ---------------- /Additional Servicies ---------------- */}
          </div>
        </>
      ),
      bgColorTest: "blue",
    },
    // --------------- Travel ---------------
    {
      question: `Über dich`,
      imgProgress: assets.imgs.houseStep5,
      name: "travel",
      children: (
        <>
          <div className="rap_input_container">
            {/* ---------------- Additional Servicies ---------------- */}
            <Options
              otherClassNameOption="ffa_options_adjetives"
              label={"Bist du gerne unterwegs?"}
              arr={travelOptions}
              onclick={(value) => {
                handleChangeInput(
                  {
                    name: "travel",
                    value,
                  },
                  value !== option_choose
                );
              }}
              select={form.travel}
              required
            />
            {/* ---------------- /Additional Servicies ---------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
    // --------------- License ---------------
    {
      question: `Über dich`,
      imgProgress: assets.imgs.houseStep6,
      name: "license",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- License -------------------- */}
            <Options
              label={"Besitzt du einen Führerschein?"}
              arr={licenseOptions}
              onclick={(value) => {
                handleChangeInput(
                  {
                    name: "license",
                    value: value === form.license ? "" : value,
                  },
                  value !== form.license
                );
              }}
              select={form.license}
              required
              error={errorFree.license === false}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /License -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
    // --------------- Name-Lastname ---------------
    {
      question: `Fast geschafft! Wir sind sicher du wirst deine nächste Stelle finden!`,
      imgProgress: assets.imgs.houseStep7,
      name: "person",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- Name -------------------- */}
            <Input
              id="name"
              nameInput="name"
              // placeholder={t("form-applicant-step-1-name")}
              placeholder="Jon"
              autofocus={currentStepForm === 7 && autofocusForm === 7.1}
              onfocus={() => setAutofocusForm(7.1)}
              onblur={() => setAutofocusForm(-1)}
              defaultValue={form.name}
              required
              responseInput={(target, isOK) => {
                handleChangeInput(target, isOK);
                setErrorFree({
                  ...errorFree,
                  person: form.name !== "" && form.lastname !== "",
                });
              }}
              label
              childrenLabel={<ChildrenLabelDefault required txt={"Vorname"} />}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /Name ------------------------- */}

            {/* -------------------- Last Name -------------------- */}
            <Input
              id="lastname"
              nameInput="lastname"
              // placeholder={t("form-applicant-step-1-lastname")}
              placeholder="Gu"
              autofocus={currentStepForm === 7 && autofocusForm === 7.2}
              onfocus={() => setAutofocusForm(7.2)}
              onblur={() => setAutofocusForm(-1)}
              defaultValue={form.lastname}
              required
              responseInput={(target, isOK) => {
                handleChangeInput(target, isOK);
                setErrorFree({
                  ...errorFree,
                  person: form.name !== "" && form.lastname !== "",
                });
              }}
              label
              childrenLabel={<ChildrenLabelDefault required txt={"Nachname"} />}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /Last Name -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
    // --------------- Credentials ---------------
    {
      question: `Fast geschafft! Wir sind sicher du wirst deine nächste Stelle finden`,
      imgProgress: assets.imgs.houseStep9,
      name: "credentials",
      children: (
        <>
          <div className="rap_input_container">
            <Input
              id="mail"
              nameInput="mail"
              type="email"
              autofocus={currentStepForm === 8 && autofocusForm === 8.1}
              onfocus={() => setAutofocusForm(8.1)}
              onblur={() => setAutofocusForm(-1)}
              placeholder="yourmail@mail.com"
              responseInput={(target, isOK) => {
                handleChangeInput(target, isOK);
                setErrorFree({
                  ...errorFree,
                  credentials:
                    form.mail !== "" &&
                    regIsMail.test(form.mail) &&
                    form.password !== "" &&
                    form.repeat_password === form.password,
                });
              }}
              defaultValue={form.mail}
              required
              label
              childrenLabel={<ChildrenLabelDefault txt={t("Email")} required />}
              errorMessage={t("form-required")}
            />
            <br />

            <Input
              id="password"
              nameInput="password"
              type="password"
              placeholder="******"
              autofocus={currentStepForm === 8 && autofocusForm === 8.2}
              onfocus={() => setAutofocusForm(8.2)}
              onblur={() => setAutofocusForm(-1)}
              responseInput={(target, isOK) => {
                handleChangeInput(target, isOK, "register");
                setErrorFree({
                  ...errorFree,
                  credentials:
                    form.mail !== "" &&
                    regIsMail.test(form.mail) &&
                    form.password !== "" &&
                    form.repeat_password === form.password,
                });
              }}
              defaultValue={form.password}
              required
              label
              childrenLabel={<ChildrenLabelDefault txt={"Passwort"} required />}
              errorMessage={t("form-required")}
            />
            <br />

            {/* -------------------- Repeat password -------------------- */}
            <Input
              id="repeat_password"
              nameInput="repeat_password"
              // placeholder={t("form-applicant-step-1-repeat-password")}
              placeholder="******"
              autofocus={currentStepForm === 8 && autofocusForm === 8.3}
              onfocus={() => setAutofocusForm(8.3)}
              onblur={() => setAutofocusForm(-1)}
              defaultValue={form.repeat_password}
              type="password"
              required
              handleBlurContainsError={(e) => {
                return e.target.value !== form.password;
              }}
              responseInput={(target, isOK) => {
                handleChangeInput(target, isOK, "register");
                setErrorFree({
                  ...errorFree,
                  credentials:
                    form.mail !== "" &&
                    regIsMail.test(form.mail) &&
                    form.password !== "" &&
                    form.repeat_password === form.password,
                });
              }}
              label
              childrenLabel={
                <ChildrenLabelDefault
                  required
                  txt={t("form-applicant-step-1-repeat-password")}
                />
              }
              errorMessage={
                form.repeat_password.length === 0
                  ? t("form-required")
                  : form.repeat_password !== form.password
                  ? t("form-applicant-step-1-please-valid-repeat-password")
                  : ""
              }
            />
            {/* -------------------- /Repeat password -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
    // --------------- Number phone ---------------
    {
      question: `Fast geschafft! Wir sind sicher du wirst deine nächste Stelle finden!`,
      imgProgress: assets.imgs.houseStep10,
      name: "phone",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- Phone number -------------------- */}
            <Input
              id="phone"
              nameInput="phone"
              // placeholder={t("form-applicant-step-1-phone-number")}
              placeholder="04900000000"
              autofocus={currentStepForm === 9 && autofocusForm === 9}
              onfocus={() => setAutofocusForm(9)}
              onblur={() => setAutofocusForm(-1)}
              defaultValue={form.phone}
              type="tel"
              required
              handleBlurContainsError={(e) => {
                let validNumberPhone = !regIsPhone.test(e.target.value);

                return validNumberPhone;
              }}
              responseInput={(target, isOK) => handleChangeInput(target, isOK)}
              label
              childrenLabel={
                <ChildrenLabelDefault required txt={"Telefonnummer"} />
              }
              errorMessage={
                form.phone?.length === 0
                  ? t("form-required")
                  : !regIsPhone.test(form.phone)
                  ? t("form-applicant-step-1-please-valid-number")
                  : ""
              }
            />
            {/* -------------------- /Phone number -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
    // --------------- Arrival ---------------
    {
      question: `Danke fürs Kommen!`,
      imgProgress: assets.imgs.houseStep11,
      name: "arrival",
      children: (
        <>
          <div className="rap_input_container">
            {/* -------------------- Arrival -------------------- */}
            <Options
              label={"Wie hast du uns gefunden?"}
              arr={arrivalOptions}
              onclick={(value) => {
                handleChangeInput(
                  {
                    name: "arrival",
                    value: value === form.arrival ? "" : value,
                  },
                  value !== form.arrival
                );
              }}
              select={form.arrival}
              required
              error={errorFree.arrival === false}
              errorMessage={t("form-required")}
            />
            {/* -------------------- /Arrival -------------------- */}
          </div>
        </>
      ),
      bgColorTest: "green",
    },
  ];

  const Step = ({
    index = 0,
    memoryName = "",
    style = {},
    current = 1,
    onClickBack = () => {},
    onClickNext = () => {},
    isLast = false,
    onClickFinish = () => {},
    imgProgress = "",
    children = <></>,
    bgColorTest = "red",
  }) => {
    return (
      <div
        className="rap_form_step"
        style={{
          ...style,
          // backgroundColor: bgColorTest,
        }}
      >
        <div
          className="rap_step_container"
          style={{ position: "relative", zIndex: 1, flex: 1, height: "auto" }}
        >
          {children}
        </div>

        <div className="rap_form_step_btns_container">
          {index !== 0 && (
            <button
              className="rap_form_step_btn rap_form_step_btn_back"
              onClick={(e) => {
                e.preventDefault();

                onClickBack();
              }}
            >
              <img
                style={{ marginRight: 2 }}
                className="rap_form_step_icon"
                src={assets.icons.iconBack}
                alt="Button Back"
              />
            </button>
          )}

          <div className="rap_steps_info_container">
            <p>
              <b>{currentStepForm}</b>/{arrSteps.length}
            </p>
          </div>

          <button
            type={isLast ? "submit" : "button"}
            className={`rap_form_step_btn rap_form_step_btn_next ${
              !errorFree[memoryName] && "rap_form_step_btn_next_disable"
            }`}
            onClick={(e) => {
              e?.preventDefault();

              !errorFree[memoryName]
                ? console.log()
                : isLast
                ? onClickFinish(e)
                : onClickNext();
            }}
          >
            {isLast ? (
              <img
                style={{ marginRight: 2 }}
                className="rap_form_step_icon"
                src={assets.icons.iconSend}
                alt="Button Next"
              />
            ) : (
              <img
                style={{ marginLeft: 2 }}
                className="rap_form_step_icon"
                src={assets.icons.iconNext}
                alt="Button Next"
              />
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Class names starting with "rap" refer to the file: RegisterApplicant.css */}
      <Helmet>
        <title>
          {docTitle} | {t("Einchecken")}
        </title>
      </Helmet>

      <Header />

      <div className="rap_container">
        {arrSteps.map((item, index) => {
          return (
            <section
              key={`bg-step-${index + 1}`}
              className={`rap_form_progresshouse_container`}
            >
              <img
                style={{
                  opacity:
                    item.imgProgress === "" || index + 1 !== currentStepForm
                      ? 0
                      : 1,
                }}
                className="rap_form_progresshouse_img"
                src={item.imgProgress}
                alt={`Background progress. Step: ${index + 1}`}
              />
            </section>
          );
        })}

        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={assets.icons.iconSending}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={assets.imgs.successful}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={assets.imgs.failed}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible === "successful" ? (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-successful-confirm")}
            </p>
          ) : (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/register-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <div className="rap_form_container">
          <form name="rap_form_register_applicant">
            <h3 className="rap_form_register_applicant_title">Einchecken</h3>

            <section className="rap_form_register_applicant_section">
              {arrSteps.map((item, index) => {
                return (
                  <Step
                    key={`rap_form_register_applicant_${index}`}
                    memoryName={item.name}
                    style={{
                      display:
                        currentStepForm === index + 1 ? "normal" : "none",
                      opacity: currentStepForm === index + 1 ? 1 : 0,
                      zIndex: currentStepForm === index + 1 ? 10 : 1,
                    }}
                    isLast={currentStepForm === arrSteps.length}
                    index={index}
                    current={currentStepForm}
                    onClickBack={() => {
                      setCurrentStepForm(currentStepForm - 1);
                    }}
                    onClickNext={() => {
                      setCurrentStepForm(currentStepForm + 1);
                    }}
                    onClickFinish={(e) => handleSubmit(e)}
                    imgProgress={item.imgProgress}
                    bgColorTest={item.bgColorTest}
                  >
                    {item.question !== "" && (
                      <div className="rap_register_question_container">
                        <h2>{item?.question}</h2>
                      </div>
                    )}
                    {item.children}
                  </Step>
                );
              })}
            </section>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterApplicant;
