/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./ApplicantsList.css";
import { WebContext } from "../../context/Context";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import SideMenu from "../../components/sideMenu/SideMenu";
import ApplicantCard from "../../components/applicantCard/ApplicantCard";
import ApplicantCardEmpty from "../../components/applicantCard/ApplicantCardEmpty";
import servicesApplicantsList from "./servicesApplicantsList";
import LoadingTab from "../../components/loading/LoadingTab";
import {
  getRegistrationTime,
  redirectLoginC,
  userCompany,
} from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import {
  registrationTimeOptions,
  genderOptions,
  licenseOptions,
  timeExpOptions,
  lngPrefOptions,
  option_choose,
  experienceOptions,
} from "../../utils/options";
import Input from "../../components/input/Input";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const ApplicantsList = ({ docTitle }) => {
  const {
    dispatch,
    arrApplicantsWithFilters,
    loadingArrApplicantsWithFilters,
  } = WebContext();

  const [openMenuFilters, setOpenMenuFilters] = useState(false);
  const [newJob, setNewJob] = useState([
    ...experienceOptions.splice(1, experienceOptions.length),
  ]);
  const [filters, setFilters] = useState({
    license: "" /* Ready */,
    experience: "" /* Ready */,
    salary: "" /* Ready */,
    gender: "" /* Ready */,
    date_register: "" /* Ready */,
    languages: "" /* Ready */,
    job: "" /* Ready */,
  });
  /* ?language[]=option-1&language[]=option-15&experience=option-4&license=class-b&language[]=option-16&gender=male&salary=83072&register=2023-10-19 */

  let { id, token } = userCompany.get();

  const navigate = useNavigate();

  const handleSelect = (type = "", value = "") => {
    return setFilters({
      ...filters,
      [type]: value,
    });
  };

  const SectionFilter = ({ title, children }) => {
    return (
      <section className="pva_filter_column_section">
        <h3>{title}</h3>

        <div className="pva_filter_column_section_options">{children}</div>
      </section>
    );
  };

  const LeftFilters = () => {
    return (
      <>
        <div className="pva_filter_column_container">
          {/* ------------------ Filter: License ------------------ */}
          <SectionFilter title={t("admin-company-license-title")}>
            <button
              className={`pva_filter_btn ${
                filters.license === "" && "pva_filter_btn_selected"
              }`}
              onClick={() => handleSelect("license")}
            >
              {t("admin-company-filter-all")}
            </button>

            {licenseOptions.map((license, index) => {
              return (
                <button
                  key={`license_option_${index}`}
                  className={`pva_filter_btn ${
                    filters.license === `&license=${license.value}` &&
                    "pva_filter_btn_selected"
                  }`}
                  onClick={() =>
                    handleSelect("license", `&license=${license.value}`)
                  }
                >
                  {t(license.name)}
                </button>
              );
            })}
          </SectionFilter>
          {/* ------------------ /Filter: License ------------------ */}

          {/* ------------------ Filter: Experience ------------------ */}
          <SectionFilter title={t("admin-company-time-experience-title")}>
            <button
              className={`pva_filter_btn ${
                filters.experience === "" && "pva_filter_btn_selected"
              }`}
              onClick={() => handleSelect("experience")}
            >
              {t("admin-company-filter-all")}
            </button>

            {timeExpOptions.map((timeexp, index) => {
              if (timeexp.value !== option_choose) {
                return (
                  <button
                    key={`experience_option_${index}`}
                    className={`pva_filter_btn ${
                      filters.experience === `&experience=${timeexp.value}` &&
                      "pva_filter_btn_selected"
                    }`}
                    onClick={() =>
                      handleSelect("experience", `&experience=${timeexp.value}`)
                    }
                  >
                    {t(timeexp.name)}
                  </button>
                );
              } else
                return (
                  <option
                    style={{ display: "none" }}
                    key={`experience_option_${index}`}
                  />
                );
            })}
          </SectionFilter>
          {/* ------------------ /Filter: Experience ------------------ */}

          {/* ---------------- Filter: Salary expectation  ---------------- */}
          {/* <SectionFilter title={t("admin-company-salary-title")}>
            <Input
              type="range"
              id="applicant_salary"
              nameInput="applicant_salary"
              min={0}
              max={150000}
              // defaultValue={form.applicant_salary}
              // responseInput={(target, isOK) => handleChangeInput(target, isOK)}
              // ${thousandsSeparator(form.applicant_salary)} €`}
            />
          </SectionFilter> */}
          {/* ---------------- /Filter: Salary expectation  ---------------- */}

          {/* ------------------ Filter: Gender ------------------ */}
          <SectionFilter title={t("admin-company-gender-title")}>
            <button
              className={`pva_filter_btn ${
                filters.gender === "" && "pva_filter_btn_selected"
              }`}
              onClick={() => handleSelect("gender")}
            >
              {t("admin-company-filter-all")}
            </button>

            {genderOptions.map((gender, index) => {
              return (
                <button
                  key={`gender_option_${index}`}
                  className={`pva_filter_btn ${
                    filters.gender === `&gender=${gender.value}` &&
                    "pva_filter_btn_selected"
                  }`}
                  onClick={() =>
                    handleSelect("gender", `&gender=${gender.value}`)
                  }
                >
                  {t(gender.name)}
                </button>
              );
            })}
          </SectionFilter>
          {/* ------------------ /Filter: Gender ------------------ */}
        </div>
      </>
    );
  };

  const RightFilters = () => {
    return (
      <>
        <div className="pva_filter_column_container">
          {/* -------------------- What job -------------------- */}
          <SectionFilter title={t("Beruf suchen")}>
            <select
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                handleSelect(
                  "job",
                  `${e.target.value !== "" ? `&job=${e.target.value}` : ""}`
                )
              }
              defaultValue={filters.job.slice(5, filters.job.length)}
            >
              <option value={""}>{t("admin-company-filter-all")}</option>

              {newJob.map((job, index) => {
                if (job.value !== option_choose) {
                  return (
                    <option key={`job_${index}`} value={job.value}>
                      {t(job.name)}
                    </option>
                  );
                } else
                  return (
                    <option style={{ display: "none" }} key={`job_${index}`} />
                  );
              })}
            </select>
          </SectionFilter>
          {/* -------------------- /What job -------------------- */}

          {/* ------------------ Filter: Date Register ------------------ */}
          <SectionFilter title={t("admin-company-time-register-title")}>
            <button
              className={`pva_filter_btn ${
                filters.date_register === "" && "pva_filter_btn_selected"
              }`}
              onClick={() => handleSelect("date_register")}
            >
              {t("admin-company-filter-all")}
            </button>

            {registrationTimeOptions.map((date_register, index) => {
              return (
                <button
                  key={`date_register_option_${index}`}
                  className={`pva_filter_btn ${
                    filters.date_register ===
                      `&register=${getRegistrationTime(date_register.value)}` &&
                    "pva_filter_btn_selected"
                  }`}
                  onClick={() =>
                    handleSelect(
                      "date_register",
                      `&register=${getRegistrationTime(date_register.value)}`
                    )
                  }
                >
                  {t(date_register.name)}
                </button>
              );
            })}
          </SectionFilter>
          {/* ------------------ /Filter: Date Register ------------------ */}

          <SectionFilter title={t("admin-company-languages-title")}>
            <select
              style={{
                width: "100%",
              }}
              // multiple={true}
              onChange={(e) =>
                handleSelect(
                  "languages",
                  `${
                    e.target.value !== "" ? `&language[]=${e.target.value}` : ""
                  }`
                )
              }
              defaultValue={filters.languages.slice(
                12,
                filters.languages.length
              )}
            >
              <option value={""}>{t("admin-company-filter-all")}</option>

              {lngPrefOptions.map((lng, index) => {
                if (lng.value !== option_choose) {
                  return (
                    <option key={`language_${index}`} value={lng.value}>
                      {t(lng.name)}
                    </option>
                  );
                } else
                  return (
                    <option
                      style={{ display: "none" }}
                      key={`language_${index}`}
                    />
                  );
              })}
            </select>
          </SectionFilter>

          <SectionFilter>
            <p>
              Wir werden bald weitere Filter aktivieren, wir führen
              Wartungsarbeiten durch. Bitte seien Sie aufmerksam.
            </p>
          </SectionFilter>
        </div>
      </>
    );
  };

  useEffect(() => {
    let saveDataFilters = JSON.parse(localStorage.getItem("filters"));

    setFilters(saveDataFilters);
  }, []);

  useEffect(() => {
    let search = `${filters.license}${filters.experience}${filters.salary}${filters.gender}${filters.date_register}${filters.languages}${filters.job}`;

    localStorage.setItem("filters", JSON.stringify(filters));

    servicesApplicantsList(
      dispatch,
      id,
      token,
      search,
      (res) =>
        res === "token-expired" &&
        redirectLoginC(dispatch, navigate, "applicants-list")
    );
  }, [dispatch, id, token, filters, navigate]);

  return (
    <>
      {/* Class names starting with "pva" refer to the file: PreviewApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-applicants-list")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="company" />

      <SideMenu
        openSides={openMenuFilters}
        sideChildrenLeft={<LeftFilters />}
        sideChildrenRight={<RightFilters />}
        onClickClose={() => setOpenMenuFilters(!openMenuFilters)}
      >
        <div className="pva_listContent_container">
          <div className="pva_icon_filter_container">
            <button
              className="pva_icon_filter_btn"
              onClick={() => setOpenMenuFilters(!openMenuFilters)}
            >
              <img
                className="pva_icon_filter_img"
                src={assets.icons.iconFilter}
                alt="Icon filter"
              />
            </button>
          </div>

          <div className="pva_list_container">
            {loadingArrApplicantsWithFilters ? (
              <LoadingTab />
            ) : arrApplicantsWithFilters.length === 0 ? (
              <ApplicantCardEmpty />
            ) : (
              arrApplicantsWithFilters.map((data, index) => {
                return (
                  <ApplicantCard key={`applicant_card_${index}`} data={data} />
                );
              })
            )}
          </div>
        </div>
      </SideMenu>
    </>
  );
};

export default ApplicantsList;
