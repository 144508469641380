/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./ApplicantCard.css";
import { t } from "../../languages/settingsLanguages";
// ---------------------------------------------------------

const ApplicantCardEmpty = () => {

  return (
    <>
      {/* Class names starting with "appc" refer to the file: ApplicantCard.css */}
      <section className="appc_empty">
        <h3>{t("applicant-card-empty")}</h3>
      </section>
    </>
  );
};

export default ApplicantCardEmpty;
